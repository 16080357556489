@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Mono Light Italic"), local("IBMPlexMono-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6sfjptAgt5VM-kVkqdyU8n1ioSflV1gMoW.woff) format("woff"); }

@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Mono Italic"), local("IBMPlexMono-Italic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6pfjptAgt5VM-kVkqdyU8n1ioa1Xdm.woff) format("woff"); }

@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Mono SemiBold Italic"), local("IBMPlexMono-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6sfjptAgt5VM-kVkqdyU8n1ioSClN1gMoW.woff) format("woff"); }

@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Mono Light"), local("IBMPlexMono-Light"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6qfjptAgt5VM-kVkqdyU8n3oQIwlBFhA.woff) format("woff"); }

@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Mono"), local("IBMPlexMono"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F63fjptAgt5VM-kVkqdyU8n1i8q0Q.woff) format("woff"); }

@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Mono SemiBold"), local("IBMPlexMono-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6qfjptAgt5VM-kVkqdyU8n3vAOwlBFhA.woff) format("woff"); }

/* cyrillic-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Mono Light Italic"), local("IBMPlexMono-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6sfjptAgt5VM-kVkqdyU8n1ioSflV1jcoQPttoz6Pz.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Mono Light Italic"), local("IBMPlexMono-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6sfjptAgt5VM-kVkqdyU8n1ioSflV1hMoQPttoz6Pz.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Mono Light Italic"), local("IBMPlexMono-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6sfjptAgt5VM-kVkqdyU8n1ioSflV1j8oQPttoz6Pz.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Mono Light Italic"), local("IBMPlexMono-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6sfjptAgt5VM-kVkqdyU8n1ioSflV1jsoQPttoz6Pz.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Mono Light Italic"), local("IBMPlexMono-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6sfjptAgt5VM-kVkqdyU8n1ioSflV1gMoQPttozw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Mono Italic"), local("IBMPlexMono-Italic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6pfjptAgt5VM-kVkqdyU8n1ioa2HdgregdFOFh.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Mono Italic"), local("IBMPlexMono-Italic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6pfjptAgt5VM-kVkqdyU8n1ioa0XdgregdFOFh.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Mono Italic"), local("IBMPlexMono-Italic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6pfjptAgt5VM-kVkqdyU8n1ioa2ndgregdFOFh.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Mono Italic"), local("IBMPlexMono-Italic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6pfjptAgt5VM-kVkqdyU8n1ioa23dgregdFOFh.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Mono Italic"), local("IBMPlexMono-Italic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6pfjptAgt5VM-kVkqdyU8n1ioa1XdgregdFA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Mono SemiBold Italic"), local("IBMPlexMono-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6sfjptAgt5VM-kVkqdyU8n1ioSClN1jcoQPttoz6Pz.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Mono SemiBold Italic"), local("IBMPlexMono-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6sfjptAgt5VM-kVkqdyU8n1ioSClN1hMoQPttoz6Pz.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Mono SemiBold Italic"), local("IBMPlexMono-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6sfjptAgt5VM-kVkqdyU8n1ioSClN1j8oQPttoz6Pz.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Mono SemiBold Italic"), local("IBMPlexMono-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6sfjptAgt5VM-kVkqdyU8n1ioSClN1jsoQPttoz6Pz.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Mono SemiBold Italic"), local("IBMPlexMono-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6sfjptAgt5VM-kVkqdyU8n1ioSClN1gMoQPttozw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Mono Light"), local("IBMPlexMono-Light"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6qfjptAgt5VM-kVkqdyU8n3oQIwl1FgsAXHNlYzg.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Mono Light"), local("IBMPlexMono-Light"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6qfjptAgt5VM-kVkqdyU8n3oQIwlRFgsAXHNlYzg.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Mono Light"), local("IBMPlexMono-Light"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6qfjptAgt5VM-kVkqdyU8n3oQIwl9FgsAXHNlYzg.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Mono Light"), local("IBMPlexMono-Light"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6qfjptAgt5VM-kVkqdyU8n3oQIwl5FgsAXHNlYzg.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Mono Light"), local("IBMPlexMono-Light"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6qfjptAgt5VM-kVkqdyU8n3oQIwlBFgsAXHNk.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Mono"), local("IBMPlexMono"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F63fjptAgt5VM-kVkqdyU8n1iIq131nj-otFQ.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Mono"), local("IBMPlexMono"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F63fjptAgt5VM-kVkqdyU8n1isq131nj-otFQ.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Mono"), local("IBMPlexMono"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F63fjptAgt5VM-kVkqdyU8n1iAq131nj-otFQ.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Mono"), local("IBMPlexMono"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F63fjptAgt5VM-kVkqdyU8n1iEq131nj-otFQ.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Mono"), local("IBMPlexMono"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F63fjptAgt5VM-kVkqdyU8n1i8q131nj-o.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Mono SemiBold"), local("IBMPlexMono-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6qfjptAgt5VM-kVkqdyU8n3vAOwl1FgsAXHNlYzg.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Mono SemiBold"), local("IBMPlexMono-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6qfjptAgt5VM-kVkqdyU8n3vAOwlRFgsAXHNlYzg.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Mono SemiBold"), local("IBMPlexMono-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6qfjptAgt5VM-kVkqdyU8n3vAOwl9FgsAXHNlYzg.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Mono SemiBold"), local("IBMPlexMono-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6qfjptAgt5VM-kVkqdyU8n3vAOwl5FgsAXHNlYzg.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Mono SemiBold"), local("IBMPlexMono-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6qfjptAgt5VM-kVkqdyU8n3vAOwlBFgsAXHNk.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmvIRcdvfo.woff) format("woff"); }

@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX-KVElMYYaJe8bpLHnCwDKhdTuF6ZP.woff) format("woff"); }

@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmyIJcdvfo.woff) format("woff"); }

@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjXr8AIFscg.woff) format("woff"); }

@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYXgKVElMYYaJe8bpLHnCwDKhdHeEw.woff) format("woff"); }

@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjQ76AIFscg.woff) format("woff"); }

/* cyrillic-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmvIRce_fuJGl18QRY.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmvIRccvfuJGl18QRY.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmvIRcdffuJGl18QRY.woff2) format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmvIRceffuJGl18QRY.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmvIRcePfuJGl18QRY.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmvIRcdvfuJGl18Q.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX-KVElMYYaJe8bpLHnCwDKhdTuGqZJW9XjDlN8.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX-KVElMYYaJe8bpLHnCwDKhdTuE6ZJW9XjDlN8.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX-KVElMYYaJe8bpLHnCwDKhdTuFKZJW9XjDlN8.woff2) format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX-KVElMYYaJe8bpLHnCwDKhdTuGKZJW9XjDlN8.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX-KVElMYYaJe8bpLHnCwDKhdTuGaZJW9XjDlN8.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX-KVElMYYaJe8bpLHnCwDKhdTuF6ZJW9XjDg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmyIJce_fuJGl18QRY.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmyIJccvfuJGl18QRY.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmyIJcdffuJGl18QRY.woff2) format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmyIJceffuJGl18QRY.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmyIJcePfuJGl18QRY.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmyIJcdvfuJGl18Q.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjXr8AIxsdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjXr8AIVsdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjXr8AIJsdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjXr8AI5sdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjXr8AI9sdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjXr8AIFsdP3pBms.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYXgKVElMYYaJe8bpLHnCwDKhdzeFaxOedfTDw.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYXgKVElMYYaJe8bpLHnCwDKhdXeFaxOedfTDw.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYXgKVElMYYaJe8bpLHnCwDKhdLeFaxOedfTDw.woff2) format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYXgKVElMYYaJe8bpLHnCwDKhd7eFaxOedfTDw.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYXgKVElMYYaJe8bpLHnCwDKhd_eFaxOedfTDw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYXgKVElMYYaJe8bpLHnCwDKhdHeFaxOedc.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjQ76AIxsdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjQ76AIVsdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjQ76AIJsdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjQ76AI5sdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjQ76AI9sdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjQ76AIFsdP3pBms.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  padding: 0;
  border: 0;
  margin: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

button,
select,
input,
textarea {
  border-radius: 0;
  font-family: inherit; }

input[type='text']::-ms-clear {
  display: none; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

sup {
  vertical-align: super; }

sub {
  vertical-align: sub; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: ''; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

button {
  margin: 0; }

html {
  font-size: 100%; }

body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility; }

code {
  font-family: 'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace; }

strong {
  font-weight: 600; }

@media screen and (-ms-high-contrast: active) {
  svg {
    fill: ButtonText; } }

h1 {
  font-size: 2.625rem;
  font-weight: 300;
  line-height: 1.199;
  letter-spacing: 0; }

h2 {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: 0; }

h3 {
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 1.29;
  letter-spacing: 0; }

h4 {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0; }

h5 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.375;
  letter-spacing: 0; }

h6 {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.29;
  letter-spacing: 0.16px; }

p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0; }

a {
  color: #0f62fe; }

em {
  font-style: italic; }

@keyframes skeleton {
  0% {
    opacity: 0.3;
    transform: scaleX(0);
    transform-origin: left; }
  20% {
    opacity: 1;
    transform: scaleX(1);
    transform-origin: left; }
  28% {
    transform: scaleX(1);
    transform-origin: right; }
  51% {
    transform: scaleX(0);
    transform-origin: right; }
  58% {
    transform: scaleX(0);
    transform-origin: right; }
  82% {
    transform: scaleX(1);
    transform-origin: right; }
  83% {
    transform: scaleX(1);
    transform-origin: left; }
  96% {
    transform: scaleX(0);
    transform-origin: left; }
  100% {
    opacity: 0.3;
    transform: scaleX(0);
    transform-origin: left; } }

.bx--grid {
  margin-right: auto;
  margin-left: auto;
  max-width: 99rem;
  padding-right: 1rem;
  padding-left: 1rem; }
  @media (min-width: 42rem) {
    .bx--grid {
      padding-right: 2rem;
      padding-left: 2rem; } }
  @media (min-width: 99rem) {
    .bx--grid {
      padding-right: 2.5rem;
      padding-left: 2.5rem; } }

@media (min-width: 99rem) {
  .bx--grid--full-width {
    max-width: 100%; } }

.bx--row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem; }

.bx--row-padding [class*='bx--col'],
.bx--col-padding {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.bx--grid--condensed [class*='bx--col'] {
  padding-top: 0.03125rem;
  padding-bottom: 0.03125rem; }

.bx--col {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col,
  .bx--grid--condensed .bx--col {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col,
  .bx--grid--narrow .bx--col {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-sm-0 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-sm-0,
  .bx--grid--condensed .bx--col-sm-0 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-sm-0,
  .bx--grid--narrow .bx--col-sm-0 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-sm-1 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-sm-1,
  .bx--grid--condensed .bx--col-sm-1 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-sm-1,
  .bx--grid--narrow .bx--col-sm-1 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-sm-2 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-sm-2,
  .bx--grid--condensed .bx--col-sm-2 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-sm-2,
  .bx--grid--narrow .bx--col-sm-2 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-sm-3 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-sm-3,
  .bx--grid--condensed .bx--col-sm-3 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-sm-3,
  .bx--grid--narrow .bx--col-sm-3 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-sm-4 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-sm-4,
  .bx--grid--condensed .bx--col-sm-4 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-sm-4,
  .bx--grid--narrow .bx--col-sm-4 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-sm,
.bx--col-sm--auto {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-sm,
  .bx--grid--condensed .bx--col-sm, .bx--row--condensed
  .bx--col-sm--auto,
  .bx--grid--condensed
  .bx--col-sm--auto {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-sm,
  .bx--grid--narrow .bx--col-sm, .bx--row--narrow
  .bx--col-sm--auto,
  .bx--grid--narrow
  .bx--col-sm--auto {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col,
.bx--col-sm {
  max-width: 100%;
  flex-basis: 0;
  flex-grow: 1; }

.bx--col--auto,
.bx--col-sm--auto {
  width: auto;
  max-width: 100%;
  flex: 1 0 0%; }

.bx--col-sm-0 {
  display: none; }

.bx--col-sm-1 {
  display: block;
  max-width: 25%;
  flex: 0 0 25%; }

.bx--col-sm-2 {
  display: block;
  max-width: 50%;
  flex: 0 0 50%; }

.bx--col-sm-3 {
  display: block;
  max-width: 75%;
  flex: 0 0 75%; }

.bx--col-sm-4 {
  display: block;
  max-width: 100%;
  flex: 0 0 100%; }

.bx--offset-sm-0 {
  margin-left: 0; }

.bx--offset-sm-1 {
  margin-left: 25%; }

.bx--offset-sm-2 {
  margin-left: 50%; }

.bx--offset-sm-3 {
  margin-left: 75%; }

.bx--col-md-0 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md-0,
  .bx--grid--condensed .bx--col-md-0 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-md-0,
  .bx--grid--narrow .bx--col-md-0 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-md-1 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md-1,
  .bx--grid--condensed .bx--col-md-1 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-md-1,
  .bx--grid--narrow .bx--col-md-1 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-md-2 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md-2,
  .bx--grid--condensed .bx--col-md-2 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-md-2,
  .bx--grid--narrow .bx--col-md-2 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-md-3 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md-3,
  .bx--grid--condensed .bx--col-md-3 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-md-3,
  .bx--grid--narrow .bx--col-md-3 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-md-4 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md-4,
  .bx--grid--condensed .bx--col-md-4 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-md-4,
  .bx--grid--narrow .bx--col-md-4 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-md-5 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md-5,
  .bx--grid--condensed .bx--col-md-5 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-md-5,
  .bx--grid--narrow .bx--col-md-5 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-md-6 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md-6,
  .bx--grid--condensed .bx--col-md-6 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-md-6,
  .bx--grid--narrow .bx--col-md-6 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-md-7 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md-7,
  .bx--grid--condensed .bx--col-md-7 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-md-7,
  .bx--grid--narrow .bx--col-md-7 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-md-8 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md-8,
  .bx--grid--condensed .bx--col-md-8 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-md-8,
  .bx--grid--narrow .bx--col-md-8 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-md,
.bx--col-md--auto {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md,
  .bx--grid--condensed .bx--col-md, .bx--row--condensed
  .bx--col-md--auto,
  .bx--grid--condensed
  .bx--col-md--auto {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-md,
  .bx--grid--narrow .bx--col-md, .bx--row--narrow
  .bx--col-md--auto,
  .bx--grid--narrow
  .bx--col-md--auto {
    padding-right: 1rem;
    padding-left: 0; }

@media (min-width: 42rem) {
  .bx--col,
  .bx--col-md {
    max-width: 100%;
    flex-basis: 0;
    flex-grow: 1; }
  .bx--col--auto,
  .bx--col-md--auto {
    width: auto;
    max-width: 100%;
    flex: 1 0 0%; }
  .bx--col-md-0 {
    display: none; }
  .bx--col-md-1 {
    display: block;
    max-width: 12.5%;
    flex: 0 0 12.5%; }
  .bx--col-md-2 {
    display: block;
    max-width: 25%;
    flex: 0 0 25%; }
  .bx--col-md-3 {
    display: block;
    max-width: 37.5%;
    flex: 0 0 37.5%; }
  .bx--col-md-4 {
    display: block;
    max-width: 50%;
    flex: 0 0 50%; }
  .bx--col-md-5 {
    display: block;
    max-width: 62.5%;
    flex: 0 0 62.5%; }
  .bx--col-md-6 {
    display: block;
    max-width: 75%;
    flex: 0 0 75%; }
  .bx--col-md-7 {
    display: block;
    max-width: 87.5%;
    flex: 0 0 87.5%; }
  .bx--col-md-8 {
    display: block;
    max-width: 100%;
    flex: 0 0 100%; }
  .bx--offset-md-0 {
    margin-left: 0; }
  .bx--offset-md-1 {
    margin-left: 12.5%; }
  .bx--offset-md-2 {
    margin-left: 25%; }
  .bx--offset-md-3 {
    margin-left: 37.5%; }
  .bx--offset-md-4 {
    margin-left: 50%; }
  .bx--offset-md-5 {
    margin-left: 62.5%; }
  .bx--offset-md-6 {
    margin-left: 75%; }
  .bx--offset-md-7 {
    margin-left: 87.5%; } }

.bx--col-lg-0 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-0,
  .bx--grid--condensed .bx--col-lg-0 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-lg-0,
  .bx--grid--narrow .bx--col-lg-0 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-lg-1 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-1,
  .bx--grid--condensed .bx--col-lg-1 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-lg-1,
  .bx--grid--narrow .bx--col-lg-1 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-lg-2 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-2,
  .bx--grid--condensed .bx--col-lg-2 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-lg-2,
  .bx--grid--narrow .bx--col-lg-2 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-lg-3 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-3,
  .bx--grid--condensed .bx--col-lg-3 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-lg-3,
  .bx--grid--narrow .bx--col-lg-3 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-lg-4 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-4,
  .bx--grid--condensed .bx--col-lg-4 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-lg-4,
  .bx--grid--narrow .bx--col-lg-4 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-lg-5 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-5,
  .bx--grid--condensed .bx--col-lg-5 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-lg-5,
  .bx--grid--narrow .bx--col-lg-5 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-lg-6 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-6,
  .bx--grid--condensed .bx--col-lg-6 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-lg-6,
  .bx--grid--narrow .bx--col-lg-6 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-lg-7 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-7,
  .bx--grid--condensed .bx--col-lg-7 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-lg-7,
  .bx--grid--narrow .bx--col-lg-7 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-lg-8 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-8,
  .bx--grid--condensed .bx--col-lg-8 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-lg-8,
  .bx--grid--narrow .bx--col-lg-8 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-lg-9 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-9,
  .bx--grid--condensed .bx--col-lg-9 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-lg-9,
  .bx--grid--narrow .bx--col-lg-9 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-lg-10 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-10,
  .bx--grid--condensed .bx--col-lg-10 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-lg-10,
  .bx--grid--narrow .bx--col-lg-10 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-lg-11 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-11,
  .bx--grid--condensed .bx--col-lg-11 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-lg-11,
  .bx--grid--narrow .bx--col-lg-11 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-lg-12 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-12,
  .bx--grid--condensed .bx--col-lg-12 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-lg-12,
  .bx--grid--narrow .bx--col-lg-12 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-lg-13 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-13,
  .bx--grid--condensed .bx--col-lg-13 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-lg-13,
  .bx--grid--narrow .bx--col-lg-13 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-lg-14 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-14,
  .bx--grid--condensed .bx--col-lg-14 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-lg-14,
  .bx--grid--narrow .bx--col-lg-14 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-lg-15 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-15,
  .bx--grid--condensed .bx--col-lg-15 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-lg-15,
  .bx--grid--narrow .bx--col-lg-15 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-lg-16 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-16,
  .bx--grid--condensed .bx--col-lg-16 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-lg-16,
  .bx--grid--narrow .bx--col-lg-16 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-lg,
.bx--col-lg--auto {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg,
  .bx--grid--condensed .bx--col-lg, .bx--row--condensed
  .bx--col-lg--auto,
  .bx--grid--condensed
  .bx--col-lg--auto {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-lg,
  .bx--grid--narrow .bx--col-lg, .bx--row--narrow
  .bx--col-lg--auto,
  .bx--grid--narrow
  .bx--col-lg--auto {
    padding-right: 1rem;
    padding-left: 0; }

@media (min-width: 66rem) {
  .bx--col,
  .bx--col-lg {
    max-width: 100%;
    flex-basis: 0;
    flex-grow: 1; }
  .bx--col--auto,
  .bx--col-lg--auto {
    width: auto;
    max-width: 100%;
    flex: 1 0 0%; }
  .bx--col-lg-0 {
    display: none; }
  .bx--col-lg-1 {
    display: block;
    max-width: 6.25%;
    flex: 0 0 6.25%; }
  .bx--col-lg-2 {
    display: block;
    max-width: 12.5%;
    flex: 0 0 12.5%; }
  .bx--col-lg-3 {
    display: block;
    max-width: 18.75%;
    flex: 0 0 18.75%; }
  .bx--col-lg-4 {
    display: block;
    max-width: 25%;
    flex: 0 0 25%; }
  .bx--col-lg-5 {
    display: block;
    max-width: 31.25%;
    flex: 0 0 31.25%; }
  .bx--col-lg-6 {
    display: block;
    max-width: 37.5%;
    flex: 0 0 37.5%; }
  .bx--col-lg-7 {
    display: block;
    max-width: 43.75%;
    flex: 0 0 43.75%; }
  .bx--col-lg-8 {
    display: block;
    max-width: 50%;
    flex: 0 0 50%; }
  .bx--col-lg-9 {
    display: block;
    max-width: 56.25%;
    flex: 0 0 56.25%; }
  .bx--col-lg-10 {
    display: block;
    max-width: 62.5%;
    flex: 0 0 62.5%; }
  .bx--col-lg-11 {
    display: block;
    max-width: 68.75%;
    flex: 0 0 68.75%; }
  .bx--col-lg-12 {
    display: block;
    max-width: 75%;
    flex: 0 0 75%; }
  .bx--col-lg-13 {
    display: block;
    max-width: 81.25%;
    flex: 0 0 81.25%; }
  .bx--col-lg-14 {
    display: block;
    max-width: 87.5%;
    flex: 0 0 87.5%; }
  .bx--col-lg-15 {
    display: block;
    max-width: 93.75%;
    flex: 0 0 93.75%; }
  .bx--col-lg-16 {
    display: block;
    max-width: 100%;
    flex: 0 0 100%; }
  .bx--offset-lg-0 {
    margin-left: 0; }
  .bx--offset-lg-1 {
    margin-left: 6.25%; }
  .bx--offset-lg-2 {
    margin-left: 12.5%; }
  .bx--offset-lg-3 {
    margin-left: 18.75%; }
  .bx--offset-lg-4 {
    margin-left: 25%; }
  .bx--offset-lg-5 {
    margin-left: 31.25%; }
  .bx--offset-lg-6 {
    margin-left: 37.5%; }
  .bx--offset-lg-7 {
    margin-left: 43.75%; }
  .bx--offset-lg-8 {
    margin-left: 50%; }
  .bx--offset-lg-9 {
    margin-left: 56.25%; }
  .bx--offset-lg-10 {
    margin-left: 62.5%; }
  .bx--offset-lg-11 {
    margin-left: 68.75%; }
  .bx--offset-lg-12 {
    margin-left: 75%; }
  .bx--offset-lg-13 {
    margin-left: 81.25%; }
  .bx--offset-lg-14 {
    margin-left: 87.5%; }
  .bx--offset-lg-15 {
    margin-left: 93.75%; } }

.bx--col-xlg-0 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-0,
  .bx--grid--condensed .bx--col-xlg-0 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-xlg-0,
  .bx--grid--narrow .bx--col-xlg-0 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-xlg-1 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-1,
  .bx--grid--condensed .bx--col-xlg-1 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-xlg-1,
  .bx--grid--narrow .bx--col-xlg-1 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-xlg-2 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-2,
  .bx--grid--condensed .bx--col-xlg-2 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-xlg-2,
  .bx--grid--narrow .bx--col-xlg-2 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-xlg-3 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-3,
  .bx--grid--condensed .bx--col-xlg-3 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-xlg-3,
  .bx--grid--narrow .bx--col-xlg-3 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-xlg-4 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-4,
  .bx--grid--condensed .bx--col-xlg-4 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-xlg-4,
  .bx--grid--narrow .bx--col-xlg-4 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-xlg-5 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-5,
  .bx--grid--condensed .bx--col-xlg-5 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-xlg-5,
  .bx--grid--narrow .bx--col-xlg-5 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-xlg-6 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-6,
  .bx--grid--condensed .bx--col-xlg-6 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-xlg-6,
  .bx--grid--narrow .bx--col-xlg-6 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-xlg-7 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-7,
  .bx--grid--condensed .bx--col-xlg-7 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-xlg-7,
  .bx--grid--narrow .bx--col-xlg-7 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-xlg-8 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-8,
  .bx--grid--condensed .bx--col-xlg-8 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-xlg-8,
  .bx--grid--narrow .bx--col-xlg-8 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-xlg-9 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-9,
  .bx--grid--condensed .bx--col-xlg-9 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-xlg-9,
  .bx--grid--narrow .bx--col-xlg-9 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-xlg-10 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-10,
  .bx--grid--condensed .bx--col-xlg-10 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-xlg-10,
  .bx--grid--narrow .bx--col-xlg-10 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-xlg-11 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-11,
  .bx--grid--condensed .bx--col-xlg-11 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-xlg-11,
  .bx--grid--narrow .bx--col-xlg-11 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-xlg-12 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-12,
  .bx--grid--condensed .bx--col-xlg-12 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-xlg-12,
  .bx--grid--narrow .bx--col-xlg-12 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-xlg-13 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-13,
  .bx--grid--condensed .bx--col-xlg-13 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-xlg-13,
  .bx--grid--narrow .bx--col-xlg-13 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-xlg-14 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-14,
  .bx--grid--condensed .bx--col-xlg-14 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-xlg-14,
  .bx--grid--narrow .bx--col-xlg-14 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-xlg-15 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-15,
  .bx--grid--condensed .bx--col-xlg-15 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-xlg-15,
  .bx--grid--narrow .bx--col-xlg-15 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-xlg-16 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-16,
  .bx--grid--condensed .bx--col-xlg-16 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-xlg-16,
  .bx--grid--narrow .bx--col-xlg-16 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-xlg,
.bx--col-xlg--auto {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg,
  .bx--grid--condensed .bx--col-xlg, .bx--row--condensed
  .bx--col-xlg--auto,
  .bx--grid--condensed
  .bx--col-xlg--auto {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-xlg,
  .bx--grid--narrow .bx--col-xlg, .bx--row--narrow
  .bx--col-xlg--auto,
  .bx--grid--narrow
  .bx--col-xlg--auto {
    padding-right: 1rem;
    padding-left: 0; }

@media (min-width: 82rem) {
  .bx--col,
  .bx--col-xlg {
    max-width: 100%;
    flex-basis: 0;
    flex-grow: 1; }
  .bx--col--auto,
  .bx--col-xlg--auto {
    width: auto;
    max-width: 100%;
    flex: 1 0 0%; }
  .bx--col-xlg-0 {
    display: none; }
  .bx--col-xlg-1 {
    display: block;
    max-width: 6.25%;
    flex: 0 0 6.25%; }
  .bx--col-xlg-2 {
    display: block;
    max-width: 12.5%;
    flex: 0 0 12.5%; }
  .bx--col-xlg-3 {
    display: block;
    max-width: 18.75%;
    flex: 0 0 18.75%; }
  .bx--col-xlg-4 {
    display: block;
    max-width: 25%;
    flex: 0 0 25%; }
  .bx--col-xlg-5 {
    display: block;
    max-width: 31.25%;
    flex: 0 0 31.25%; }
  .bx--col-xlg-6 {
    display: block;
    max-width: 37.5%;
    flex: 0 0 37.5%; }
  .bx--col-xlg-7 {
    display: block;
    max-width: 43.75%;
    flex: 0 0 43.75%; }
  .bx--col-xlg-8 {
    display: block;
    max-width: 50%;
    flex: 0 0 50%; }
  .bx--col-xlg-9 {
    display: block;
    max-width: 56.25%;
    flex: 0 0 56.25%; }
  .bx--col-xlg-10 {
    display: block;
    max-width: 62.5%;
    flex: 0 0 62.5%; }
  .bx--col-xlg-11 {
    display: block;
    max-width: 68.75%;
    flex: 0 0 68.75%; }
  .bx--col-xlg-12 {
    display: block;
    max-width: 75%;
    flex: 0 0 75%; }
  .bx--col-xlg-13 {
    display: block;
    max-width: 81.25%;
    flex: 0 0 81.25%; }
  .bx--col-xlg-14 {
    display: block;
    max-width: 87.5%;
    flex: 0 0 87.5%; }
  .bx--col-xlg-15 {
    display: block;
    max-width: 93.75%;
    flex: 0 0 93.75%; }
  .bx--col-xlg-16 {
    display: block;
    max-width: 100%;
    flex: 0 0 100%; }
  .bx--offset-xlg-0 {
    margin-left: 0; }
  .bx--offset-xlg-1 {
    margin-left: 6.25%; }
  .bx--offset-xlg-2 {
    margin-left: 12.5%; }
  .bx--offset-xlg-3 {
    margin-left: 18.75%; }
  .bx--offset-xlg-4 {
    margin-left: 25%; }
  .bx--offset-xlg-5 {
    margin-left: 31.25%; }
  .bx--offset-xlg-6 {
    margin-left: 37.5%; }
  .bx--offset-xlg-7 {
    margin-left: 43.75%; }
  .bx--offset-xlg-8 {
    margin-left: 50%; }
  .bx--offset-xlg-9 {
    margin-left: 56.25%; }
  .bx--offset-xlg-10 {
    margin-left: 62.5%; }
  .bx--offset-xlg-11 {
    margin-left: 68.75%; }
  .bx--offset-xlg-12 {
    margin-left: 75%; }
  .bx--offset-xlg-13 {
    margin-left: 81.25%; }
  .bx--offset-xlg-14 {
    margin-left: 87.5%; }
  .bx--offset-xlg-15 {
    margin-left: 93.75%; } }

.bx--col-max-0 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-0,
  .bx--grid--condensed .bx--col-max-0 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-max-0,
  .bx--grid--narrow .bx--col-max-0 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-max-1 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-1,
  .bx--grid--condensed .bx--col-max-1 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-max-1,
  .bx--grid--narrow .bx--col-max-1 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-max-2 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-2,
  .bx--grid--condensed .bx--col-max-2 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-max-2,
  .bx--grid--narrow .bx--col-max-2 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-max-3 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-3,
  .bx--grid--condensed .bx--col-max-3 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-max-3,
  .bx--grid--narrow .bx--col-max-3 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-max-4 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-4,
  .bx--grid--condensed .bx--col-max-4 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-max-4,
  .bx--grid--narrow .bx--col-max-4 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-max-5 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-5,
  .bx--grid--condensed .bx--col-max-5 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-max-5,
  .bx--grid--narrow .bx--col-max-5 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-max-6 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-6,
  .bx--grid--condensed .bx--col-max-6 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-max-6,
  .bx--grid--narrow .bx--col-max-6 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-max-7 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-7,
  .bx--grid--condensed .bx--col-max-7 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-max-7,
  .bx--grid--narrow .bx--col-max-7 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-max-8 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-8,
  .bx--grid--condensed .bx--col-max-8 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-max-8,
  .bx--grid--narrow .bx--col-max-8 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-max-9 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-9,
  .bx--grid--condensed .bx--col-max-9 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-max-9,
  .bx--grid--narrow .bx--col-max-9 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-max-10 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-10,
  .bx--grid--condensed .bx--col-max-10 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-max-10,
  .bx--grid--narrow .bx--col-max-10 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-max-11 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-11,
  .bx--grid--condensed .bx--col-max-11 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-max-11,
  .bx--grid--narrow .bx--col-max-11 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-max-12 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-12,
  .bx--grid--condensed .bx--col-max-12 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-max-12,
  .bx--grid--narrow .bx--col-max-12 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-max-13 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-13,
  .bx--grid--condensed .bx--col-max-13 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-max-13,
  .bx--grid--narrow .bx--col-max-13 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-max-14 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-14,
  .bx--grid--condensed .bx--col-max-14 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-max-14,
  .bx--grid--narrow .bx--col-max-14 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-max-15 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-15,
  .bx--grid--condensed .bx--col-max-15 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-max-15,
  .bx--grid--narrow .bx--col-max-15 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-max-16 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-16,
  .bx--grid--condensed .bx--col-max-16 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-max-16,
  .bx--grid--narrow .bx--col-max-16 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-max,
.bx--col-max--auto {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max,
  .bx--grid--condensed .bx--col-max, .bx--row--condensed
  .bx--col-max--auto,
  .bx--grid--condensed
  .bx--col-max--auto {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-max,
  .bx--grid--narrow .bx--col-max, .bx--row--narrow
  .bx--col-max--auto,
  .bx--grid--narrow
  .bx--col-max--auto {
    padding-right: 1rem;
    padding-left: 0; }

@media (min-width: 99rem) {
  .bx--col,
  .bx--col-max {
    max-width: 100%;
    flex-basis: 0;
    flex-grow: 1; }
  .bx--col--auto,
  .bx--col-max--auto {
    width: auto;
    max-width: 100%;
    flex: 1 0 0%; }
  .bx--col-max-0 {
    display: none; }
  .bx--col-max-1 {
    display: block;
    max-width: 6.25%;
    flex: 0 0 6.25%; }
  .bx--col-max-2 {
    display: block;
    max-width: 12.5%;
    flex: 0 0 12.5%; }
  .bx--col-max-3 {
    display: block;
    max-width: 18.75%;
    flex: 0 0 18.75%; }
  .bx--col-max-4 {
    display: block;
    max-width: 25%;
    flex: 0 0 25%; }
  .bx--col-max-5 {
    display: block;
    max-width: 31.25%;
    flex: 0 0 31.25%; }
  .bx--col-max-6 {
    display: block;
    max-width: 37.5%;
    flex: 0 0 37.5%; }
  .bx--col-max-7 {
    display: block;
    max-width: 43.75%;
    flex: 0 0 43.75%; }
  .bx--col-max-8 {
    display: block;
    max-width: 50%;
    flex: 0 0 50%; }
  .bx--col-max-9 {
    display: block;
    max-width: 56.25%;
    flex: 0 0 56.25%; }
  .bx--col-max-10 {
    display: block;
    max-width: 62.5%;
    flex: 0 0 62.5%; }
  .bx--col-max-11 {
    display: block;
    max-width: 68.75%;
    flex: 0 0 68.75%; }
  .bx--col-max-12 {
    display: block;
    max-width: 75%;
    flex: 0 0 75%; }
  .bx--col-max-13 {
    display: block;
    max-width: 81.25%;
    flex: 0 0 81.25%; }
  .bx--col-max-14 {
    display: block;
    max-width: 87.5%;
    flex: 0 0 87.5%; }
  .bx--col-max-15 {
    display: block;
    max-width: 93.75%;
    flex: 0 0 93.75%; }
  .bx--col-max-16 {
    display: block;
    max-width: 100%;
    flex: 0 0 100%; }
  .bx--offset-max-0 {
    margin-left: 0; }
  .bx--offset-max-1 {
    margin-left: 6.25%; }
  .bx--offset-max-2 {
    margin-left: 12.5%; }
  .bx--offset-max-3 {
    margin-left: 18.75%; }
  .bx--offset-max-4 {
    margin-left: 25%; }
  .bx--offset-max-5 {
    margin-left: 31.25%; }
  .bx--offset-max-6 {
    margin-left: 37.5%; }
  .bx--offset-max-7 {
    margin-left: 43.75%; }
  .bx--offset-max-8 {
    margin-left: 50%; }
  .bx--offset-max-9 {
    margin-left: 56.25%; }
  .bx--offset-max-10 {
    margin-left: 62.5%; }
  .bx--offset-max-11 {
    margin-left: 68.75%; }
  .bx--offset-max-12 {
    margin-left: 75%; }
  .bx--offset-max-13 {
    margin-left: 81.25%; }
  .bx--offset-max-14 {
    margin-left: 87.5%; }
  .bx--offset-max-15 {
    margin-left: 93.75%; } }

.bx--no-gutter,
.bx--row.bx--no-gutter [class*='bx--col'] {
  padding-right: 0;
  padding-left: 0; }

.bx--no-gutter--start,
.bx--row.bx--no-gutter--start [class*='bx--col'] {
  padding-left: 0; }

.bx--no-gutter--end,
.bx--row.bx--no-gutter--end [class*='bx--col'] {
  padding-right: 0; }

.bx--no-gutter--left,
.bx--row.bx--no-gutter--left [class*='bx--col'] {
  padding-left: 0; }

.bx--no-gutter--right,
.bx--row.bx--no-gutter--right [class*='bx--col'] {
  padding-right: 0; }

.bx--hang--start {
  padding-left: 1rem; }

.bx--hang--end {
  padding-right: 1rem; }

.bx--hang--left {
  padding-left: 1rem; }

.bx--hang--right {
  padding-right: 1rem; }

.bx--aspect-ratio {
  position: relative; }

.bx--aspect-ratio::before {
  width: 1px;
  height: 0;
  margin-left: -1px;
  content: '';
  float: left; }

.bx--aspect-ratio::after {
  display: table;
  clear: both;
  content: ''; }

.bx--aspect-ratio--16x9::before {
  padding-top: 56.25%; }

.bx--aspect-ratio--9x16::before {
  padding-top: 177.77778%; }

.bx--aspect-ratio--2x1::before {
  padding-top: 50%; }

.bx--aspect-ratio--1x2::before {
  padding-top: 200%; }

.bx--aspect-ratio--4x3::before {
  padding-top: 75%; }

.bx--aspect-ratio--3x4::before {
  padding-top: 133.33333%; }

.bx--aspect-ratio--3x2::before {
  padding-top: 66.66667%; }

.bx--aspect-ratio--2x3::before {
  padding-top: 150%; }

.bx--aspect-ratio--1x1::before {
  padding-top: 100%; }

.bx--aspect-ratio--object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.bx--text-truncate--end {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap; }

.bx--text-truncate--front {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  direction: rtl;
  text-overflow: ellipsis;
  white-space: nowrap; }

.bx--link {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.29;
  letter-spacing: 0.16px;
  display: inline-flex;
  color: #0f62fe;
  outline: none;
  text-decoration: none;
  transition: color 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--link *,
  .bx--link *::before,
  .bx--link *::after {
    box-sizing: inherit; }
  .bx--link:hover {
    color: #0043ce;
    text-decoration: underline; }
  .bx--link:active, .bx--link:active:visited, .bx--link:active:visited:hover {
    color: #161616;
    text-decoration: underline; }
  .bx--link:focus {
    outline: 1px solid #0f62fe; }
    @media screen and (prefers-contrast) {
      .bx--link:focus {
        outline-style: dotted; } }
  .bx--link:visited {
    color: #0f62fe; }
  .bx--link:visited:hover {
    color: #0043ce; }

.bx--link--disabled,
.bx--link--disabled:hover {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.29;
  letter-spacing: 0.16px;
  color: #c6c6c6;
  cursor: not-allowed;
  font-weight: 400;
  text-decoration: none; }
  .bx--link--disabled *,
  .bx--link--disabled *::before,
  .bx--link--disabled *::after,
  .bx--link--disabled:hover *,
  .bx--link--disabled:hover *::before,
  .bx--link--disabled:hover *::after {
    box-sizing: inherit; }

.bx--link.bx--link--visited:visited {
  color: #8a3ffc; }

.bx--link.bx--link--visited:visited:hover {
  color: #0043ce; }

.bx--link.bx--link--inline {
  text-decoration: underline; }
  .bx--link.bx--link--inline:focus, .bx--link.bx--link--inline:visited {
    text-decoration: none; }

.bx--link--disabled.bx--link--inline {
  text-decoration: underline; }

.bx--link--sm {
  font-size: 0.75rem;
  line-height: 1.34;
  letter-spacing: 0.32px; }

.bx--link--lg {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375;
  letter-spacing: 0; }

.bx--link__icon {
  display: inline-flex;
  align-self: center;
  margin-left: 0.5rem; }

.bx--breadcrumb {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.29;
  letter-spacing: 0.16px;
  display: inline; }
  .bx--breadcrumb *,
  .bx--breadcrumb *::before,
  .bx--breadcrumb *::after {
    box-sizing: inherit; }
  @media (min-width: 42rem) {
    .bx--breadcrumb {
      display: flex;
      flex-wrap: wrap; } }

.bx--breadcrumb-item {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 0.5rem; }

.bx--breadcrumb-item .bx--link:visited {
  color: #0f62fe; }
  .bx--breadcrumb-item .bx--link:visited:hover {
    color: #0043ce; }

.bx--breadcrumb-item::after {
  margin-left: 0.5rem;
  color: #161616;
  content: '/'; }

.bx--breadcrumb--no-trailing-slash
.bx--breadcrumb-item:last-child::after {
  content: ''; }

.bx--breadcrumb-item:last-child,
.bx--breadcrumb-item:last-child::after {
  margin-right: 0; }

.bx--breadcrumb .bx--link {
  white-space: nowrap; }

.bx--breadcrumb-item [aria-current='page'],
.bx--breadcrumb-item.bx--breadcrumb-item--current
.bx--link {
  color: #161616;
  cursor: auto; }
  .bx--breadcrumb-item [aria-current='page']:hover,
  .bx--breadcrumb-item.bx--breadcrumb-item--current
.bx--link:hover {
    text-decoration: none; }

.bx--breadcrumb-item .bx--overflow-menu {
  position: relative;
  width: 1.25rem;
  height: 1.125rem; }
  .bx--breadcrumb-item .bx--overflow-menu:focus {
    outline: 1px solid #0f62fe; }
  .bx--breadcrumb-item .bx--overflow-menu:hover {
    background: transparent; }
  .bx--breadcrumb-item .bx--overflow-menu::after {
    position: absolute;
    bottom: 2px;
    width: 0.75rem;
    height: 1px;
    background: #0043ce;
    content: '';
    opacity: 0;
    transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--breadcrumb-item .bx--overflow-menu:hover::after {
  opacity: 1; }

.bx--breadcrumb-item
.bx--overflow-menu.bx--overflow-menu--open {
  background: transparent;
  box-shadow: none; }

.bx--breadcrumb-item .bx--overflow-menu__icon {
  position: relative;
  fill: #0f62fe;
  transform: translateY(4px); }

.bx--breadcrumb-item
.bx--overflow-menu:hover
.bx--overflow-menu__icon {
  fill: #0043ce; }

.bx--breadcrumb-menu-options:focus {
  outline: none; }

.bx--breadcrumb-menu-options.bx--overflow-menu-options::after {
  top: -0.4375rem;
  left: 0.875rem;
  width: 0;
  height: 0;
  border-right: 0.4375rem solid transparent;
  border-bottom: 0.4375rem solid #f4f4f4;
  border-left: 0.4375rem solid transparent;
  margin: 0 auto;
  background: transparent; }

.bx--breadcrumb.bx--skeleton .bx--link {
  position: relative;
  padding: 0;
  border: none;
  background: #e5e5e5;
  box-shadow: none;
  pointer-events: none;
  width: 6.25rem;
  height: 1rem; }
  .bx--breadcrumb.bx--skeleton .bx--link:hover, .bx--breadcrumb.bx--skeleton .bx--link:focus, .bx--breadcrumb.bx--skeleton .bx--link:active {
    border: none;
    cursor: default;
    outline: none; }
  .bx--breadcrumb.bx--skeleton .bx--link::before {
    position: absolute;
    width: 100%;
    height: 100%;
    animation: 3000ms ease-in-out skeleton infinite;
    background: #c6c6c6;
    content: '';
    will-change: transform-origin, transform, opacity; }
    @media (prefers-reduced-motion: reduce) {
      .bx--breadcrumb.bx--skeleton .bx--link::before {
        animation: none; } }

.bx--assistive-text,
.bx--visually-hidden {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  visibility: inherit;
  white-space: nowrap; }

.bx--body {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.29;
  letter-spacing: 0.16px;
  background-color: #ffffff;
  color: #161616;
  line-height: 1; }
  .bx--body *,
  .bx--body *::before,
  .bx--body *::after {
    box-sizing: inherit; }

.bx--btn {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.29;
  letter-spacing: 0.16px;
  position: relative;
  display: inline-flex;
  max-width: 20rem;
  min-height: 3rem;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: calc(0.875rem - 3px) 63px calc(0.875rem - 3px) 15px;
  margin: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  text-align: left;
  text-decoration: none;
  transition: background 70ms cubic-bezier(0, 0, 0.38, 0.9), box-shadow 70ms cubic-bezier(0, 0, 0.38, 0.9), border-color 70ms cubic-bezier(0, 0, 0.38, 0.9), outline 70ms cubic-bezier(0, 0, 0.38, 0.9);
  vertical-align: top; }
  .bx--btn *,
  .bx--btn *::before,
  .bx--btn *::after {
    box-sizing: inherit; }
  .bx--btn:disabled, .bx--btn:hover:disabled, .bx--btn:focus:disabled, .bx--btn.bx--btn--disabled, .bx--btn.bx--btn--disabled:hover, .bx--btn.bx--btn--disabled:focus {
    border-color: #c6c6c6;
    background: #c6c6c6;
    box-shadow: none;
    color: #8d8d8d;
    cursor: not-allowed; }
  .bx--btn .bx--btn__icon {
    position: absolute;
    right: 1rem;
    width: 1rem;
    height: 1rem;
    flex-shrink: 0; }

.bx--btn::-moz-focus-inner {
  padding: 0;
  border: 0; }

.bx--btn--primary {
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background-color: #0f62fe;
  color: #ffffff; }
  .bx--btn--primary:hover {
    background-color: #0353e9; }
  .bx--btn--primary:focus {
    border-color: #0f62fe;
    box-shadow: inset 0 0 0 1px #0f62fe, inset 0 0 0 2px #ffffff; }
  .bx--btn--primary:active {
    background-color: #002d9c; }
  .bx--btn--primary .bx--btn__icon,
  .bx--btn--primary .bx--btn__icon path:not([data-icon-path]):not([fill='none']) {
    fill: currentColor; }
  .bx--btn--primary:hover {
    color: #ffffff; }

.bx--btn--secondary {
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background-color: #393939;
  color: #ffffff; }
  .bx--btn--secondary:hover {
    background-color: #4c4c4c; }
  .bx--btn--secondary:focus {
    border-color: #0f62fe;
    box-shadow: inset 0 0 0 1px #0f62fe, inset 0 0 0 2px #ffffff; }
  .bx--btn--secondary:active {
    background-color: #6f6f6f; }
  .bx--btn--secondary .bx--btn__icon,
  .bx--btn--secondary .bx--btn__icon path:not([data-icon-path]):not([fill='none']) {
    fill: currentColor; }
  .bx--btn--secondary:hover, .bx--btn--secondary:focus {
    color: #ffffff; }

.bx--btn--tertiary {
  border-width: 1px;
  border-style: solid;
  border-color: #0f62fe;
  background-color: transparent;
  color: #0f62fe; }
  .bx--btn--tertiary:hover {
    background-color: #0353e9; }
  .bx--btn--tertiary:focus {
    border-color: #0f62fe;
    box-shadow: inset 0 0 0 1px #0f62fe, inset 0 0 0 2px #ffffff; }
  .bx--btn--tertiary:active {
    background-color: #002d9c; }
  .bx--btn--tertiary .bx--btn__icon,
  .bx--btn--tertiary .bx--btn__icon path:not([data-icon-path]):not([fill='none']) {
    fill: currentColor; }
  .bx--btn--tertiary:hover {
    color: #ffffff; }
  .bx--btn--tertiary:focus {
    background-color: #0f62fe;
    color: #ffffff; }
  .bx--btn--tertiary:active {
    border-color: transparent;
    background-color: #002d9c;
    color: #ffffff; }
  .bx--btn--tertiary:disabled, .bx--btn--tertiary:hover:disabled, .bx--btn--tertiary:focus:disabled, .bx--btn--tertiary.bx--btn--disabled, .bx--btn--tertiary.bx--btn--disabled:hover, .bx--btn--tertiary.bx--btn--disabled:focus {
    background: transparent;
    color: #8d8d8d;
    outline: none; }

.bx--btn--ghost {
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background-color: transparent;
  color: #0f62fe;
  padding: calc(0.875rem - 3px) 16px; }
  .bx--btn--ghost:hover {
    background-color: #e5e5e5; }
  .bx--btn--ghost:focus {
    border-color: #0f62fe;
    box-shadow: inset 0 0 0 1px #0f62fe, inset 0 0 0 2px #ffffff; }
  .bx--btn--ghost:active {
    background-color: #c6c6c6; }
  .bx--btn--ghost .bx--btn__icon,
  .bx--btn--ghost .bx--btn__icon path:not([data-icon-path]):not([fill='none']) {
    fill: currentColor; }
  .bx--btn--ghost .bx--btn__icon {
    position: static;
    margin-left: 0.5rem; }
  .bx--btn--ghost:hover, .bx--btn--ghost:active {
    color: #0043ce; }
  .bx--btn--ghost:active {
    background-color: #c6c6c6; }
  .bx--btn--ghost:disabled, .bx--btn--ghost:hover:disabled, .bx--btn--ghost:focus:disabled, .bx--btn--ghost.bx--btn--disabled, .bx--btn--ghost.bx--btn--disabled:hover, .bx--btn--ghost.bx--btn--disabled:focus {
    border-color: transparent;
    background: transparent;
    color: #8d8d8d;
    outline: none; }
  .bx--btn--ghost.bx--btn--sm {
    padding: calc(0.375rem - 3px) 16px; }
  .bx--btn--ghost.bx--btn--field, .bx--btn--ghost.bx--btn--md {
    padding: calc(0.675rem - 3px) 16px; }

.bx--btn.bx--btn--icon-only.bx--tooltip__trigger {
  position: relative;
  display: inline-flex;
  overflow: visible;
  align-items: center;
  cursor: pointer; }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus {
    outline: 1px solid #0f62fe; }
    @media screen and (prefers-contrast) {
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus {
        outline-style: dotted; } }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus {
    outline: 1px solid transparent; }
    .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus svg {
      outline: 1px solid #0f62fe; }
      @media screen and (prefers-contrast) {
        .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus svg {
          outline-style: dotted; } }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::before, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::after,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger .bx--assistive-text,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger + .bx--assistive-text {
    position: absolute;
    z-index: 6000;
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::before, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::after,
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger .bx--assistive-text,
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger + .bx--assistive-text {
        display: inline-block; } }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::before, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::after {
    transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--tooltip--a11y::before, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--tooltip--a11y::after {
    transition: none; }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::before {
    width: 0;
    height: 0;
    border-style: solid;
    content: ''; }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger .bx--assistive-text,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger + .bx--assistive-text {
    box-sizing: content-box;
    color: inherit;
    opacity: 1;
    white-space: normal;
    word-break: break-word; }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::after,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger .bx--assistive-text,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger + .bx--assistive-text {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    z-index: 6000;
    width: max-content;
    min-width: 1.5rem;
    max-width: 13rem;
    height: auto;
    padding: 0.1875rem 1rem;
    background-color: #393939;
    border-radius: 0.125rem;
    color: #ffffff;
    font-weight: 400;
    text-align: left;
    transform: translateX(-50%);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.29;
    letter-spacing: 0.16px; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::after,
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger .bx--assistive-text,
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger + .bx--assistive-text {
        width: auto; } }
    @supports (-ms-accelerator: true) {
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::after,
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger .bx--assistive-text,
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger + .bx--assistive-text {
        width: auto; } }
    @supports (-ms-ime-align: auto) {
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::after,
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger .bx--assistive-text,
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger + .bx--assistive-text {
        width: auto; } }
    @media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::after,
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger .bx--assistive-text,
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger + .bx--assistive-text {
        border: 1px solid transparent; } }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::after {
    content: attr(aria-label); }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--tooltip--a11y::after {
    content: none; }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--tooltip--visible::before, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--tooltip--visible::after, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:hover::before, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:hover::after, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus::before, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus::after {
    opacity: 1; }

@keyframes tooltip-fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--tooltip--visible .bx--assistive-text,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--tooltip--visible + .bx--assistive-text, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:hover .bx--assistive-text,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:hover + .bx--assistive-text, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus .bx--assistive-text,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus + .bx--assistive-text {
    overflow: visible;
    margin: auto;
    clip: auto; }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--tooltip--visible .bx--assistive-text,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--tooltip--visible + .bx--assistive-text, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--tooltip--visible.bx--tooltip--a11y::before, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:hover .bx--assistive-text,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:hover + .bx--assistive-text, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:hover.bx--tooltip--a11y::before, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus .bx--assistive-text,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus + .bx--assistive-text, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus.bx--tooltip--a11y::before {
    animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--tooltip--hidden .bx--assistive-text,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--tooltip--hidden + .bx--assistive-text {
    overflow: hidden;
    margin: -1px;
    clip: rect(0, 0, 0, 0); }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--tooltip--hidden.bx--tooltip--a11y::before {
    animation: none;
    opacity: 0; }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger svg,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:hover svg,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus svg {
    fill: currentColor; }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--btn--disabled.bx--tooltip--a11y::before, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--btn--disabled.bx--tooltip--a11y::after,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--btn--disabled .bx--assistive-text {
    overflow: hidden;
    margin: -1px;
    clip: rect(0, 0, 0, 0);
    opacity: 0; }

.bx--btn.bx--btn--icon-only:not(.bx--tooltip--hidden)
.bx--assistive-text {
  pointer-events: all; }

.bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus {
  border-color: #0f62fe; }

.bx--btn.bx--btn--icon-only.bx--tooltip__trigger:active:not([disabled]) {
  border-color: transparent; }

.bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus
svg {
  outline-color: transparent; }

.bx--btn.bx--btn--icon-only.bx--tooltip__trigger[disabled]:hover,
.bx--btn.bx--btn--icon-only.bx--tooltip__trigger[disabled]:focus,
.bx--btn.bx--btn--icon-only.bx--tooltip__trigger[disabled]:active {
  cursor: not-allowed;
  fill: #8d8d8d; }

.bx--tooltip__trigger.bx--btn--icon-only--top {
  position: relative;
  display: inline-flex;
  overflow: visible;
  align-items: center;
  cursor: pointer; }
  .bx--tooltip__trigger.bx--btn--icon-only--top:focus {
    outline: 1px solid #0f62fe; }
    @media screen and (prefers-contrast) {
      .bx--tooltip__trigger.bx--btn--icon-only--top:focus {
        outline-style: dotted; } }
  .bx--tooltip__trigger.bx--btn--icon-only--top:focus {
    outline: 1px solid transparent; }
    .bx--tooltip__trigger.bx--btn--icon-only--top:focus svg {
      outline: 1px solid #0f62fe; }
      @media screen and (prefers-contrast) {
        .bx--tooltip__trigger.bx--btn--icon-only--top:focus svg {
          outline-style: dotted; } }
  .bx--tooltip__trigger.bx--btn--icon-only--top::before, .bx--tooltip__trigger.bx--btn--icon-only--top::after,
  .bx--tooltip__trigger.bx--btn--icon-only--top .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--top + .bx--assistive-text {
    position: absolute;
    z-index: 6000;
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--btn--icon-only--top::before, .bx--tooltip__trigger.bx--btn--icon-only--top::after,
      .bx--tooltip__trigger.bx--btn--icon-only--top .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--top + .bx--assistive-text {
        display: inline-block; } }
  .bx--tooltip__trigger.bx--btn--icon-only--top::before, .bx--tooltip__trigger.bx--btn--icon-only--top::after {
    transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--a11y::after {
    transition: none; }
  .bx--tooltip__trigger.bx--btn--icon-only--top::before {
    width: 0;
    height: 0;
    border-style: solid;
    content: ''; }
  .bx--tooltip__trigger.bx--btn--icon-only--top .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--top + .bx--assistive-text {
    box-sizing: content-box;
    color: inherit;
    opacity: 1;
    white-space: normal;
    word-break: break-word; }
  .bx--tooltip__trigger.bx--btn--icon-only--top::after,
  .bx--tooltip__trigger.bx--btn--icon-only--top .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--top + .bx--assistive-text {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    z-index: 6000;
    width: max-content;
    min-width: 1.5rem;
    max-width: 13rem;
    height: auto;
    padding: 0.1875rem 1rem;
    background-color: #393939;
    border-radius: 0.125rem;
    color: #ffffff;
    font-weight: 400;
    text-align: left;
    transform: translateX(-50%);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.29;
    letter-spacing: 0.16px; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--btn--icon-only--top::after,
      .bx--tooltip__trigger.bx--btn--icon-only--top .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--top + .bx--assistive-text {
        width: auto; } }
    @supports (-ms-accelerator: true) {
      .bx--tooltip__trigger.bx--btn--icon-only--top::after,
      .bx--tooltip__trigger.bx--btn--icon-only--top .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--top + .bx--assistive-text {
        width: auto; } }
    @supports (-ms-ime-align: auto) {
      .bx--tooltip__trigger.bx--btn--icon-only--top::after,
      .bx--tooltip__trigger.bx--btn--icon-only--top .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--top + .bx--assistive-text {
        width: auto; } }
    @media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
      .bx--tooltip__trigger.bx--btn--icon-only--top::after,
      .bx--tooltip__trigger.bx--btn--icon-only--top .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--top + .bx--assistive-text {
        border: 1px solid transparent; } }
  .bx--tooltip__trigger.bx--btn--icon-only--top::after {
    content: attr(aria-label); }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--a11y::after {
    content: none; }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--visible::before, .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--visible::after, .bx--tooltip__trigger.bx--btn--icon-only--top:hover::before, .bx--tooltip__trigger.bx--btn--icon-only--top:hover::after, .bx--tooltip__trigger.bx--btn--icon-only--top:focus::before, .bx--tooltip__trigger.bx--btn--icon-only--top:focus::after {
    opacity: 1; }

@keyframes tooltip-fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--visible .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--visible + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--top:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--top:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--top:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--top:focus + .bx--assistive-text {
    overflow: visible;
    margin: auto;
    clip: auto; }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--visible .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--visible + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--visible.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--btn--icon-only--top:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--top:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--top:hover.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--btn--icon-only--top:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--top:focus + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--top:focus.bx--tooltip--a11y::before {
    animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--hidden .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--hidden + .bx--assistive-text {
    overflow: hidden;
    margin: -1px;
    clip: rect(0, 0, 0, 0); }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--hidden.bx--tooltip--a11y::before {
    animation: none;
    opacity: 0; }
  .bx--tooltip__trigger.bx--btn--icon-only--top .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    width: 100%;
    height: 0.75rem;
    bottom: -0.75rem; }
  .bx--tooltip__trigger.bx--btn--icon-only--top::before, .bx--tooltip__trigger.bx--btn--icon-only--top::after,
  .bx--tooltip__trigger.bx--btn--icon-only--top .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--top + .bx--assistive-text {
    top: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--btn--icon-only--top::before {
    top: -0.5rem;
    border-width: 0.3125rem 0.25rem 0 0.25rem;
    border-color: #393939 transparent transparent transparent;
    transform: translate(-50%, -100%); }
  .bx--tooltip__trigger.bx--btn--icon-only--top::after,
  .bx--tooltip__trigger.bx--btn--icon-only--top .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--top + .bx--assistive-text {
    top: -0.8125rem;
    left: 50%;
    transform: translate(-50%, -100%); }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-start .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    width: 100%;
    height: 0.75rem;
    bottom: -0.75rem; }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-start::before, .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-start .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-start + .bx--assistive-text {
    top: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-start::before {
    top: -0.5rem;
    border-width: 0.3125rem 0.25rem 0 0.25rem;
    border-color: #393939 transparent transparent transparent;
    transform: translate(-50%, -100%); }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-start .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-start + .bx--assistive-text {
    top: -0.8125rem;
    left: 0;
    transform: translate(0, -100%); }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-center .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    width: 100%;
    height: 0.75rem;
    bottom: -0.75rem; }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-center::before, .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-center .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-center + .bx--assistive-text {
    top: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-center::before {
    top: -0.5rem;
    border-width: 0.3125rem 0.25rem 0 0.25rem;
    border-color: #393939 transparent transparent transparent;
    transform: translate(-50%, -100%); }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-center .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-center + .bx--assistive-text {
    top: -0.8125rem;
    left: 50%;
    transform: translate(-50%, -100%); }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-end .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    width: 100%;
    height: 0.75rem;
    bottom: -0.75rem; }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-end::before, .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-end .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-end + .bx--assistive-text {
    top: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-end::before {
    top: -0.5rem;
    border-width: 0.3125rem 0.25rem 0 0.25rem;
    border-color: #393939 transparent transparent transparent;
    transform: translate(-50%, -100%); }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-end .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-end + .bx--assistive-text {
    top: -0.8125rem;
    right: 0;
    left: auto;
    transform: translate(0, -100%); }

.bx--tooltip__trigger.bx--btn--icon-only--right {
  position: relative;
  display: inline-flex;
  overflow: visible;
  align-items: center;
  cursor: pointer; }
  .bx--tooltip__trigger.bx--btn--icon-only--right:focus {
    outline: 1px solid #0f62fe; }
    @media screen and (prefers-contrast) {
      .bx--tooltip__trigger.bx--btn--icon-only--right:focus {
        outline-style: dotted; } }
  .bx--tooltip__trigger.bx--btn--icon-only--right:focus {
    outline: 1px solid transparent; }
    .bx--tooltip__trigger.bx--btn--icon-only--right:focus svg {
      outline: 1px solid #0f62fe; }
      @media screen and (prefers-contrast) {
        .bx--tooltip__trigger.bx--btn--icon-only--right:focus svg {
          outline-style: dotted; } }
  .bx--tooltip__trigger.bx--btn--icon-only--right::before, .bx--tooltip__trigger.bx--btn--icon-only--right::after,
  .bx--tooltip__trigger.bx--btn--icon-only--right .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--right + .bx--assistive-text {
    position: absolute;
    z-index: 6000;
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--btn--icon-only--right::before, .bx--tooltip__trigger.bx--btn--icon-only--right::after,
      .bx--tooltip__trigger.bx--btn--icon-only--right .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--right + .bx--assistive-text {
        display: inline-block; } }
  .bx--tooltip__trigger.bx--btn--icon-only--right::before, .bx--tooltip__trigger.bx--btn--icon-only--right::after {
    transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--a11y::after {
    transition: none; }
  .bx--tooltip__trigger.bx--btn--icon-only--right::before {
    width: 0;
    height: 0;
    border-style: solid;
    content: ''; }
  .bx--tooltip__trigger.bx--btn--icon-only--right .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--right + .bx--assistive-text {
    box-sizing: content-box;
    color: inherit;
    opacity: 1;
    white-space: normal;
    word-break: break-word; }
  .bx--tooltip__trigger.bx--btn--icon-only--right::after,
  .bx--tooltip__trigger.bx--btn--icon-only--right .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--right + .bx--assistive-text {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    z-index: 6000;
    width: max-content;
    min-width: 1.5rem;
    max-width: 13rem;
    height: auto;
    padding: 0.1875rem 1rem;
    background-color: #393939;
    border-radius: 0.125rem;
    color: #ffffff;
    font-weight: 400;
    text-align: left;
    transform: translateX(-50%);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.29;
    letter-spacing: 0.16px; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--btn--icon-only--right::after,
      .bx--tooltip__trigger.bx--btn--icon-only--right .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--right + .bx--assistive-text {
        width: auto; } }
    @supports (-ms-accelerator: true) {
      .bx--tooltip__trigger.bx--btn--icon-only--right::after,
      .bx--tooltip__trigger.bx--btn--icon-only--right .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--right + .bx--assistive-text {
        width: auto; } }
    @supports (-ms-ime-align: auto) {
      .bx--tooltip__trigger.bx--btn--icon-only--right::after,
      .bx--tooltip__trigger.bx--btn--icon-only--right .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--right + .bx--assistive-text {
        width: auto; } }
    @media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
      .bx--tooltip__trigger.bx--btn--icon-only--right::after,
      .bx--tooltip__trigger.bx--btn--icon-only--right .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--right + .bx--assistive-text {
        border: 1px solid transparent; } }
  .bx--tooltip__trigger.bx--btn--icon-only--right::after {
    content: attr(aria-label); }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--a11y::after {
    content: none; }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--visible::before, .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--visible::after, .bx--tooltip__trigger.bx--btn--icon-only--right:hover::before, .bx--tooltip__trigger.bx--btn--icon-only--right:hover::after, .bx--tooltip__trigger.bx--btn--icon-only--right:focus::before, .bx--tooltip__trigger.bx--btn--icon-only--right:focus::after {
    opacity: 1; }

@keyframes tooltip-fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--visible .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--visible + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--right:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--right:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--right:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--right:focus + .bx--assistive-text {
    overflow: visible;
    margin: auto;
    clip: auto; }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--visible .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--visible + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--visible.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--btn--icon-only--right:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--right:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--right:hover.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--btn--icon-only--right:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--right:focus + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--right:focus.bx--tooltip--a11y::before {
    animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--hidden .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--hidden + .bx--assistive-text {
    overflow: hidden;
    margin: -1px;
    clip: rect(0, 0, 0, 0); }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--hidden.bx--tooltip--a11y::before {
    animation: none;
    opacity: 0; }
  .bx--tooltip__trigger.bx--btn--icon-only--right .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    width: 0.75rem;
    height: 100%;
    left: -0.75rem; }
  .bx--tooltip__trigger.bx--btn--icon-only--right::before, .bx--tooltip__trigger.bx--btn--icon-only--right::after,
  .bx--tooltip__trigger.bx--btn--icon-only--right .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--right + .bx--assistive-text {
    top: 50%;
    right: 0; }
  .bx--tooltip__trigger.bx--btn--icon-only--right::before {
    right: -0.5rem;
    border-width: 0.25rem 0.3125rem 0.25rem 0;
    border-color: transparent #393939 transparent transparent;
    transform: translate(100%, -50%); }
  .bx--tooltip__trigger.bx--btn--icon-only--right::after,
  .bx--tooltip__trigger.bx--btn--icon-only--right .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--right + .bx--assistive-text {
    right: -0.8125rem;
    transform: translate(100%, -50%); }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-start .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    width: 0.75rem;
    height: 100%;
    left: -0.75rem; }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-start::before, .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-start .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-start + .bx--assistive-text {
    top: 50%;
    right: 0; }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-start::before {
    right: -0.5rem;
    border-width: 0.25rem 0.3125rem 0.25rem 0;
    border-color: transparent #393939 transparent transparent;
    transform: translate(100%, -50%); }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-start .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-start + .bx--assistive-text {
    right: -0.8125rem;
    transform: translate(100%, -50%); }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-center .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    width: 0.75rem;
    height: 100%;
    left: -0.75rem; }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-center::before, .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-center .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-center + .bx--assistive-text {
    top: 50%;
    right: 0; }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-center::before {
    right: -0.5rem;
    border-width: 0.25rem 0.3125rem 0.25rem 0;
    border-color: transparent #393939 transparent transparent;
    transform: translate(100%, -50%); }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-center .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-center + .bx--assistive-text {
    right: -0.8125rem;
    transform: translate(100%, -50%); }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-end .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    width: 0.75rem;
    height: 100%;
    left: -0.75rem; }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-end::before, .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-end .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-end + .bx--assistive-text {
    top: 50%;
    right: 0; }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-end::before {
    right: -0.5rem;
    border-width: 0.25rem 0.3125rem 0.25rem 0;
    border-color: transparent #393939 transparent transparent;
    transform: translate(100%, -50%); }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-end .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-end + .bx--assistive-text {
    right: -0.8125rem;
    transform: translate(100%, -50%); }

.bx--tooltip__trigger.bx--btn--icon-only--bottom {
  position: relative;
  display: inline-flex;
  overflow: visible;
  align-items: center;
  cursor: pointer; }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom:focus {
    outline: 1px solid #0f62fe; }
    @media screen and (prefers-contrast) {
      .bx--tooltip__trigger.bx--btn--icon-only--bottom:focus {
        outline-style: dotted; } }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom:focus {
    outline: 1px solid transparent; }
    .bx--tooltip__trigger.bx--btn--icon-only--bottom:focus svg {
      outline: 1px solid #0f62fe; }
      @media screen and (prefers-contrast) {
        .bx--tooltip__trigger.bx--btn--icon-only--bottom:focus svg {
          outline-style: dotted; } }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom::before, .bx--tooltip__trigger.bx--btn--icon-only--bottom::after,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom + .bx--assistive-text {
    position: absolute;
    z-index: 6000;
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--btn--icon-only--bottom::before, .bx--tooltip__trigger.bx--btn--icon-only--bottom::after,
      .bx--tooltip__trigger.bx--btn--icon-only--bottom .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--bottom + .bx--assistive-text {
        display: inline-block; } }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom::before, .bx--tooltip__trigger.bx--btn--icon-only--bottom::after {
    transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--a11y::after {
    transition: none; }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom::before {
    width: 0;
    height: 0;
    border-style: solid;
    content: ''; }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom + .bx--assistive-text {
    box-sizing: content-box;
    color: inherit;
    opacity: 1;
    white-space: normal;
    word-break: break-word; }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom::after,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom + .bx--assistive-text {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    z-index: 6000;
    width: max-content;
    min-width: 1.5rem;
    max-width: 13rem;
    height: auto;
    padding: 0.1875rem 1rem;
    background-color: #393939;
    border-radius: 0.125rem;
    color: #ffffff;
    font-weight: 400;
    text-align: left;
    transform: translateX(-50%);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.29;
    letter-spacing: 0.16px; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--btn--icon-only--bottom::after,
      .bx--tooltip__trigger.bx--btn--icon-only--bottom .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--bottom + .bx--assistive-text {
        width: auto; } }
    @supports (-ms-accelerator: true) {
      .bx--tooltip__trigger.bx--btn--icon-only--bottom::after,
      .bx--tooltip__trigger.bx--btn--icon-only--bottom .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--bottom + .bx--assistive-text {
        width: auto; } }
    @supports (-ms-ime-align: auto) {
      .bx--tooltip__trigger.bx--btn--icon-only--bottom::after,
      .bx--tooltip__trigger.bx--btn--icon-only--bottom .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--bottom + .bx--assistive-text {
        width: auto; } }
    @media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
      .bx--tooltip__trigger.bx--btn--icon-only--bottom::after,
      .bx--tooltip__trigger.bx--btn--icon-only--bottom .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--bottom + .bx--assistive-text {
        border: 1px solid transparent; } }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom::after {
    content: attr(aria-label); }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--a11y::after {
    content: none; }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--visible::before, .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--visible::after, .bx--tooltip__trigger.bx--btn--icon-only--bottom:hover::before, .bx--tooltip__trigger.bx--btn--icon-only--bottom:hover::after, .bx--tooltip__trigger.bx--btn--icon-only--bottom:focus::before, .bx--tooltip__trigger.bx--btn--icon-only--bottom:focus::after {
    opacity: 1; }

@keyframes tooltip-fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--visible .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--visible + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--bottom:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--bottom:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom:focus + .bx--assistive-text {
    overflow: visible;
    margin: auto;
    clip: auto; }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--visible .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--visible + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--visible.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--btn--icon-only--bottom:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--bottom:hover.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--btn--icon-only--bottom:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom:focus + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--bottom:focus.bx--tooltip--a11y::before {
    animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--hidden .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--hidden + .bx--assistive-text {
    overflow: hidden;
    margin: -1px;
    clip: rect(0, 0, 0, 0); }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--hidden.bx--tooltip--a11y::before {
    animation: none;
    opacity: 0; }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    width: 100%;
    height: 0.75rem;
    top: -0.75rem; }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom::before, .bx--tooltip__trigger.bx--btn--icon-only--bottom::after,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom + .bx--assistive-text {
    bottom: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom::before {
    bottom: -0.5rem;
    border-width: 0 0.25rem 0.3125rem 0.25rem;
    border-color: transparent transparent #393939 transparent;
    transform: translate(-50%, 100%); }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom::after,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom + .bx--assistive-text {
    bottom: -0.8125rem;
    transform: translate(-50%, 100%); }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-start .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    width: 100%;
    height: 0.75rem;
    top: -0.75rem; }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-start::before, .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-start .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-start + .bx--assistive-text {
    bottom: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-start::before {
    bottom: -0.5rem;
    border-width: 0 0.25rem 0.3125rem 0.25rem;
    border-color: transparent transparent #393939 transparent;
    transform: translate(-50%, 100%); }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-start .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-start + .bx--assistive-text {
    bottom: -0.8125rem;
    left: 0;
    transform: translate(0, 100%); }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-center .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    width: 100%;
    height: 0.75rem;
    top: -0.75rem; }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-center::before, .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-center .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-center + .bx--assistive-text {
    bottom: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-center::before {
    bottom: -0.5rem;
    border-width: 0 0.25rem 0.3125rem 0.25rem;
    border-color: transparent transparent #393939 transparent;
    transform: translate(-50%, 100%); }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-center .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-center + .bx--assistive-text {
    bottom: -0.8125rem;
    transform: translate(-50%, 100%); }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-end .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    width: 100%;
    height: 0.75rem;
    top: -0.75rem; }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-end::before, .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-end .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-end + .bx--assistive-text {
    bottom: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-end::before {
    bottom: -0.5rem;
    border-width: 0 0.25rem 0.3125rem 0.25rem;
    border-color: transparent transparent #393939 transparent;
    transform: translate(-50%, 100%); }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-end .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-end + .bx--assistive-text {
    bottom: -0.8125rem;
    right: 0;
    left: auto;
    transform: translate(0, 100%); }

.bx--tooltip__trigger.bx--btn--icon-only--left {
  position: relative;
  display: inline-flex;
  overflow: visible;
  align-items: center;
  cursor: pointer; }
  .bx--tooltip__trigger.bx--btn--icon-only--left:focus {
    outline: 1px solid #0f62fe; }
    @media screen and (prefers-contrast) {
      .bx--tooltip__trigger.bx--btn--icon-only--left:focus {
        outline-style: dotted; } }
  .bx--tooltip__trigger.bx--btn--icon-only--left:focus {
    outline: 1px solid transparent; }
    .bx--tooltip__trigger.bx--btn--icon-only--left:focus svg {
      outline: 1px solid #0f62fe; }
      @media screen and (prefers-contrast) {
        .bx--tooltip__trigger.bx--btn--icon-only--left:focus svg {
          outline-style: dotted; } }
  .bx--tooltip__trigger.bx--btn--icon-only--left::before, .bx--tooltip__trigger.bx--btn--icon-only--left::after,
  .bx--tooltip__trigger.bx--btn--icon-only--left .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--left + .bx--assistive-text {
    position: absolute;
    z-index: 6000;
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--btn--icon-only--left::before, .bx--tooltip__trigger.bx--btn--icon-only--left::after,
      .bx--tooltip__trigger.bx--btn--icon-only--left .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--left + .bx--assistive-text {
        display: inline-block; } }
  .bx--tooltip__trigger.bx--btn--icon-only--left::before, .bx--tooltip__trigger.bx--btn--icon-only--left::after {
    transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--a11y::after {
    transition: none; }
  .bx--tooltip__trigger.bx--btn--icon-only--left::before {
    width: 0;
    height: 0;
    border-style: solid;
    content: ''; }
  .bx--tooltip__trigger.bx--btn--icon-only--left .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--left + .bx--assistive-text {
    box-sizing: content-box;
    color: inherit;
    opacity: 1;
    white-space: normal;
    word-break: break-word; }
  .bx--tooltip__trigger.bx--btn--icon-only--left::after,
  .bx--tooltip__trigger.bx--btn--icon-only--left .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--left + .bx--assistive-text {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    z-index: 6000;
    width: max-content;
    min-width: 1.5rem;
    max-width: 13rem;
    height: auto;
    padding: 0.1875rem 1rem;
    background-color: #393939;
    border-radius: 0.125rem;
    color: #ffffff;
    font-weight: 400;
    text-align: left;
    transform: translateX(-50%);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.29;
    letter-spacing: 0.16px; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--btn--icon-only--left::after,
      .bx--tooltip__trigger.bx--btn--icon-only--left .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--left + .bx--assistive-text {
        width: auto; } }
    @supports (-ms-accelerator: true) {
      .bx--tooltip__trigger.bx--btn--icon-only--left::after,
      .bx--tooltip__trigger.bx--btn--icon-only--left .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--left + .bx--assistive-text {
        width: auto; } }
    @supports (-ms-ime-align: auto) {
      .bx--tooltip__trigger.bx--btn--icon-only--left::after,
      .bx--tooltip__trigger.bx--btn--icon-only--left .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--left + .bx--assistive-text {
        width: auto; } }
    @media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
      .bx--tooltip__trigger.bx--btn--icon-only--left::after,
      .bx--tooltip__trigger.bx--btn--icon-only--left .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--left + .bx--assistive-text {
        border: 1px solid transparent; } }
  .bx--tooltip__trigger.bx--btn--icon-only--left::after {
    content: attr(aria-label); }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--a11y::after {
    content: none; }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--visible::before, .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--visible::after, .bx--tooltip__trigger.bx--btn--icon-only--left:hover::before, .bx--tooltip__trigger.bx--btn--icon-only--left:hover::after, .bx--tooltip__trigger.bx--btn--icon-only--left:focus::before, .bx--tooltip__trigger.bx--btn--icon-only--left:focus::after {
    opacity: 1; }

@keyframes tooltip-fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--visible .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--visible + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--left:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--left:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--left:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--left:focus + .bx--assistive-text {
    overflow: visible;
    margin: auto;
    clip: auto; }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--visible .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--visible + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--visible.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--btn--icon-only--left:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--left:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--left:hover.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--btn--icon-only--left:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--left:focus + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--left:focus.bx--tooltip--a11y::before {
    animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--hidden .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--hidden + .bx--assistive-text {
    overflow: hidden;
    margin: -1px;
    clip: rect(0, 0, 0, 0); }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--hidden.bx--tooltip--a11y::before {
    animation: none;
    opacity: 0; }
  .bx--tooltip__trigger.bx--btn--icon-only--left .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    width: 0.75rem;
    height: 100%;
    right: -0.75rem; }
  .bx--tooltip__trigger.bx--btn--icon-only--left::before, .bx--tooltip__trigger.bx--btn--icon-only--left::after,
  .bx--tooltip__trigger.bx--btn--icon-only--left .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--left + .bx--assistive-text {
    top: 50%;
    left: 0; }
  .bx--tooltip__trigger.bx--btn--icon-only--left::before {
    left: -0.5rem;
    border-width: 0.25rem 0 0.25rem 0.3125rem;
    border-color: transparent transparent transparent #393939;
    transform: translate(-100%, -50%); }
  .bx--tooltip__trigger.bx--btn--icon-only--left::after,
  .bx--tooltip__trigger.bx--btn--icon-only--left .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--left + .bx--assistive-text {
    left: -0.8125rem;
    transform: translate(-100%, -50%); }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-start .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    width: 0.75rem;
    height: 100%;
    right: -0.75rem; }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-start::before, .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-start .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-start + .bx--assistive-text {
    top: 50%;
    left: 0; }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-start::before {
    left: -0.5rem;
    border-width: 0.25rem 0 0.25rem 0.3125rem;
    border-color: transparent transparent transparent #393939;
    transform: translate(-100%, -50%); }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-start .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-start + .bx--assistive-text {
    left: -0.8125rem;
    transform: translate(-100%, -50%); }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-center .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    width: 0.75rem;
    height: 100%;
    right: -0.75rem; }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-center::before, .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-center .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-center + .bx--assistive-text {
    top: 50%;
    left: 0; }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-center::before {
    left: -0.5rem;
    border-width: 0.25rem 0 0.25rem 0.3125rem;
    border-color: transparent transparent transparent #393939;
    transform: translate(-100%, -50%); }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-center .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-center + .bx--assistive-text {
    left: -0.8125rem;
    transform: translate(-100%, -50%); }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-end .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    width: 0.75rem;
    height: 100%;
    right: -0.75rem; }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-end::before, .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-end .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-end + .bx--assistive-text {
    top: 50%;
    left: 0; }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-end::before {
    left: -0.5rem;
    border-width: 0.25rem 0 0.25rem 0.3125rem;
    border-color: transparent transparent transparent #393939;
    transform: translate(-100%, -50%); }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-end .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-end + .bx--assistive-text {
    left: -0.8125rem;
    transform: translate(-100%, -50%); }

.bx--btn--icon-only {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem; }
  .bx--btn--icon-only .bx--btn__icon {
    position: static; }
  .bx--btn--icon-only.bx--btn--ghost .bx--btn__icon,
  .bx--btn--icon-only.bx--btn--danger--ghost .bx--btn__icon {
    margin: 0; }

.bx--btn--icon-only.bx--btn--selected {
  background: #e0e0e0; }

.bx--btn path[data-icon-path='inner-path'] {
  fill: none; }

.bx--btn--ghost.bx--btn--icon-only
.bx--btn__icon
path:not([data-icon-path]):not([fill='none']),
.bx--btn--ghost.bx--btn--icon-only .bx--btn__icon {
  fill: #161616; }

.bx--btn--ghost.bx--btn--icon-only[disabled]
.bx--btn__icon
path:not([data-icon-path]):not([fill='none']),
.bx--btn--ghost.bx--btn--icon-only[disabled]
.bx--btn__icon,
.bx--btn.bx--btn--icon-only.bx--btn--ghost[disabled]:hover
.bx--btn__icon {
  fill: #8d8d8d; }

.bx--btn--ghost.bx--btn--icon-only[disabled] {
  cursor: not-allowed; }

.bx--btn--field.bx--btn--icon-only,
.bx--btn--md.bx--btn--icon-only {
  padding-right: 0.6875rem;
  padding-left: 0.6875rem; }

.bx--btn--sm.bx--btn--icon-only {
  padding-right: 0.4375rem;
  padding-left: 0.4375rem; }

.bx--btn--danger {
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background-color: #da1e28;
  color: #ffffff; }
  .bx--btn--danger:hover {
    background-color: #b81921; }
  .bx--btn--danger:focus {
    border-color: #0f62fe;
    box-shadow: inset 0 0 0 1px #0f62fe, inset 0 0 0 2px #ffffff; }
  .bx--btn--danger:active {
    background-color: #750e13; }
  .bx--btn--danger .bx--btn__icon,
  .bx--btn--danger .bx--btn__icon path:not([data-icon-path]):not([fill='none']) {
    fill: currentColor; }
  .bx--btn--danger:hover {
    color: #ffffff; }
  .bx--btn--danger-tertiary, .bx--btn--danger--tertiary {
    border-width: 1px;
    border-style: solid;
    border-color: #da1e28;
    background-color: transparent;
    color: #da1e28; }
    .bx--btn--danger-tertiary:hover, .bx--btn--danger--tertiary:hover {
      background-color: #b81921; }
    .bx--btn--danger-tertiary:focus, .bx--btn--danger--tertiary:focus {
      border-color: #0f62fe;
      box-shadow: inset 0 0 0 1px #0f62fe, inset 0 0 0 2px #ffffff; }
    .bx--btn--danger-tertiary:active, .bx--btn--danger--tertiary:active {
      background-color: #750e13; }
    .bx--btn--danger-tertiary .bx--btn__icon,
    .bx--btn--danger-tertiary .bx--btn__icon path:not([data-icon-path]):not([fill='none']), .bx--btn--danger--tertiary .bx--btn__icon,
    .bx--btn--danger--tertiary .bx--btn__icon path:not([data-icon-path]):not([fill='none']) {
      fill: currentColor; }
    .bx--btn--danger-tertiary:hover, .bx--btn--danger--tertiary:hover {
      border-color: #b81921;
      color: #ffffff; }
    .bx--btn--danger-tertiary:focus, .bx--btn--danger--tertiary:focus {
      background-color: #da1e28;
      color: #ffffff; }
    .bx--btn--danger-tertiary:active, .bx--btn--danger--tertiary:active {
      border-color: #750e13;
      color: #ffffff; }
    .bx--btn--danger-tertiary:disabled, .bx--btn--danger-tertiary:hover:disabled, .bx--btn--danger-tertiary:focus:disabled, .bx--btn--danger-tertiary.bx--btn--disabled, .bx--btn--danger-tertiary.bx--btn--disabled:hover, .bx--btn--danger-tertiary.bx--btn--disabled:focus, .bx--btn--danger--tertiary:disabled, .bx--btn--danger--tertiary:hover:disabled, .bx--btn--danger--tertiary:focus:disabled, .bx--btn--danger--tertiary.bx--btn--disabled, .bx--btn--danger--tertiary.bx--btn--disabled:hover, .bx--btn--danger--tertiary.bx--btn--disabled:focus {
      background: transparent;
      color: #8d8d8d;
      outline: none; }
  .bx--btn--danger-ghost, .bx--btn--danger--ghost {
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    background-color: transparent;
    color: #da1e28;
    padding: calc(0.875rem - 3px) 16px; }
    .bx--btn--danger-ghost:hover, .bx--btn--danger--ghost:hover {
      background-color: #b81921; }
    .bx--btn--danger-ghost:focus, .bx--btn--danger--ghost:focus {
      border-color: #0f62fe;
      box-shadow: inset 0 0 0 1px #0f62fe, inset 0 0 0 2px #ffffff; }
    .bx--btn--danger-ghost:active, .bx--btn--danger--ghost:active {
      background-color: #750e13; }
    .bx--btn--danger-ghost .bx--btn__icon,
    .bx--btn--danger-ghost .bx--btn__icon path:not([data-icon-path]):not([fill='none']), .bx--btn--danger--ghost .bx--btn__icon,
    .bx--btn--danger--ghost .bx--btn__icon path:not([data-icon-path]):not([fill='none']) {
      fill: currentColor; }
    .bx--btn--danger-ghost .bx--btn__icon, .bx--btn--danger--ghost .bx--btn__icon {
      position: static;
      margin-left: 0.5rem; }
    .bx--btn--danger-ghost:hover, .bx--btn--danger-ghost:active, .bx--btn--danger--ghost:hover, .bx--btn--danger--ghost:active {
      color: #ffffff; }
    .bx--btn--danger-ghost:disabled, .bx--btn--danger-ghost:hover:disabled, .bx--btn--danger-ghost:focus:disabled, .bx--btn--danger-ghost.bx--btn--disabled, .bx--btn--danger-ghost.bx--btn--disabled:hover, .bx--btn--danger-ghost.bx--btn--disabled:focus, .bx--btn--danger--ghost:disabled, .bx--btn--danger--ghost:hover:disabled, .bx--btn--danger--ghost:focus:disabled, .bx--btn--danger--ghost.bx--btn--disabled, .bx--btn--danger--ghost.bx--btn--disabled:hover, .bx--btn--danger--ghost.bx--btn--disabled:focus {
      border-color: transparent;
      background: transparent;
      color: #c6c6c6;
      outline: none; }
    .bx--btn--danger-ghost.bx--btn--sm, .bx--btn--danger--ghost.bx--btn--sm {
      padding: calc(0.375rem - 3px) 16px; }
    .bx--btn--danger-ghost.bx--btn--field, .bx--btn--danger-ghost.bx--btn--md, .bx--btn--danger--ghost.bx--btn--field, .bx--btn--danger--ghost.bx--btn--md {
      padding: calc(0.675rem - 3px) 16px; }

.bx--btn--sm {
  min-height: 2rem;
  padding: calc(0.375rem - 3px) 60px calc(0.375rem - 3px) 12px; }

.bx--btn--xl:not(.bx--btn--icon-only) {
  align-items: baseline;
  padding-top: 1rem;
  padding-right: 4rem;
  padding-left: 1rem;
  min-height: 5rem; }

.bx--btn--lg:not(.bx--btn--icon-only) {
  align-items: baseline;
  padding-top: 1rem;
  padding-right: 4rem;
  padding-left: 1rem;
  min-height: 4rem; }

.bx--btn--field,
.bx--btn--md {
  min-height: 2.5rem;
  padding: calc(0.675rem - 3px) 60px calc(0.675rem - 3px) 12px; }

.bx--btn--expressive {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375;
  letter-spacing: 0;
  min-height: 3rem; }

.bx--btn--icon-only.bx--btn--expressive {
  padding: 12px 13px; }

.bx--btn.bx--btn--expressive .bx--btn__icon {
  width: 1.25rem;
  height: 1.25rem; }

.bx--btn-set .bx--btn.bx--btn--expressive {
  max-width: 20rem; }

.bx--btn.bx--skeleton {
  position: relative;
  padding: 0;
  border: none;
  background: #e5e5e5;
  box-shadow: none;
  pointer-events: none;
  width: 9.375rem; }
  .bx--btn.bx--skeleton:hover, .bx--btn.bx--skeleton:focus, .bx--btn.bx--skeleton:active {
    border: none;
    cursor: default;
    outline: none; }
  .bx--btn.bx--skeleton::before {
    position: absolute;
    width: 100%;
    height: 100%;
    animation: 3000ms ease-in-out skeleton infinite;
    background: #c6c6c6;
    content: '';
    will-change: transform-origin, transform, opacity; }
    @media (prefers-reduced-motion: reduce) {
      .bx--btn.bx--skeleton::before {
        animation: none; } }

.bx--btn-set {
  display: flex; }

.bx--btn-set--stacked {
  flex-direction: column; }

.bx--btn-set .bx--btn {
  width: 100%;
  max-width: 12.25rem; }
  .bx--btn-set .bx--btn:not(:focus) {
    box-shadow: -0.0625rem 0 0 0 #e0e0e0; }
  .bx--btn-set .bx--btn:first-of-type:not(:focus) {
    box-shadow: inherit; }

.bx--btn-set .bx--btn:focus + .bx--btn {
  box-shadow: inherit; }

.bx--btn-set--stacked .bx--btn:not(:focus) {
  box-shadow: 0 -0.0625rem 0 0 #e0e0e0; }

.bx--btn-set--stacked .bx--btn:first-of-type:not(:focus) {
  box-shadow: inherit; }

.bx--btn-set .bx--btn.bx--btn--disabled {
  box-shadow: -0.0625rem 0 0 0 #8d8d8d; }
  .bx--btn-set .bx--btn.bx--btn--disabled:first-of-type {
    box-shadow: none; }

.bx--btn-set--stacked .bx--btn.bx--btn--disabled {
  box-shadow: 0 -0.0625rem 0 0 #8d8d8d; }
  .bx--btn-set--stacked .bx--btn.bx--btn--disabled:first-of-type {
    box-shadow: none; }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--btn:focus {
    color: Highlight;
    outline: 1px solid Highlight; } }

.bx--table-toolbar {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 3rem;
  background-color: #f4f4f4; }

.bx--toolbar-content {
  display: flex;
  width: 100%;
  height: 3rem;
  justify-content: flex-end;
  transform: translate3d(0, 0, 0);
  transition: transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), clip-path 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--batch-actions ~ .bx--toolbar-content {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); }

.bx--toolbar-content .bx--search .bx--search-input {
  background-color: transparent; }

.bx--batch-actions ~ .bx--toolbar-search-container {
  display: flex;
  align-items: center;
  opacity: 1;
  transition: opacity 110ms; }

.bx--toolbar-content
.bx--toolbar-search-container-expandable {
  position: relative;
  width: 3rem;
  height: 3rem;
  box-shadow: none;
  cursor: pointer;
  transition: width 240ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0, 0, 0.38, 0.9); }
  .bx--toolbar-content
.bx--toolbar-search-container-expandable:hover {
    background-color: #e5e5e5; }

.bx--toolbar-search-container-expandable .bx--search-input {
  height: 100%;
  padding: 0;
  cursor: pointer;
  opacity: 0; }

.bx--toolbar-search-container-expandable
.bx--search-magnifier-icon {
  left: 0;
  width: 3rem;
  height: 3rem;
  padding: 1rem;
  fill: #161616; }

.bx--toolbar-search-container-expandable.bx--search--disabled
.bx--search-magnifier-icon {
  background-color: #f4f4f4;
  cursor: not-allowed;
  transition: background-color none; }

.bx--toolbar-search-container-disabled .bx--search-input {
  cursor: not-allowed; }

.bx--toolbar-search-container-expandable.bx--search
.bx--label {
  visibility: hidden; }

.bx--toolbar-search-container-expandable.bx--search
.bx--search-close {
  width: 3rem;
  height: 3rem; }
  .bx--toolbar-search-container-expandable.bx--search
.bx--search-close::before {
    top: 0.125rem;
    height: calc(100% - 0.25rem);
    background-color: #e5e5e5; }

.bx--toolbar-search-container-expandable.bx--search
.bx--search-close:focus::before {
  background-color: #0f62fe; }

.bx--toolbar-search-container-active.bx--search {
  width: 100%; }

.bx--toolbar-search-container-active .bx--search-input {
  opacity: 1; }

.bx--toolbar-search-container-active .bx--label,
.bx--toolbar-search-container-active .bx--search-input {
  padding: 0 3rem;
  cursor: text; }

.bx--toolbar-search-container-active
.bx--search-input:focus
+ .bx--search-close {
  border: none;
  box-shadow: none;
  outline: none; }

.bx--toolbar-search-container-active
.bx--search-input:not(:placeholder-shown) {
  border: none;
  background-color: #e5e5e5; }

.bx--toolbar-search-container-active
.bx--search-magnifier-icon:focus,
.bx--toolbar-search-container-active
.bx--search-magnifier-icon:active,
.bx--toolbar-search-container-active
.bx--search-magnifier-icon:hover {
  border: none;
  background-color: transparent;
  outline: none; }

.bx--toolbar-search-container-persistent .bx--search-close,
.bx--toolbar-search-container-persistent
.bx--search-close:hover,
.bx--toolbar-search-container-active .bx--search-close,
.bx--toolbar-search-container-active .bx--search-close:hover {
  border: none;
  background-color: transparent; }

.bx--toolbar-search-container-persistent
.bx--search-close::before {
  display: none; }

.bx--overflow-menu.bx--toolbar-action {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  appearance: none;
  background: none;
  cursor: pointer;
  width: 100%;
  display: flex;
  width: 3rem;
  height: 3rem;
  padding: 1rem;
  cursor: pointer;
  transition: background 110ms cubic-bezier(0, 0, 0.38, 0.9); }
  .bx--overflow-menu.bx--toolbar-action *,
  .bx--overflow-menu.bx--toolbar-action *::before,
  .bx--overflow-menu.bx--toolbar-action *::after {
    box-sizing: inherit; }
  .bx--overflow-menu.bx--toolbar-action::-moz-focus-inner {
    border: 0; }

.bx--toolbar-action {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  appearance: none;
  background: none;
  cursor: pointer;
  width: 100%;
  display: flex;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  transition: background 110ms cubic-bezier(0, 0, 0.38, 0.9); }
  .bx--toolbar-action *,
  .bx--toolbar-action *::before,
  .bx--toolbar-action *::after {
    box-sizing: inherit; }
  .bx--toolbar-action::-moz-focus-inner {
    border: 0; }

.bx--toolbar-action:hover:not([disabled]) {
  background-color: #e5e5e5; }

.bx--toolbar-action:hover[aria-expanded='true'] {
  background-color: #f4f4f4; }

.bx--toolbar-action[disabled] {
  cursor: not-allowed; }

.bx--toolbar-action[disabled] .bx--toolbar-action__icon {
  cursor: not-allowed;
  fill: #c6c6c6; }

.bx--toolbar-action:focus:not([disabled]),
.bx--toolbar-action:active:not([disabled]) {
  outline: 2px solid #0f62fe;
  outline-offset: -2px; }
  @media screen and (prefers-contrast) {
    .bx--toolbar-action:focus:not([disabled]),
    .bx--toolbar-action:active:not([disabled]) {
      outline-style: dotted; } }
  .bx--toolbar-action:focus:not([disabled]).bx--toolbar-search-container-expandable,
  .bx--toolbar-action:active:not([disabled]).bx--toolbar-search-container-expandable {
    outline: none; }

.bx--toolbar-action ~ .bx--btn {
  max-width: none;
  margin: 0;
  white-space: nowrap; }

.bx--overflow-menu--data-table {
  height: 3rem; }

.bx--toolbar-action__icon {
  width: auto;
  max-width: 1rem;
  height: 1rem;
  fill: #161616; }

.bx--toolbar-search-container-persistent {
  position: relative;
  width: 100%;
  height: 3rem;
  opacity: 1; }

.bx--toolbar-search-container-persistent
+ .bx--toolbar-content {
  position: relative;
  width: auto; }

.bx--toolbar-search-container-persistent .bx--search {
  position: initial; }

.bx--toolbar-search-container-persistent
.bx--search-magnifier-icon {
  left: 1rem; }

.bx--toolbar-search-container-persistent .bx--search-input {
  height: 3rem;
  padding: 0 3rem;
  border: none; }

.bx--toolbar-search-container-persistent
.bx--search-input:focus:not([disabled]) {
  outline: 2px solid #0f62fe;
  outline-offset: -2px; }
  @media screen and (prefers-contrast) {
    .bx--toolbar-search-container-persistent
.bx--search-input:focus:not([disabled]) {
      outline-style: dotted; } }

.bx--toolbar-search-container-persistent
.bx--search-input:hover:not([disabled]) {
  background-color: #e5e5e5; }

.bx--toolbar-search-container-persistent
.bx--search-input:active:not([disabled]),
.bx--toolbar-search-container-persistent
.bx--search-input:not(:placeholder-shown) {
  background-color: #e5e5e5; }

.bx--toolbar-search-container-persistent .bx--search-close {
  width: 3rem;
  height: 3rem; }

.bx--batch-actions--active ~ .bx--toolbar-search-container,
.bx--batch-actions--active ~ .bx--toolbar-content {
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  transform: translate3d(0, 48px, 0);
  transition: transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), clip-path 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--batch-actions {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  background-color: #0f62fe;
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  overflow-x: auto;
  pointer-events: none;
  transform: translate3d(0, 48px, 0);
  transition: transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), clip-path 110ms cubic-bezier(0.2, 0, 0.38, 0.9), opacity 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  will-change: transform; }

.bx--batch-actions:focus {
  outline: 1px solid #0f62fe; }
  @media screen and (prefers-contrast) {
    .bx--batch-actions:focus {
      outline-style: dotted; } }

.bx--batch-actions--active {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  pointer-events: all;
  transform: translate3d(0, 0, 0); }

.bx--action-list {
  position: absolute;
  right: 0;
  display: flex; }

.bx--action-list .bx--btn {
  min-width: 0;
  padding: calc(0.875rem - 3px) 16px;
  color: #ffffff; }

.bx--action-list .bx--btn:disabled {
  color: #8d8d8d; }

.bx--action-list .bx--btn .bx--btn__icon {
  position: static;
  margin-left: 0.5rem;
  fill: #ffffff; }

.bx--action-list .bx--btn .bx--btn__icon .st0 {
  fill: none; }

.bx--batch-download {
  padding: 0.0625rem; }

.bx--action-list .bx--btn--primary:focus::before,
.bx--action-list .bx--btn--primary::before,
.bx--action-list .bx--btn--primary:focus::after,
.bx--action-list .bx--btn--primary::after {
  display: none; }

.bx--action-list .bx--btn--primary:focus {
  outline: 2px solid #f4f4f4;
  outline-offset: -0.125rem; }

.bx--action-list
.bx--btn--primary:nth-child(3):hover
+ .bx--btn--primary.bx--batch-summary__cancel::before,
.bx--action-list
.bx--btn--primary:nth-child(3):focus
+ .bx--btn--primary.bx--batch-summary__cancel::before {
  opacity: 0; }

.bx--btn--primary.bx--batch-summary__cancel::before {
  position: absolute;
  top: 0.9375rem;
  left: 0;
  display: block;
  width: 0.0625rem;
  height: 1rem;
  border: none;
  background-color: #ffffff;
  content: '';
  opacity: 1;
  transition: opacity 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--btn--primary.bx--batch-summary__cancel:hover::before {
  opacity: 0;
  transition: opacity 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--batch-summary__cancel {
  position: relative;
  padding-right: 1rem; }

.bx--batch-summary {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  margin-left: 1rem;
  color: #ffffff; }

.bx--batch-summary__para {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.29;
  letter-spacing: 0.16px; }

.bx--table-toolbar--small,
.bx--table-toolbar--sm {
  height: 2rem; }
  .bx--table-toolbar--small .bx--toolbar-search-container-expandable,
  .bx--table-toolbar--small .bx--toolbar-search-container-persistent,
  .bx--table-toolbar--sm .bx--toolbar-search-container-expandable,
  .bx--table-toolbar--sm .bx--toolbar-search-container-persistent {
    height: 2rem; }
  .bx--table-toolbar--small .bx--toolbar-search-container-expandable .bx--search-input,
  .bx--table-toolbar--small .bx--toolbar-search-container-persistent .bx--search-input,
  .bx--table-toolbar--sm .bx--toolbar-search-container-expandable .bx--search-input,
  .bx--table-toolbar--sm .bx--toolbar-search-container-persistent .bx--search-input {
    height: 2rem; }
  .bx--table-toolbar--small .bx--toolbar-search-container-expandable .bx--search-close,
  .bx--table-toolbar--small .bx--toolbar-search-container-persistent .bx--search-close,
  .bx--table-toolbar--sm .bx--toolbar-search-container-expandable .bx--search-close,
  .bx--table-toolbar--sm .bx--toolbar-search-container-persistent .bx--search-close {
    width: 2rem;
    height: 2rem; }
  .bx--table-toolbar--small .bx--toolbar-search-container-expandable
.bx--search-magnifier-icon,
  .bx--table-toolbar--small .bx--toolbar-search-container-persistent
.bx--search-magnifier-icon,
  .bx--table-toolbar--sm .bx--toolbar-search-container-expandable
.bx--search-magnifier-icon,
  .bx--table-toolbar--sm .bx--toolbar-search-container-persistent
.bx--search-magnifier-icon {
    width: 2rem;
    height: 2rem;
    padding: 0.5rem; }
  .bx--table-toolbar--small .bx--toolbar-action.bx--toolbar-search-container-persistent,
  .bx--table-toolbar--sm .bx--toolbar-action.bx--toolbar-search-container-persistent {
    width: 100%; }
  .bx--table-toolbar--small .bx--toolbar-search-container-active
.bx--search-magnifier-icon,
  .bx--table-toolbar--small .bx--toolbar-search-container-persistent
.bx--search-magnifier-icon,
  .bx--table-toolbar--sm .bx--toolbar-search-container-active
.bx--search-magnifier-icon,
  .bx--table-toolbar--sm .bx--toolbar-search-container-persistent
.bx--search-magnifier-icon {
    left: 0.5rem; }
  .bx--table-toolbar--small .bx--toolbar-search-container-expandable,
  .bx--table-toolbar--sm .bx--toolbar-search-container-expandable {
    width: 2rem; }
  .bx--table-toolbar--small .bx--toolbar-search-container-expandable
.bx--search
.bx--search-input,
  .bx--table-toolbar--sm .bx--toolbar-search-container-expandable
.bx--search
.bx--search-input {
    padding: 0 3rem; }
  .bx--table-toolbar--small .bx--toolbar-search-container-active,
  .bx--table-toolbar--sm .bx--toolbar-search-container-active {
    flex: auto;
    transition: flex 175ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--table-toolbar--small .bx--toolbar-search-container-active .bx--search-input,
  .bx--table-toolbar--sm .bx--toolbar-search-container-active .bx--search-input {
    visibility: inherit; }
  .bx--table-toolbar--small .bx--toolbar-search-container-active
.bx--search-input:focus,
  .bx--table-toolbar--sm .bx--toolbar-search-container-active
.bx--search-input:focus {
    outline: 2px solid #0f62fe;
    outline-offset: -2px;
    background-color: #e5e5e5; }
    @media screen and (prefers-contrast) {
      .bx--table-toolbar--small .bx--toolbar-search-container-active
.bx--search-input:focus,
      .bx--table-toolbar--sm .bx--toolbar-search-container-active
.bx--search-input:focus {
        outline-style: dotted; } }
  .bx--table-toolbar--small .bx--toolbar-search-container-active
.bx--search-input:active,
  .bx--table-toolbar--small .bx--toolbar-search-container-active
.bx--search-input:not(:placeholder-shown),
  .bx--table-toolbar--sm .bx--toolbar-search-container-active
.bx--search-input:active,
  .bx--table-toolbar--sm .bx--toolbar-search-container-active
.bx--search-input:not(:placeholder-shown) {
    background-color: #e5e5e5; }
  .bx--table-toolbar--small .bx--toolbar-search-container-active
.bx--search-magnifier-icon:focus,
  .bx--table-toolbar--small .bx--toolbar-search-container-active
.bx--search-magnifier-icon:active,
  .bx--table-toolbar--small .bx--toolbar-search-container-active
.bx--search-magnifier-icon:hover,
  .bx--table-toolbar--sm .bx--toolbar-search-container-active
.bx--search-magnifier-icon:focus,
  .bx--table-toolbar--sm .bx--toolbar-search-container-active
.bx--search-magnifier-icon:active,
  .bx--table-toolbar--sm .bx--toolbar-search-container-active
.bx--search-magnifier-icon:hover {
    outline: 2px solid transparent;
    outline-offset: -2px;
    background-color: transparent; }

.bx--search--disabled .bx--search-magnifier-icon:hover {
  background-color: transparent; }

.bx--table-toolbar--small
.bx--batch-actions
.bx--action-list,
.bx--table-toolbar--sm
.bx--batch-actions
.bx--action-list {
  height: 2rem; }

.bx--table-toolbar--small .bx--toolbar-action,
.bx--table-toolbar--sm .bx--toolbar-action {
  width: 2rem;
  height: 2rem;
  padding: 0.5rem 0; }

.bx--table-toolbar--small .bx--btn--primary,
.bx--table-toolbar--sm .bx--btn--primary {
  height: 2rem;
  min-height: auto;
  padding-top: calc(0.375rem - 3px);
  padding-bottom: calc(0.375rem - 3px); }

.bx--table-toolbar--small
.bx--btn--primary.bx--batch-summary__cancel::before,
.bx--table-toolbar--sm
.bx--btn--primary.bx--batch-summary__cancel::before {
  top: 0.5rem; }

.bx--table-toolbar--small
.bx--toolbar-action
~ .bx--btn,
.bx--table-toolbar--sm
.bx--toolbar-action
~ .bx--btn {
  overflow: hidden;
  height: 2rem; }

.bx--data-table-container {
  padding-top: 0.125rem; }

.bx--data-table-content {
  overflow-x: auto; }

.bx--data-table-header {
  padding: 1rem 0 1.5rem 1rem;
  background: #f4f4f4; }

.bx--data-table-header__title {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0;
  color: #161616; }

.bx--data-table-header__description {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.29;
  letter-spacing: 0.16px;
  color: #525252; }

.bx--data-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0; }

.bx--data-table thead {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.29;
  letter-spacing: 0.16px;
  background-color: #e0e0e0; }

.bx--data-table tbody {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.29;
  letter-spacing: 0.16px;
  width: 100%;
  background-color: #f4f4f4; }

.bx--data-table tr {
  width: 100%;
  height: 3rem;
  border: none; }

.bx--data-table tbody tr,
.bx--data-table tbody tr td,
.bx--data-table tbody tr th {
  transition: background-color 70ms cubic-bezier(0, 0, 0.38, 0.9); }

.bx--data-table tbody tr:hover {
  background: #e5e5e5; }

.bx--data-table tbody tr:hover td,
.bx--data-table tbody tr:hover th {
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  background: #e5e5e5;
  color: #161616; }

.bx--data-table tbody tr:hover td .bx--link,
.bx--data-table tbody tr:hover th .bx--link {
  color: #0043ce; }

.bx--data-table th,
.bx--data-table td {
  text-align: left;
  vertical-align: middle; }

.bx--data-table th[align='right'],
.bx--data-table td[align='right'] {
  text-align: right; }

.bx--data-table th[align='center'],
.bx--data-table td[align='center'] {
  text-align: center; }

.bx--data-table th {
  padding-right: 1rem;
  padding-left: 1rem;
  background-color: #e0e0e0;
  color: #161616; }

.bx--data-table th:last-of-type {
  position: static;
  width: auto; }

.bx--data-table td,
.bx--data-table tbody th {
  padding-right: 1rem;
  padding-left: 1rem;
  border-top: 1px solid #f4f4f4;
  border-bottom: 1px solid #e0e0e0;
  background: #f4f4f4;
  color: #525252; }
  .bx--data-table td + td:first-of-type,
  .bx--data-table tbody th + td:first-of-type {
    padding-left: 0.75rem; }

@supports (-moz-appearance: none) {
  .bx--data-table td {
    background-clip: padding-box; } }

.bx--data-table .bx--list-box input[role='combobox'],
.bx--data-table .bx--list-box input[type='text'],
.bx--data-table .bx--dropdown,
.bx--data-table .bx--list-box,
.bx--data-table .bx--number input[type='number'],
.bx--data-table .bx--number__control-btn::before,
.bx--data-table .bx--number__control-btn::after,
.bx--data-table .bx--text-input,
.bx--data-table .bx--select-input {
  background-color: #ffffff; }

.bx--data-table
td.bx--table-column-menu
.bx--overflow-menu[aria-expanded='false']:focus {
  outline: 2px solid #0f62fe;
  outline-offset: -2px; }
  @media screen and (prefers-contrast) {
    .bx--data-table
td.bx--table-column-menu
.bx--overflow-menu[aria-expanded='false']:focus {
      outline-style: dotted; } }

.bx--data-table
td.bx--table-column-menu
.bx--overflow-menu[aria-expanded='true']:focus {
  outline: none; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .bx--data-table
td.bx--table-column-menu
.bx--overflow-menu
.bx--overflow-menu__icon {
    opacity: 0; } }

.bx--data-table
td.bx--table-column-menu
.bx--overflow-menu.bx--overflow-menu--open
.bx--overflow-menu__icon {
  opacity: 1; }

.bx--data-table.bx--data-table--visible-overflow-menu
td.bx--table-column-menu
.bx--overflow-menu
.bx--overflow-menu__icon,
.bx--data-table
td.bx--table-column-menu
.bx--overflow-menu:hover
.bx--overflow-menu__icon,
.bx--data-table
td.bx--table-column-menu
.bx--overflow-menu:focus
.bx--overflow-menu__icon,
.bx--data-table
tr:hover
td.bx--table-column-menu
.bx--overflow-menu
.bx--overflow-menu__icon {
  opacity: 1; }

.bx--table-row--menu-option
.bx--overflow-menu-options__btn
.bx--overflow-menu-options__option-content
svg {
  position: relative;
  top: 0.1875rem;
  margin-right: 0.5rem; }

.bx--data-table .bx--overflow-menu:hover,
.bx--data-table .bx--overflow-menu__trigger:hover {
  background-color: #cacaca; }

.bx--data-table--selected .bx--overflow-menu:hover,
.bx--data-table--selected .bx--overflow-menu__trigger:hover {
  background-color: #e5e5e5; }

.bx--data-table--selected .bx--link {
  color: #0043ce; }

.bx--data-table--compact td.bx--table-column-menu,
.bx--data-table--xs td.bx--table-column-menu,
.bx--data-table--short td.bx--table-column-menu,
.bx--data-table--sm td.bx--table-column-menu {
  height: 1.5rem;
  padding-top: 0;
  padding-bottom: 0; }

.bx--data-table--short td.bx--table-column-menu,
.bx--data-table--sm td.bx--table-column-menu {
  height: 2rem; }

.bx--data-table--md td.bx--table-column-menu {
  height: 2.5rem; }

.bx--data-table--tall .bx--table-column-menu,
.bx--data-table--xl .bx--table-column-menu {
  padding-top: 0.5rem; }

.bx--data-table--zebra
tbody
tr:not(.bx--parent-row):nth-child(odd)
td {
  border-bottom: 1px solid #f4f4f4; }

.bx--data-table--zebra
tbody
tr:not(.bx--parent-row):nth-child(even)
td {
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  background-color: #e0e0e0; }

.bx--data-table--zebra
tbody
tr:not(.bx--parent-row):hover
td {
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  background-color: #e5e5e5; }

.bx--table-column-checkbox .bx--checkbox-label {
  padding-left: 0; }

.bx--data-table th.bx--table-column-checkbox {
  position: static;
  width: 2rem;
  background: #e0e0e0;
  transition: background-color 70ms cubic-bezier(0, 0, 0.38, 0.9); }

.bx--data-table
.bx--table-column-checkbox
.bx--checkbox:focus
+ .bx--checkbox-label::before {
  outline-offset: 0.375rem; }

.bx--data-table--compact
.bx--table-column-checkbox
.bx--checkbox:focus
+ .bx--checkbox-label::before,
.bx--data-table--xs
.bx--table-column-checkbox
.bx--checkbox:focus
+ .bx--checkbox-label::before {
  outline-offset: 0.125rem; }

.bx--data-table thead th.bx--table-column-checkbox,
.bx--data-table tbody td.bx--table-column-checkbox,
.bx--data-table thead th.bx--table-expand,
.bx--data-table tbody td.bx--table-expand {
  min-width: 0; }

.bx--data-table thead th.bx--table-column-checkbox,
.bx--data-table tbody td.bx--table-column-checkbox {
  padding-right: 0.25rem;
  padding-left: 1rem; }

.bx--data-table thead th.bx--table-expand,
.bx--data-table tbody td.bx--table-expand {
  width: 2rem;
  height: 2rem; }

.bx--data-table--compact thead th.bx--table-expand,
.bx--data-table--compact tbody td.bx--table-expand,
.bx--data-table--xs thead th.bx--table-expand,
.bx--data-table--xs tbody td.bx--table-expand {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0 0 0 0.5rem; }

.bx--data-table--short thead th.bx--table-expand,
.bx--data-table--short tbody td.bx--table-expand,
.bx--data-table--sm thead th.bx--table-expand,
.bx--data-table--sm tbody td.bx--table-expand {
  width: 2rem;
  height: 2rem;
  padding: 0;
  padding-left: 0.5rem; }

.bx--data-table--md thead th.bx--table-expand,
.bx--data-table--md tbody td.bx--table-expand {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.25rem 0 0.25rem 0.5rem; }

.bx--data-table--tall thead th.bx--table-expand,
.bx--data-table--tall tbody td.bx--table-expand,
.bx--data-table--xl thead th.bx--table-expand,
.bx--data-table--xl tbody td.bx--table-expand {
  height: 4rem;
  padding-top: 0.625rem;
  padding-bottom: 1.375rem; }

.bx--data-table--tall .bx--table-column-checkbox,
.bx--data-table--xl .bx--table-column-checkbox {
  padding-top: 0.8125rem; }

.bx--data-table--tall .bx--table-column-radio,
.bx--data-table--xl .bx--table-column-radio {
  padding-top: 1rem; }

.bx--date-table tbody th.bx--table-column-checkbox:hover {
  background: #cacaca; }

.bx--table-column-radio {
  width: 48px; }

.bx--table-column-radio .bx--radio-button__appearance {
  margin-right: -0.125rem; }

.bx--data-table--zebra
tbody
tr:nth-child(odd).bx--data-table--selected
td,
tr.bx--data-table--selected td {
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #c6c6c6;
  background-color: #e0e0e0;
  color: #161616; }

.bx--data-table--zebra
tbody
tr:first-of-type:nth-child(odd).bx--data-table--selected
td,
tr.bx--data-table--selected:first-of-type td {
  border-top: 1px solid #c6c6c6; }

.bx--data-table--zebra
tbody
tr:last-of-type:nth-child(odd).bx--data-table--selected
td,
.bx--data-table--zebra
tbody
tr:last-of-type:nth-child(even).bx--data-table--selected
td,
tr.bx--data-table--selected:last-of-type td {
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0; }

.bx--data-table--zebra
tbody
tr:nth-child(even).bx--data-table--selected
td {
  border-bottom: 1px solid #c6c6c6; }

.bx--data-table--zebra
tbody
tr:nth-child(even).bx--data-table--selected:hover
td {
  border-bottom: 1px solid #cacaca; }

.bx--data-table--zebra
tbody
tr:nth-child(odd).bx--data-table--selected:hover
td,
.bx--data-table tbody .bx--data-table--selected:hover td {
  border-top: 1px solid #cacaca;
  border-bottom: 1px solid #cacaca;
  background: #cacaca;
  color: #161616; }

.bx--data-table--selected
.bx--overflow-menu
.bx--overflow-menu__icon {
  opacity: 1; }

.bx--data-table--compact thead tr,
.bx--data-table--compact tbody tr,
.bx--data-table--compact tbody tr th {
  height: 1.5rem; }

.bx--data-table--compact .bx--table-header-label {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem; }

.bx--data-table--compact td,
.bx--data-table--compact tbody tr th {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem; }

.bx--data-table--compact .bx--overflow-menu {
  width: 2rem;
  height: 100%; }

.bx--data-table.bx--data-table--compact
.bx--table-column-checkbox {
  padding-top: 0;
  padding-bottom: 0; }

.bx--data-table.bx--data-table--compact
.bx--table-column-checkbox
.bx--checkbox-label {
  height: 1.4375rem;
  min-height: 1.4375rem; }

.bx--data-table--xs thead tr,
.bx--data-table--xs tbody tr,
.bx--data-table--xs tbody tr th {
  height: 1.5rem; }

.bx--data-table--xs .bx--table-header-label {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem; }

.bx--data-table--xs td,
.bx--data-table--xs tbody tr th {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem; }

.bx--data-table--xs .bx--overflow-menu {
  width: 2rem;
  height: calc(100% + 1px); }

.bx--data-table.bx--data-table--xs
.bx--table-column-checkbox {
  padding-top: 0;
  padding-bottom: 0; }

.bx--data-table.bx--data-table--xs
.bx--table-column-checkbox
.bx--checkbox-label {
  height: 1.4375rem;
  min-height: 1.4375rem; }

.bx--data-table--short thead tr,
.bx--data-table--short tbody tr,
.bx--data-table--short tbody tr th {
  height: 2rem; }

.bx--data-table--short .bx--table-header-label {
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem; }

.bx--data-table--short td,
.bx--data-table--short tbody tr th {
  padding-top: 0.4375rem;
  padding-bottom: 0.375rem; }

.bx--data-table.bx--data-table--short
.bx--table-column-checkbox {
  padding-top: 0.1875rem;
  padding-bottom: 0.1875rem; }

.bx--data-table--short .bx--overflow-menu {
  height: 100%; }

.bx--data-table--sm thead tr,
.bx--data-table--sm tbody tr,
.bx--data-table--sm tbody tr th {
  height: 2rem; }

.bx--data-table--sm .bx--table-header-label {
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem; }

.bx--data-table--sm td,
.bx--data-table--sm tbody tr th {
  padding-top: 0.4375rem;
  padding-bottom: 0.375rem; }

.bx--data-table.bx--data-table--sm
.bx--table-column-checkbox {
  padding-top: 0.1875rem;
  padding-bottom: 0.1875rem; }

.bx--data-table--sm .bx--overflow-menu {
  height: calc(100% + 1px); }

.bx--data-table--md thead tr,
.bx--data-table--md tbody tr,
.bx--data-table--md tbody tr th {
  height: 2.5rem; }

.bx--data-table--md .bx--table-header-label {
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem; }

.bx--data-table--md td,
.bx--data-table--md tbody tr th {
  padding-top: 0.4375rem;
  padding-bottom: 0.375rem; }

.bx--data-table.bx--data-table--md
.bx--table-column-checkbox {
  padding-top: 0.1875rem;
  padding-bottom: 0.1875rem; }

.bx--data-table--md .bx--table-column-menu {
  padding-top: 0.1875rem;
  padding-bottom: 0.1875rem; }

.bx--data-table--tall thead tr,
.bx--data-table--tall tbody tr,
.bx--data-table--tall tbody tr th {
  height: 4rem; }

.bx--data-table--tall .bx--table-header-label {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.bx--data-table--tall td,
.bx--data-table--tall tbody tr th {
  padding-top: 1rem; }

.bx--data-table--tall th,
.bx--data-table--tall td {
  vertical-align: top; }

.bx--data-table--tall .bx--data-table--cell-secondary-text {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.34;
  letter-spacing: 0.32px; }

.bx--data-table--xl thead tr,
.bx--data-table--xl tbody tr,
.bx--data-table--xl tbody tr th {
  height: 4rem; }

.bx--data-table--xl .bx--table-header-label {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.bx--data-table--xl td,
.bx--data-table--xl tbody tr th {
  padding-top: 1rem; }

.bx--data-table--xl th,
.bx--data-table--xl td {
  vertical-align: top; }

.bx--data-table--xl .bx--data-table--cell-secondary-text {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.34;
  letter-spacing: 0.32px; }

.bx--data-table--static {
  width: auto; }

.bx--data-table_inner-container {
  background-color: #e0e0e0;
  transform: translateZ(0); }

.bx--data-table--sticky-header {
  display: block;
  overflow-y: scroll;
  /* When using sticky header, with a selection element in the first column, we need to set the last item to a fixed width to match the table body. We only want this to happen when the last table header does not have any text */ }
  .bx--data-table--sticky-header thead,
  .bx--data-table--sticky-header tbody,
  .bx--data-table--sticky-header tr,
  .bx--data-table--sticky-header th,
  .bx--data-table--sticky-header td {
    display: flex; }
  .bx--data-table--sticky-header thead {
    position: sticky;
    z-index: 1;
    top: 0;
    overflow: scroll;
    width: 100%;
    -ms-overflow-style: none;
    will-change: transform; }
  .bx--data-table--sticky-header thead tr th {
    border-bottom: 1px solid #c6c6c6; }
  .bx--data-table--sticky-header tbody {
    flex-direction: column;
    -ms-overflow-style: none;
    overflow-x: scroll;
    will-change: transform; }
  .bx--data-table--sticky-header tr.bx--parent-row.bx--expandable-row {
    height: auto;
    min-height: 3rem; }
  .bx--data-table--sticky-header tr.bx--expandable-row:not(.bx--parent-row) {
    height: auto; }
  .bx--data-table--sticky-header .bx--table-expand {
    max-width: 3rem; }
  .bx--data-table--sticky-header thead .bx--table-expand {
    align-items: center; }
  .bx--data-table--sticky-header .bx--parent-row {
    min-height: 3rem; }
  .bx--data-table--sticky-header:not(.bx--data-table--compact):not(.bx--data-table--xs):not(.bx--data-table--tall):not(.bx--data-table--xl):not(.bx--data-table--short):not(.bx--data-table--sm) td:not(.bx--table-column-menu):not(.bx--table-column-checkbox) {
    padding-top: 0.875rem; }
  .bx--data-table--sticky-header tr.bx--parent-row.bx--expandable-row:hover
+ tr[data-child-row]
td {
    border-top: 1px solid #e5e5e5; }
  .bx--data-table--sticky-header tr.bx--expandable-row:last-of-type {
    overflow: hidden; }
  .bx--data-table--sticky-header tr.bx--data-table--selected:first-of-type td {
    border-top: none; }
  .bx--data-table--sticky-header thead th.bx--table-column-checkbox,
  .bx--data-table--sticky-header tbody tr td.bx--table-column-checkbox {
    width: 2.25rem;
    min-width: 2.25rem;
    align-items: center; }
  .bx--data-table--sticky-header.bx--data-table--tall thead th.bx--table-column-checkbox,
  .bx--data-table--sticky-header.bx--data-table--xl thead th.bx--table-column-checkbox,
  .bx--data-table--sticky-header.bx--data-table--tall td.bx--table-column-checkbox,
  .bx--data-table--sticky-header.bx--data-table--xl td.bx--table-column-checkbox {
    align-items: flex-start; }
  .bx--data-table--sticky-header th.bx--table-column-checkbox ~ th:last-of-type:empty {
    max-width: 4rem; }
  .bx--data-table--sticky-header th:empty:not(.bx--table-expand) {
    max-width: 2.25rem; }
  .bx--data-table--sticky-header td.bx--table-column-menu {
    height: auto;
    align-items: center;
    padding-top: 0; }
  .bx--data-table--sticky-header thead::-webkit-scrollbar,
  .bx--data-table--sticky-header tbody::-webkit-scrollbar {
    display: none; }

@-moz-document url-prefix() {
  .bx--data-table--sticky-header thead,
  .bx--data-table--sticky-header tbody {
    scrollbar-width: none; } }
  .bx--data-table--sticky-header tbody tr:last-of-type {
    border-bottom: 0; }
  .bx--data-table--sticky-header th:not(.bx--table-column-checkbox):not(.bx--table-column-menu):not(.bx--table-expand-v2):not(.bx--table-column-icon),
  .bx--data-table--sticky-header td:not(.bx--table-column-checkbox):not(.bx--table-column-menu):not(.bx--table-expand-v2):not(.bx--table-column-icon) {
    width: 100%;
    min-width: 0; }
  .bx--data-table--sticky-header.bx--data-table--compact tr:not(.bx--expandable-row),
  .bx--data-table--sticky-header.bx--data-table--xs tr:not(.bx--expandable-row),
  .bx--data-table--sticky-header.bx--data-table--short tr:not(.bx--expandable-row),
  .bx--data-table--sticky-header.bx--data-table--sm tr:not(.bx--expandable-row),
  .bx--data-table--sticky-header.bx--data-table--tall tr:not(.bx--expandable-row),
  .bx--data-table--sticky-header.bx--data-table--xl tr:not(.bx--expandable-row) {
    height: auto; }
  .bx--data-table--sticky-header.bx--data-table--compact tr:not(.bx--expandable-row),
  .bx--data-table--sticky-header.bx--data-table--xs tr:not(.bx--expandable-row) {
    min-height: 1.5rem; }
  .bx--data-table--sticky-header.bx--data-table--short tr:not(.bx--expandable-row),
  .bx--data-table--sticky-header.bx--data-table--sm tr:not(.bx--expandable-row) {
    min-height: 2rem; }
  .bx--data-table--sticky-header.bx--data-table--tall tr:not(.bx--expandable-row),
  .bx--data-table--sticky-header.bx--data-table--xl tr:not(.bx--expandable-row) {
    min-height: 4rem; }
  .bx--data-table--sticky-header.bx--data-table--compact tr td.bx--table-expand,
  .bx--data-table--sticky-header.bx--data-table--xs tr td.bx--table-expand {
    padding-top: 0.25rem; }
  .bx--data-table--sticky-header.bx--data-table--short tr td.bx--table-expand,
  .bx--data-table--sticky-header.bx--data-table--sm tr td.bx--table-expand {
    padding-top: 0.5rem; }
  .bx--data-table--sticky-header .bx--table-header-label {
    display: block;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: calc(100% - 10px);
    padding-top: 0.9375rem;
    padding-bottom: 1rem;
    overflow-y: hidden; }
  .bx--data-table--sticky-header.bx--data-table--compact th .bx--table-header-label,
  .bx--data-table--sticky-header.bx--data-table--xs th .bx--table-header-label {
    padding-top: 0.1875rem;
    padding-bottom: 0; }
  .bx--data-table--sticky-header.bx--data-table--short th .bx--table-header-label,
  .bx--data-table--sticky-header.bx--data-table--sm th .bx--table-header-label {
    padding-top: 0.5rem;
    padding-bottom: 0; }
  .bx--data-table--sticky-header.bx--data-table--tall th .bx--table-header-label,
  .bx--data-table--sticky-header.bx--data-table--xl th .bx--table-header-label {
    padding-top: 1rem; }
  .bx--data-table--sticky-header.bx--data-table--tall th.bx--table-expand,
  .bx--data-table--sticky-header.bx--data-table--xl th.bx--table-expand {
    display: flex;
    align-items: flex-start; }
  .bx--data-table--sticky-header.bx--data-table--compact tr.bx--parent-row
.bx--table-column-checkbox,
  .bx--data-table--sticky-header.bx--data-table--short tr.bx--parent-row
.bx--table-column-checkbox,
  .bx--data-table--sticky-header.bx--data-table--xs tr.bx--parent-row
.bx--table-column-checkbox,
  .bx--data-table--sticky-header.bx--data-table--sm tr.bx--parent-row
.bx--table-column-checkbox {
    align-items: flex-start; }

.bx--data-table--max-width {
  max-width: 100%; }

.bx--data-table--sticky-header {
  max-height: 18.75rem; }

.bx--data-table .bx--form-item.bx--checkbox-wrapper:last-of-type {
  margin: 0; }

.bx--data-table--short
.bx--form-item.bx--checkbox-wrapper:last-of-type,
.bx--data-table--compact
.bx--form-item.bx--checkbox-wrapper:last-of-type,
.bx--data-table--xs
.bx--form-item.bx--checkbox-wrapper:last-of-type,
.bx--data-table--sm
.bx--form-item.bx--checkbox-wrapper:last-of-type {
  margin: -0.1875rem 0; }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--data-table-content {
    outline: 1px solid transparent; } }

.bx--data-table tr.bx--parent-row:first-of-type td {
  border-top: 1px solid #e0e0e0; }

.bx--expandable-row--hidden td {
  width: auto;
  padding: 1rem;
  border-top: 0; }

tr.bx--parent-row:not(.bx--expandable-row)
+ tr[data-child-row] {
  height: 0;
  transition: height 150ms cubic-bezier(0.2, 0, 0.38, 0.9); }

tr.bx--parent-row:not(.bx--expandable-row)
+ tr[data-child-row]
td {
  padding-top: 0;
  padding-bottom: 0;
  border: 0;
  background-color: #e5e5e5;
  transition: padding 150ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 150ms cubic-bezier(0.2, 0, 0.38, 0.9); }

tr.bx--parent-row:not(.bx--expandable-row)
+ tr[data-child-row]
td
.bx--child-row-inner-container {
  overflow: hidden;
  max-height: 0; }

tr.bx--parent-row.bx--expandable-row + tr[data-child-row] {
  transition: height 150ms cubic-bezier(0.2, 0, 0.38, 0.9); }

tr.bx--parent-row.bx--expandable-row + tr[data-child-row] td {
  padding-left: 4rem;
  border-bottom: 1px solid #e0e0e0;
  transition: padding-bottom 110ms cubic-bezier(0.2, 0, 0.38, 0.9), transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }

tr.bx--parent-row.bx--expandable-row
+ tr[data-child-row]
td
.bx--child-row-inner-container {
  max-height: 100%; }

.bx--parent-row.bx--expandable-row > td,
.bx--parent-row.bx--expandable-row + tr[data-child-row] > td {
  border-bottom: 1px solid #e0e0e0;
  box-shadow: 0 1px #e0e0e0; }

.bx--parent-row:not(.bx--expandable-row)
+ tr[data-child-row]
> td {
  box-shadow: none; }

.bx--parent-row.bx--expandable-row > td:first-of-type {
  box-shadow: none; }

tr.bx--parent-row:not(.bx--expandable-row) td,
tr.bx--parent-row.bx--expandable-row td,
tr.bx--parent-row.bx--expandable-row {
  transition: height 240ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }

tr.bx--parent-row:not(.bx--expandable-row):first-of-type:hover
td {
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0; }

tr.bx--parent-row.bx--expandable-row:hover td {
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  background-color: #e5e5e5;
  color: #161616; }

tr.bx--parent-row.bx--expandable-row:hover td:first-of-type {
  border-bottom: 1px solid #e5e5e5; }

tr.bx--parent-row.bx--expandable-row:hover
+ tr[data-child-row]
td {
  border-bottom: 1px solid #e0e0e0;
  background-color: #e5e5e5;
  color: #161616; }

tr.bx--expandable-row--hover + tr[data-child-row] td {
  border-bottom: 1px solid #e0e0e0; }

tr.bx--expandable-row--hover {
  background-color: #e5e5e5; }

tr.bx--expandable-row--hover td {
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  background-color: #e5e5e5;
  color: #161616; }

tr.bx--parent-row.bx--expandable-row.bx--expandable-row--hover
td:first-of-type {
  border-bottom: 1px solid transparent; }

.bx--data-table td.bx--table-expand {
  border-bottom: 1px solid #e0e0e0; }

.bx--data-table
th.bx--table-expand
+ .bx--table-column-checkbox,
.bx--data-table
td.bx--table-expand
+ .bx--table-column-checkbox {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.bx--data-table
th.bx--table-expand
+ .bx--table-column-checkbox
+ th,
.bx--data-table
td.bx--table-expand
+ .bx--table-column-checkbox
+ td {
  padding-left: 0.5rem; }

.bx--data-table td.bx--table-expand,
.bx--data-table th.bx--table-expand {
  padding: 0.5rem;
  padding-right: 0; }

.bx--data-table
td.bx--table-expand[data-previous-value='collapsed'] {
  border-bottom: 1px solid transparent; }

.bx--table-expand[data-previous-value='collapsed']
.bx--table-expand__svg {
  transform: rotate(270deg);
  transition: transform 150ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--table-expand__button {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  appearance: none;
  background: none;
  cursor: pointer;
  display: inline-flex;
  width: 100%;
  height: calc(100% + 1px);
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
  vertical-align: inherit; }
  .bx--table-expand__button *,
  .bx--table-expand__button *::before,
  .bx--table-expand__button *::after {
    box-sizing: inherit; }
  .bx--table-expand__button::-moz-focus-inner {
    border: 0; }

.bx--table-expand__button:focus {
  box-shadow: inset 0 0 0 2px #0f62fe;
  outline: none; }

.bx--table-expand__svg {
  fill: #161616;
  transform: rotate(90deg);
  transition: transform 150ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--data-table--tall .bx--table-expand__button,
.bx--data-table--xl .bx--table-expand__button {
  width: 2rem;
  padding: 0; }

tr.bx--parent-row.bx--expandable-row
td.bx--table-expand
+ td::after {
  position: absolute;
  bottom: -0.0625rem;
  left: 0;
  width: 0.5rem;
  height: 0.0625rem;
  background: #e0e0e0;
  content: ''; }

tr.bx--parent-row.bx--expandable-row:hover
td.bx--table-expand
+ td::after,
tr.bx--parent-row.bx--expandable-row.bx--expandable-row--hover
td.bx--table-expand
+ td::after {
  background: #e5e5e5; }

tr.bx--parent-row.bx--data-table--selected
td.bx--table-expand
+ td::after {
  display: none; }

.bx--data-table--zebra tbody tr[data-parent-row]:nth-child(4n + 3) td,
.bx--data-table--zebra tbody tr[data-child-row]:nth-child(4n + 4) td {
  border-bottom: 1px solid #f4f4f4; }

.bx--data-table--zebra tbody tr[data-parent-row]:nth-child(4n + 1) td,
.bx--data-table--zebra tbody tr[data-child-row]:nth-child(4n + 2) td {
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  background-color: #e0e0e0; }

.bx--data-table--zebra tr.bx--parent-row td,
.bx--data-table--zebra
tr.bx--parent-row.bx--expandable-row
+ tr[data-child-row]
td {
  transition: transform 150ms cubic-bezier(0.2, 0, 0.38, 0.9), border-bottom 150ms cubic-bezier(0.2, 0, 0.38, 0.9), border-top 150ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--data-table--zebra tbody tr[data-parent-row]:hover td,
.bx--data-table--zebra
tbody
tr[data-parent-row]:hover
+ tr[data-child-row]
td,
.bx--data-table--zebra tbody tr[data-child-row]:hover td {
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  background-color: #e5e5e5; }

.bx--data-table--zebra
tr.bx--parent-row.bx--expandable-row.bx--expandable-row--hover
td {
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  background: #e5e5e5; }

tr.bx--parent-row.bx--data-table--selected:first-of-type td {
  border-top: 1px solid #c6c6c6;
  border-bottom: 1px solid #e0e0e0;
  background: #e0e0e0;
  box-shadow: 0 1px #c6c6c6; }

tr.bx--parent-row.bx--data-table--selected td {
  border-bottom: 1px solid transparent;
  background: #e0e0e0;
  box-shadow: 0 1px #c6c6c6;
  color: #161616; }

tr.bx--parent-row.bx--data-table--selected:last-of-type td {
  border-bottom: 1px solid transparent;
  background: #e0e0e0;
  box-shadow: 0 1px #e0e0e0; }

tr.bx--parent-row.bx--data-table--selected:not(.bx--expandable-row):hover
td {
  border-top: 1px solid #cacaca;
  border-bottom: 1px solid #e0e0e0;
  background: #cacaca;
  box-shadow: 0 1px #cacaca; }

tr.bx--parent-row.bx--data-table--selected.bx--expandable-row
td,
tr.bx--parent-row.bx--data-table--selected.bx--expandable-row
td:first-of-type {
  border-bottom: 1px solid transparent;
  box-shadow: 0 1px #e0e0e0; }

tr.bx--parent-row.bx--data-table--selected.bx--expandable-row:hover
td,
tr.bx--parent-row.bx--data-table--selected.bx--expandable-row:hover
td:first-of-type,
tr.bx--parent-row.bx--data-table--selected.bx--expandable-row--hover
td,
tr.bx--parent-row.bx--data-table--selected.bx--expandable-row--hover
td:first-of-type {
  border-top: 1px solid #cacaca;
  border-bottom: 1px solid transparent;
  background: #cacaca;
  box-shadow: 0 1px #cacaca; }

tr.bx--parent-row.bx--data-table--selected.bx--expandable-row
+ tr[data-child-row]
td {
  border-top: 1px solid #c6c6c6;
  border-bottom: 1px solid #e0e0e0;
  background-color: #e5e5e5;
  box-shadow: 0 1px #c6c6c6;
  color: #161616; }

tr.bx--parent-row.bx--data-table--selected.bx--expandable-row
+ tr[data-child-row]:last-of-type
td {
  padding-bottom: 1.5rem;
  box-shadow: inset 0 -1px #c6c6c6; }

tr.bx--parent-row.bx--data-table--selected.bx--expandable-row:hover
+ tr[data-child-row]
td,
tr.bx--parent-row.bx--data-table--selected.bx--expandable-row--hover
+ tr[data-child-row]
td {
  background: #e0e0e0; }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--table-expand__button:focus .bx--table-expand__svg {
    color: Highlight;
    outline: 1px solid Highlight; } }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--table-expand__svg {
    fill: ButtonText; } }

.bx--data-table--sort th,
.bx--data-table th[aria-sort] {
  height: 3rem;
  padding: 0;
  border-top: none;
  border-bottom: none; }

.bx--table-sort {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  appearance: none;
  background: none;
  cursor: pointer;
  display: flex;
  width: 100%;
  min-height: 100%;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  background-color: #e0e0e0;
  color: #161616;
  font: inherit;
  line-height: 1;
  transition: background-color 70ms cubic-bezier(0, 0, 0.38, 0.9), outline 70ms cubic-bezier(0, 0, 0.38, 0.9); }
  .bx--table-sort *,
  .bx--table-sort *::before,
  .bx--table-sort *::after {
    box-sizing: inherit; }
  .bx--table-sort::-moz-focus-inner {
    border: 0; }

.bx--table-sort:focus {
  outline: 2px solid #0f62fe;
  outline-offset: -2px; }
  @media screen and (prefers-contrast) {
    .bx--table-sort:focus {
      outline-style: dotted; } }

.bx--table-sort:hover {
  background: #cacaca; }

.bx--table-sort:focus svg,
.bx--table-sort:hover svg {
  opacity: 1; }

.bx--data-table.bx--data-table--sort
th
> .bx--table-header-label {
  padding-right: 1rem;
  padding-left: 1rem; }

th .bx--table-sort__flex {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 3rem;
  align-items: center;
  justify-content: space-between; }

.bx--data-table--sort:not(.bx--data-table--compact):not(.bx--data-table--short):not(.bx--data-table--tall):not(.bx--data-table--xs):not(.bx--data-table--sm):not(.bx--data-table--md):not(.bx--data-table--xl)
th
.bx--table-sort__flex {
  /* IE11 workaround for align-items: center and min-height
        https://github.com/philipwalton/flexbugs/issues/231 */ }
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .bx--data-table--sort:not(.bx--data-table--compact):not(.bx--data-table--short):not(.bx--data-table--tall):not(.bx--data-table--xs):not(.bx--data-table--sm):not(.bx--data-table--md):not(.bx--data-table--xl)
th
.bx--table-sort__flex {
      height: 2.99rem; } }

.bx--data-table--compact.bx--data-table--sort
th
.bx--table-sort__flex,
.bx--data-table--xs.bx--data-table--sort
th
.bx--table-sort__flex {
  min-height: 1.5rem; }

.bx--data-table--short.bx--data-table--sort
th
.bx--table-sort__flex,
.bx--data-table--sm.bx--data-table--sort
th
.bx--table-sort__flex {
  min-height: 2rem; }

.bx--data-table--md.bx--data-table--sort
th
.bx--table-sort__flex {
  min-height: 2.5rem; }

.bx--data-table--tall.bx--data-table--sort
th
.bx--table-sort__flex,
.bx--data-table--xl.bx--data-table--sort
th
.bx--table-sort__flex {
  min-height: 4rem;
  align-items: flex-start; }

.bx--table-sort .bx--table-sort__icon-inactive {
  display: block; }

.bx--table-sort .bx--table-sort__icon {
  display: none; }

.bx--table-sort__icon-unsorted {
  width: 1.25rem;
  min-width: 1rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  fill: #161616;
  opacity: 0; }

.bx--table-sort.bx--table-sort--active {
  background: #cacaca; }

.bx--table-sort.bx--table-sort--active
.bx--table-sort__icon-unsorted {
  display: none; }

.bx--table-sort.bx--table-sort--active
.bx--table-sort__icon {
  display: block;
  opacity: 1; }

.bx--table-sort--ascending .bx--table-sort__icon {
  transform: rotate(180deg); }

.bx--table-sort__icon {
  width: 1.25rem;
  min-width: 1rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  fill: #161616;
  opacity: 1;
  transform: rotate(0);
  transition: transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--data-table--compact.bx--data-table--sort th,
.bx--data-table--xs.bx--data-table--sort th {
  height: 1.5rem; }

.bx--data-table--short.bx--data-table--sort th,
.bx--data-table--sm.bx--data-table--sort th {
  height: 2rem; }

.bx--data-table--md.bx--data-table--sort th {
  height: 2.5rem; }

.bx--data-table--tall.bx--data-table--sort th,
.bx--data-table--xl.bx--data-table--sort th {
  height: 4rem; }

.bx--data-table--tall.bx--data-table--sort
th
.bx--table-sort,
.bx--data-table--xl.bx--data-table--sort
th
.bx--table-sort {
  display: inline-block;
  height: 4rem; }

.bx--data-table--tall .bx--table-sort__icon-unsorted,
.bx--data-table--tall .bx--table-sort__icon,
.bx--data-table--xl .bx--table-sort__icon-unsorted,
.bx--data-table--xl .bx--table-sort__icon {
  margin-top: 0.8125rem; }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--table-sort__icon,
  .bx--table-sort__icon-unsorted {
    fill: ButtonText; } }

.bx--inline-edit-label {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .bx--inline-edit-label:hover .bx--inline-edit-label__icon {
    opacity: 1; }

.bx--inline-edit-label--inactive {
  display: none; }

.bx--inline-edit-label__action {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  appearance: none;
  background: none;
  cursor: pointer; }
  .bx--inline-edit-label__action *,
  .bx--inline-edit-label__action *::before,
  .bx--inline-edit-label__action *::after {
    box-sizing: inherit; }
  .bx--inline-edit-label__action::-moz-focus-inner {
    border: 0; }
  .bx--inline-edit-label__action:hover {
    cursor: pointer; }
  .bx--inline-edit-label__action:focus {
    outline: 1px solid #0f62fe;
    padding: 0.125rem; }
    @media screen and (prefers-contrast) {
      .bx--inline-edit-label__action:focus {
        outline-style: dotted; } }
    .bx--inline-edit-label__action:focus .bx--inline-edit-label__icon {
      width: auto;
      opacity: 1; }

.bx--inline-edit-label__icon {
  fill: #161616;
  opacity: 0; }

.bx--inline-edit-input {
  display: none; }

.bx--inline-edit-input--active {
  display: block;
  margin-left: -0.75rem; }
  .bx--inline-edit-input--active input {
    padding-left: 0.75rem; }

.bx--data-table.bx--skeleton th {
  padding-left: 1rem;
  vertical-align: middle; }

.bx--data-table.bx--skeleton th span,
.bx--data-table.bx--skeleton td span {
  position: relative;
  padding: 0;
  border: none;
  background: #e5e5e5;
  box-shadow: none;
  pointer-events: none;
  display: block;
  width: 4rem;
  height: 1rem; }
  .bx--data-table.bx--skeleton th span:hover, .bx--data-table.bx--skeleton th span:focus, .bx--data-table.bx--skeleton th span:active,
  .bx--data-table.bx--skeleton td span:hover,
  .bx--data-table.bx--skeleton td span:focus,
  .bx--data-table.bx--skeleton td span:active {
    border: none;
    cursor: default;
    outline: none; }
  .bx--data-table.bx--skeleton th span::before,
  .bx--data-table.bx--skeleton td span::before {
    position: absolute;
    width: 100%;
    height: 100%;
    animation: 3000ms ease-in-out skeleton infinite;
    background: #c6c6c6;
    content: '';
    will-change: transform-origin, transform, opacity; }
    @media (prefers-reduced-motion: reduce) {
      .bx--data-table.bx--skeleton th span::before,
      .bx--data-table.bx--skeleton td span::before {
        animation: none; } }

.bx--data-table.bx--skeleton tr:hover td {
  border-color: #e0e0e0;
  background: transparent; }
  .bx--data-table.bx--skeleton tr:hover td:first-of-type, .bx--data-table.bx--skeleton tr:hover td:last-of-type {
    border-color: #e0e0e0; }

.bx--data-table.bx--skeleton .bx--table-sort-v2 {
  pointer-events: none; }

.bx--data-table.bx--skeleton th span {
  background: #c6c6c6; }

.bx--data-table.bx--skeleton th span::before {
  background: #e5e5e5; }

.bx--data-table-container.bx--skeleton
.bx--data-table-header__title {
  position: relative;
  padding: 0;
  border: none;
  background: #e5e5e5;
  box-shadow: none;
  pointer-events: none;
  width: 7.5rem;
  height: 1.5rem; }
  .bx--data-table-container.bx--skeleton
.bx--data-table-header__title:hover, .bx--data-table-container.bx--skeleton
.bx--data-table-header__title:focus, .bx--data-table-container.bx--skeleton
.bx--data-table-header__title:active {
    border: none;
    cursor: default;
    outline: none; }
  .bx--data-table-container.bx--skeleton
.bx--data-table-header__title::before {
    position: absolute;
    width: 100%;
    height: 100%;
    animation: 3000ms ease-in-out skeleton infinite;
    background: #c6c6c6;
    content: '';
    will-change: transform-origin, transform, opacity; }
    @media (prefers-reduced-motion: reduce) {
      .bx--data-table-container.bx--skeleton
.bx--data-table-header__title::before {
        animation: none; } }

.bx--data-table-container.bx--skeleton
.bx--data-table-header__description {
  position: relative;
  padding: 0;
  border: none;
  background: #e5e5e5;
  box-shadow: none;
  pointer-events: none;
  width: 10rem;
  height: 1rem;
  margin-top: 0.5rem; }
  .bx--data-table-container.bx--skeleton
.bx--data-table-header__description:hover, .bx--data-table-container.bx--skeleton
.bx--data-table-header__description:focus, .bx--data-table-container.bx--skeleton
.bx--data-table-header__description:active {
    border: none;
    cursor: default;
    outline: none; }
  .bx--data-table-container.bx--skeleton
.bx--data-table-header__description::before {
    position: absolute;
    width: 100%;
    height: 100%;
    animation: 3000ms ease-in-out skeleton infinite;
    background: #c6c6c6;
    content: '';
    will-change: transform-origin, transform, opacity; }
    @media (prefers-reduced-motion: reduce) {
      .bx--data-table-container.bx--skeleton
.bx--data-table-header__description::before {
        animation: none; } }

.bx--fieldset {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  margin-bottom: 2rem; }
  .bx--fieldset *,
  .bx--fieldset *::before,
  .bx--fieldset *::after {
    box-sizing: inherit; }

.bx--fieldset--no-margin {
  margin-bottom: 0; }

.bx--form-item {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.29;
  letter-spacing: 0.16px;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: flex-start; }

.bx--label {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.34;
  letter-spacing: 0.32px;
  display: inline-block;
  margin-bottom: 0.5rem;
  color: #525252;
  font-weight: 400;
  line-height: 1rem;
  vertical-align: baseline; }
  .bx--label *,
  .bx--label *::before,
  .bx--label *::after {
    box-sizing: inherit; }

.bx--label .bx--tooltip__trigger {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.34;
  letter-spacing: 0.32px; }

.bx--label.bx--skeleton {
  position: relative;
  padding: 0;
  border: none;
  background: #e5e5e5;
  box-shadow: none;
  pointer-events: none;
  width: 4.6875rem;
  height: 0.875rem; }
  .bx--label.bx--skeleton:hover, .bx--label.bx--skeleton:focus, .bx--label.bx--skeleton:active {
    border: none;
    cursor: default;
    outline: none; }
  .bx--label.bx--skeleton::before {
    position: absolute;
    width: 100%;
    height: 100%;
    animation: 3000ms ease-in-out skeleton infinite;
    background: #c6c6c6;
    content: '';
    will-change: transform-origin, transform, opacity; }
    @media (prefers-reduced-motion: reduce) {
      .bx--label.bx--skeleton::before {
        animation: none; } }

input[type='number'] {
  font-family: 'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace; }

input[data-invalid]:not(:focus),
.bx--number[data-invalid] input[type='number']:not(:focus),
.bx--text-input__field-wrapper[data-invalid]
> .bx--text-input--invalid:not(:focus),
.bx--text-area__wrapper[data-invalid]
> .bx--text-area--invalid:not(:focus),
.bx--select-input__wrapper[data-invalid]
.bx--select-input:not(:focus),
.bx--list-box[data-invalid]:not(:focus),
.bx--combo-box[data-invalid] .bx--text-input:not(:focus) {
  outline: 2px solid #da1e28;
  outline-offset: -2px; }
  @media screen and (prefers-contrast) {
    input[data-invalid]:not(:focus),
    .bx--number[data-invalid] input[type='number']:not(:focus),
    .bx--text-input__field-wrapper[data-invalid]
> .bx--text-input--invalid:not(:focus),
    .bx--text-area__wrapper[data-invalid]
> .bx--text-area--invalid:not(:focus),
    .bx--select-input__wrapper[data-invalid]
.bx--select-input:not(:focus),
    .bx--list-box[data-invalid]:not(:focus),
    .bx--combo-box[data-invalid] .bx--text-input:not(:focus) {
      outline-style: dotted; } }

input[data-invalid] ~ .bx--form-requirement,
.bx--number[data-invalid] .bx--number__input-wrapper ~ .bx--form-requirement,
.bx--number__input-wrapper--warning ~ .bx--form-requirement,
.bx--date-picker-input__wrapper ~ .bx--form-requirement,
.bx--date-picker-input__wrapper--warn ~ .bx--form-requirement,
.bx--date-picker-input__wrapper--invalid ~ .bx--form-requirement,
.bx--time-picker--invalid ~ .bx--form-requirement,
.bx--text-input__field-wrapper[data-invalid] ~ .bx--form-requirement,
.bx--text-input__field-wrapper--warning ~ .bx--form-requirement,
.bx--text-input__field-wrapper--warning > .bx--text-input ~ .bx--form-requirement,
.bx--text-area__wrapper[data-invalid] ~ .bx--form-requirement,
.bx--select-input__wrapper[data-invalid] ~ .bx--form-requirement,
.bx--select--warning .bx--select-input__wrapper ~ .bx--form-requirement,
.bx--time-picker[data-invalid] ~ .bx--form-requirement,
.bx--list-box[data-invalid] ~ .bx--form-requirement,
.bx--list-box--warning ~ .bx--form-requirement {
  display: block;
  overflow: visible;
  max-height: 12.5rem;
  font-weight: 400; }

input[data-invalid] ~ .bx--form-requirement,
.bx--number[data-invalid] .bx--number__input-wrapper ~ .bx--form-requirement,
.bx--date-picker-input__wrapper ~ .bx--form-requirement,
.bx--date-picker-input__wrapper--invalid ~ .bx--form-requirement,
.bx--time-picker--invalid ~ .bx--form-requirement,
.bx--text-input__field-wrapper[data-invalid] ~ .bx--form-requirement,
.bx--text-area__wrapper[data-invalid] ~ .bx--form-requirement,
.bx--select-input__wrapper[data-invalid] ~ .bx--form-requirement,
.bx--time-picker[data-invalid] ~ .bx--form-requirement,
.bx--list-box[data-invalid] ~ .bx--form-requirement {
  color: #da1e28; }

.bx--form--fluid .bx--text-input__field-wrapper[data-invalid],
.bx--form--fluid .bx--text-input__field-wrapper--warning {
  display: block; }

.bx--form--fluid .bx--fieldset {
  margin: 0; }

.bx--form--fluid input[data-invalid] {
  outline: none; }

.bx--form--fluid .bx--form-requirement {
  padding: 0.5rem 2.5rem 0.5rem 1rem;
  margin: 0; }

input:not(output):not([data-invalid]):-moz-ui-invalid {
  box-shadow: none; }

.bx--form-requirement {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.34;
  letter-spacing: 0.32px;
  display: none;
  overflow: hidden;
  max-height: 0;
  margin: 0.25rem 0 0; }
  .bx--form-requirement *,
  .bx--form-requirement *::before,
  .bx--form-requirement *::after {
    box-sizing: inherit; }

.bx--select--inline .bx--form__helper-text {
  margin-top: 0; }

.bx--form__helper-text {
  font-size: 0.75rem;
  line-height: 1.34;
  letter-spacing: 0.32px;
  z-index: 0;
  width: 100%;
  margin-top: 0.25rem;
  color: #525252;
  opacity: 1; }

.bx--label--disabled,
.bx--form__helper-text--disabled {
  color: #c6c6c6; }

fieldset[disabled] .bx--label,
fieldset[disabled] .bx--form__helper-text {
  color: #c6c6c6; }

.bx--select {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start; }
  .bx--select *,
  .bx--select *::before,
  .bx--select *::after {
    box-sizing: inherit; }

.bx--select-input__wrapper {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center; }

.bx--select-input {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.29;
  letter-spacing: 0.16px;
  outline: 2px solid transparent;
  outline-offset: -2px;
  display: block;
  width: 100%;
  height: 2.5rem;
  padding: 0 3rem 0 1rem;
  border: none;
  border-bottom: 1px solid #8d8d8d;
  appearance: none;
  background-color: #f4f4f4;
  border-radius: 0;
  color: #161616;
  cursor: pointer;
  opacity: 1;
  transition: outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--select-input:hover {
    background-color: #e5e5e5; }
  .bx--select-input::-ms-expand {
    display: none; }

@-moz-document url-prefix() {
  .bx--select-input:-moz-focusring, .bx--select-input::-moz-focus-inner {
    background-image: none;
    color: transparent;
    text-shadow: 0 0 0 #000000; } }
  .bx--select-input:focus {
    outline: 2px solid #0f62fe;
    outline-offset: -2px;
    color: #161616; }
    @media screen and (prefers-contrast) {
      .bx--select-input:focus {
        outline-style: dotted; } }
  .bx--select-input:disabled, .bx--select-input:hover:disabled {
    border-bottom-color: #f4f4f4;
    background-color: #f4f4f4;
    color: #c6c6c6;
    cursor: not-allowed; }

.bx--select-input--sm {
  height: 2rem;
  max-height: 2rem; }

.bx--select-input--xl,
.bx--select-input--lg {
  height: 3rem;
  max-height: 3rem; }

.bx--select--disabled .bx--label,
.bx--select--disabled .bx--form__helper-text {
  color: #c6c6c6; }

.bx--select-input__wrapper[data-invalid] .bx--select-input,
.bx--select--warning .bx--select-input {
  padding-right: 4.5rem; }

.bx--select-input:disabled ~ .bx--select__arrow {
  fill: #c6c6c6; }

.bx--select--light .bx--select-input {
  background-color: #ffffff; }
  .bx--select--light .bx--select-input:hover {
    background-color: #e5e5e5; }
  .bx--select--light .bx--select-input:disabled, .bx--select--light .bx--select-input:hover:disabled {
    background-color: #ffffff;
    color: #c6c6c6;
    cursor: not-allowed; }

.bx--select__arrow {
  position: absolute;
  top: 0;
  right: 1rem;
  height: 100%;
  fill: #161616;
  pointer-events: none; }
  @media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
    .bx--select__arrow path {
      fill: ButtonText; } }

.bx--select__invalid-icon {
  position: absolute;
  right: 2.5rem; }

.bx--select-input__wrapper[data-invalid]
.bx--select-input
~ .bx--select__invalid-icon {
  fill: #da1e28; }

.bx--select__invalid-icon--warning {
  fill: #f1c21b; }

.bx--select__invalid-icon--warning path[fill] {
  fill: #000000;
  opacity: 1; }

.bx--select-optgroup,
.bx--select-option {
  color: #161616; }

.bx--select--inline {
  display: flex;
  flex-direction: row;
  align-items: center; }

.bx--select--inline.bx--select--invalid .bx--label,
.bx--select--inline.bx--select--invalid
.bx--form__helper-text {
  align-self: flex-start;
  margin-top: 0.8125rem; }

.bx--select--inline .bx--form__helper-text {
  margin-bottom: 0;
  margin-left: 0.5rem; }

.bx--select--inline .bx--label {
  margin: 0 0.5rem 0 0;
  white-space: nowrap; }

.bx--select--inline .bx--select-input {
  width: auto;
  padding-right: 2rem;
  padding-left: 0.5rem;
  border-bottom: none;
  background-color: transparent;
  color: #161616; }

.bx--select--inline .bx--select-input[disabled],
.bx--select--inline .bx--select-input[disabled]:hover {
  background-color: #f4f4f4; }

.bx--select--inline .bx--select__arrow {
  right: 0.5rem; }

.bx--select--inline.bx--select--invalid
.bx--select-input {
  padding-right: 3.5rem; }

.bx--select--inline.bx--select--invalid
.bx--select-input
~ .bx--select__invalid-icon {
  right: 2rem; }

.bx--select--inline .bx--select-input:disabled {
  color: #c6c6c6;
  cursor: not-allowed; }
  .bx--select--inline .bx--select-input:disabled ~ * {
    cursor: not-allowed; }

.bx--select.bx--skeleton {
  position: relative;
  padding: 0;
  border: none;
  background: #e5e5e5;
  box-shadow: none;
  pointer-events: none;
  width: 100%;
  height: 2.5rem; }
  .bx--select.bx--skeleton:hover, .bx--select.bx--skeleton:focus, .bx--select.bx--skeleton:active {
    border: none;
    cursor: default;
    outline: none; }
  .bx--select.bx--skeleton::before {
    position: absolute;
    width: 100%;
    height: 100%;
    animation: 3000ms ease-in-out skeleton infinite;
    background: #c6c6c6;
    content: '';
    will-change: transform-origin, transform, opacity; }
    @media (prefers-reduced-motion: reduce) {
      .bx--select.bx--skeleton::before {
        animation: none; } }

.bx--select.bx--skeleton .bx--select-input {
  display: none; }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--select__arrow {
    fill: ButtonText; } }

.bx--text-input {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.29;
  letter-spacing: 0.16px;
  outline: 2px solid transparent;
  outline-offset: -2px;
  width: 100%;
  height: 2.5rem;
  padding: 0 1rem;
  border: none;
  border-bottom: 1px solid #8d8d8d;
  background-color: #f4f4f4;
  color: #161616;
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--text-input *,
  .bx--text-input *::before,
  .bx--text-input *::after {
    box-sizing: inherit; }
  .bx--text-input:focus, .bx--text-input:active {
    outline: 2px solid #0f62fe;
    outline-offset: -2px; }
    @media screen and (prefers-contrast) {
      .bx--text-input:focus, .bx--text-input:active {
        outline-style: dotted; } }
  .bx--text-input-wrapper svg[hidden] {
    display: none; }

.bx--text-input--xl,
.bx--text-input--lg {
  height: 3rem; }

.bx--text-input--sm {
  height: 2rem; }

.bx--password-input {
  padding-right: 2.5rem; }

.bx--text-input--sm.bx--password-input {
  padding-right: 2rem; }

.bx--text-input--lg.bx--password-input {
  padding-right: 3rem; }

.bx--text-input::placeholder {
  color: #a8a8a8;
  opacity: 1; }

.bx--text-input--light {
  background-color: #ffffff; }

.bx--text-input__field-wrapper {
  position: relative;
  display: flex;
  width: 100%; }

.bx--text-input__invalid-icon {
  position: absolute;
  top: 50%;
  right: 1rem;
  fill: #da1e28;
  transform: translateY(-50%); }

.bx--text-input__invalid-icon--warning {
  fill: #f1c21b; }

.bx--text-input__invalid-icon--warning path:first-of-type {
  fill: #000000;
  opacity: 1; }

.bx--text-input--password__visibility {
  position: relative;
  display: inline-flex;
  overflow: visible;
  align-items: center;
  cursor: pointer; }
  .bx--text-input--password__visibility:focus {
    outline: 1px solid #0f62fe; }
    @media screen and (prefers-contrast) {
      .bx--text-input--password__visibility:focus {
        outline-style: dotted; } }
  .bx--text-input--password__visibility:focus {
    outline: 1px solid transparent; }
    .bx--text-input--password__visibility:focus svg {
      outline: 1px solid #0f62fe; }
      @media screen and (prefers-contrast) {
        .bx--text-input--password__visibility:focus svg {
          outline-style: dotted; } }
  .bx--text-input--password__visibility::before, .bx--text-input--password__visibility::after,
  .bx--text-input--password__visibility .bx--assistive-text,
  .bx--text-input--password__visibility + .bx--assistive-text {
    position: absolute;
    z-index: 6000;
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--text-input--password__visibility::before, .bx--text-input--password__visibility::after,
      .bx--text-input--password__visibility .bx--assistive-text,
      .bx--text-input--password__visibility + .bx--assistive-text {
        display: inline-block; } }
  .bx--text-input--password__visibility::before, .bx--text-input--password__visibility::after {
    transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--text-input--password__visibility.bx--tooltip--a11y::before, .bx--text-input--password__visibility.bx--tooltip--a11y::after {
    transition: none; }
  .bx--text-input--password__visibility::before {
    width: 0;
    height: 0;
    border-style: solid;
    content: ''; }
  .bx--text-input--password__visibility .bx--assistive-text,
  .bx--text-input--password__visibility + .bx--assistive-text {
    box-sizing: content-box;
    color: inherit;
    opacity: 1;
    white-space: normal;
    word-break: break-word; }
  .bx--text-input--password__visibility::after,
  .bx--text-input--password__visibility .bx--assistive-text,
  .bx--text-input--password__visibility + .bx--assistive-text {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    z-index: 6000;
    width: max-content;
    min-width: 1.5rem;
    max-width: 13rem;
    height: auto;
    padding: 0.1875rem 1rem;
    background-color: #393939;
    border-radius: 0.125rem;
    color: #ffffff;
    font-weight: 400;
    text-align: left;
    transform: translateX(-50%);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.29;
    letter-spacing: 0.16px; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--text-input--password__visibility::after,
      .bx--text-input--password__visibility .bx--assistive-text,
      .bx--text-input--password__visibility + .bx--assistive-text {
        width: auto; } }
    @supports (-ms-accelerator: true) {
      .bx--text-input--password__visibility::after,
      .bx--text-input--password__visibility .bx--assistive-text,
      .bx--text-input--password__visibility + .bx--assistive-text {
        width: auto; } }
    @supports (-ms-ime-align: auto) {
      .bx--text-input--password__visibility::after,
      .bx--text-input--password__visibility .bx--assistive-text,
      .bx--text-input--password__visibility + .bx--assistive-text {
        width: auto; } }
    @media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
      .bx--text-input--password__visibility::after,
      .bx--text-input--password__visibility .bx--assistive-text,
      .bx--text-input--password__visibility + .bx--assistive-text {
        border: 1px solid transparent; } }
  .bx--text-input--password__visibility::after {
    content: attr(aria-label); }
  .bx--text-input--password__visibility.bx--tooltip--a11y::after {
    content: none; }
  .bx--text-input--password__visibility.bx--tooltip--visible::before, .bx--text-input--password__visibility.bx--tooltip--visible::after, .bx--text-input--password__visibility:hover::before, .bx--text-input--password__visibility:hover::after, .bx--text-input--password__visibility:focus::before, .bx--text-input--password__visibility:focus::after {
    opacity: 1; }

@keyframes tooltip-fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .bx--text-input--password__visibility.bx--tooltip--visible .bx--assistive-text,
  .bx--text-input--password__visibility.bx--tooltip--visible + .bx--assistive-text, .bx--text-input--password__visibility:hover .bx--assistive-text,
  .bx--text-input--password__visibility:hover + .bx--assistive-text, .bx--text-input--password__visibility:focus .bx--assistive-text,
  .bx--text-input--password__visibility:focus + .bx--assistive-text {
    overflow: visible;
    margin: auto;
    clip: auto; }
  .bx--text-input--password__visibility.bx--tooltip--visible .bx--assistive-text,
  .bx--text-input--password__visibility.bx--tooltip--visible + .bx--assistive-text, .bx--text-input--password__visibility.bx--tooltip--visible.bx--tooltip--a11y::before, .bx--text-input--password__visibility:hover .bx--assistive-text,
  .bx--text-input--password__visibility:hover + .bx--assistive-text, .bx--text-input--password__visibility:hover.bx--tooltip--a11y::before, .bx--text-input--password__visibility:focus .bx--assistive-text,
  .bx--text-input--password__visibility:focus + .bx--assistive-text, .bx--text-input--password__visibility:focus.bx--tooltip--a11y::before {
    animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--text-input--password__visibility.bx--tooltip--hidden .bx--assistive-text,
  .bx--text-input--password__visibility.bx--tooltip--hidden + .bx--assistive-text {
    overflow: hidden;
    margin: -1px;
    clip: rect(0, 0, 0, 0); }
  .bx--text-input--password__visibility.bx--tooltip--hidden.bx--tooltip--a11y::before {
    animation: none;
    opacity: 0; }
  .bx--text-input--password__visibility .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    width: 100%;
    height: 0.75rem;
    top: -0.75rem; }
  .bx--text-input--password__visibility::before, .bx--text-input--password__visibility::after,
  .bx--text-input--password__visibility .bx--assistive-text,
  .bx--text-input--password__visibility + .bx--assistive-text {
    bottom: 0;
    left: 50%; }
  .bx--text-input--password__visibility::before {
    bottom: -0.5rem;
    border-width: 0 0.25rem 0.3125rem 0.25rem;
    border-color: transparent transparent #393939 transparent;
    transform: translate(-50%, 100%); }
  .bx--text-input--password__visibility::after,
  .bx--text-input--password__visibility .bx--assistive-text,
  .bx--text-input--password__visibility + .bx--assistive-text {
    bottom: -0.8125rem;
    transform: translate(-50%, 100%); }

.bx--text-input--password__visibility,
.bx--btn.bx--text-input--password__visibility__toggle.bx--tooltip__trigger {
  outline: 2px solid transparent;
  outline-offset: -2px;
  position: absolute;
  right: 0;
  display: flex;
  width: 2.5rem;
  height: 100%;
  min-height: auto;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 0;
  background: none;
  cursor: pointer;
  transition: outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--text-input--sm
+ .bx--btn.bx--text-input--password__visibility__toggle.bx--tooltip__trigger {
  width: 2rem; }

.bx--text-input--lg
+ .bx--btn.bx--text-input--password__visibility__toggle.bx--tooltip__trigger {
  width: 3rem; }

.bx--btn.bx--text-input--password__visibility__toggle.bx--tooltip__trigger
svg {
  fill: #525252;
  transition: fill 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  @media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
    .bx--btn.bx--text-input--password__visibility__toggle.bx--tooltip__trigger
svg {
      fill: ButtonText; } }

.bx--btn.bx--text-input--password__visibility__toggle.bx--tooltip__trigger:focus {
  outline: 2px solid #0f62fe;
  outline-offset: -2px; }
  @media screen and (prefers-contrast) {
    .bx--btn.bx--text-input--password__visibility__toggle.bx--tooltip__trigger:focus {
      outline-style: dotted; } }

.bx--btn.bx--text-input--password__visibility__toggle.bx--tooltip__trigger:hover
svg,
.bx--btn.bx--text-input--password__visibility__toggle.bx--tooltip__trigger:focus
svg {
  fill: #161616; }

.bx--text-input--invalid,
.bx--text-input--warning {
  padding-right: 2.5rem; }

.bx--text-input--invalid.bx--password-input {
  padding-right: 4rem; }

.bx--text-input--invalid +
.bx--text-input--password__visibility,
.bx--text-input--invalid +
.bx--text-input--password__visibility__toggle {
  right: 1rem; }

.bx--password-input-wrapper .bx--text-input__invalid-icon {
  right: 2.5rem; }

.bx--text-input:disabled
+ .bx--text-input--password__visibility
svg,
.bx--text-input:disabled
+ .bx--text-input--password__visibility__toggle.bx--tooltip__trigger svg {
  cursor: not-allowed;
  fill: #c6c6c6; }
  .bx--text-input:disabled
+ .bx--text-input--password__visibility
svg:hover,
  .bx--text-input:disabled
+ .bx--text-input--password__visibility__toggle.bx--tooltip__trigger svg:hover {
    fill: #c6c6c6; }

.bx--text-input:disabled {
  outline: 2px solid transparent;
  outline-offset: -2px;
  border-bottom: 1px solid transparent;
  background-color: #f4f4f4;
  color: #c6c6c6;
  cursor: not-allowed;
  -webkit-text-fill-color: currentColor; }

.bx--text-input--light:disabled {
  background-color: #ffffff; }

.bx--text-input:disabled::placeholder {
  color: #c6c6c6;
  opacity: 1; }

.bx--text-input--invalid {
  outline: 2px solid #da1e28;
  outline-offset: -2px;
  box-shadow: none; }
  @media screen and (prefers-contrast) {
    .bx--text-input--invalid {
      outline-style: dotted; } }
  .bx--text-input--invalid .bx--text-input--password__visibility,
  .bx--text-input--invalid .bx--text-input--password__visibility__toggle {
    right: 2.5rem; }

.bx--skeleton.bx--text-input {
  position: relative;
  padding: 0;
  border: none;
  background: #e5e5e5;
  box-shadow: none;
  pointer-events: none; }
  .bx--skeleton.bx--text-input:hover, .bx--skeleton.bx--text-input:focus, .bx--skeleton.bx--text-input:active {
    border: none;
    cursor: default;
    outline: none; }
  .bx--skeleton.bx--text-input::before {
    position: absolute;
    width: 100%;
    height: 100%;
    animation: 3000ms ease-in-out skeleton infinite;
    background: #c6c6c6;
    content: '';
    will-change: transform-origin, transform, opacity; }
    @media (prefers-reduced-motion: reduce) {
      .bx--skeleton.bx--text-input::before {
        animation: none; } }

.bx--form--fluid .bx--text-input-wrapper {
  position: relative;
  background: #f4f4f4;
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--form--fluid .bx--label {
  position: absolute;
  z-index: 1;
  top: 0.8125rem;
  left: 1rem;
  margin: 0; }

.bx--form--fluid .bx--form__helper-text {
  display: none; }

.bx--form--fluid .bx--text-input {
  min-height: 4rem;
  padding: 2rem 1rem 0.8125rem; }

.bx--text-input__divider,
.bx--form--fluid .bx--text-input__divider {
  display: none; }

.bx--form--fluid .bx--text-input--invalid,
.bx--form--fluid .bx--text-input--warn {
  border-bottom: none; }

.bx--form--fluid
.bx--text-input--invalid
+ .bx--text-input__divider,
.bx--form--fluid
.bx--text-input--warn
+ .bx--text-input__divider {
  display: block;
  border-style: solid;
  border-color: #e0e0e0;
  border-bottom: none;
  margin: 0 1rem; }

.bx--form--fluid .bx--text-input__invalid-icon {
  top: 5rem; }

.bx--form--fluid .bx--text-input-wrapper--light {
  background: #ffffff; }

.bx--form--fluid
.bx--text-input__field-wrapper[data-invalid]
> .bx--text-input--invalid {
  outline: 2px solid transparent;
  outline-offset: -2px; }

.bx--form--fluid
.bx--text-input__field-wrapper[data-invalid]:not(:focus) {
  outline: 2px solid #da1e28;
  outline-offset: -2px; }
  @media screen and (prefers-contrast) {
    .bx--form--fluid
.bx--text-input__field-wrapper[data-invalid]:not(:focus) {
      outline-style: dotted; } }

.bx--form--fluid
.bx--text-input__field-wrapper[data-invalid]
> .bx--text-input--invalid:focus {
  outline: 2px solid #0f62fe;
  outline-offset: -2px; }
  @media screen and (prefers-contrast) {
    .bx--form--fluid
.bx--text-input__field-wrapper[data-invalid]
> .bx--text-input--invalid:focus {
      outline-style: dotted; } }

.bx--text-input-wrapper.bx--text-input-wrapper--inline {
  flex-flow: row wrap; }

.bx--text-input-wrapper .bx--label--inline {
  flex: 1;
  margin: 0.8125rem 0 0 0;
  overflow-wrap: break-word;
  word-break: break-word; }

.bx--text-input-wrapper .bx--label--inline--sm {
  margin-top: 0.5625rem; }

.bx--text-input-wrapper .bx--label--inline--xl,
.bx--text-input-wrapper .bx--label--inline--lg {
  margin-top: 1.0625rem; }

.bx--text-input__label-helper-wrapper {
  max-width: 8rem;
  flex: 2;
  flex-direction: column;
  margin-right: 1.5rem;
  overflow-wrap: break-word; }

.bx--text-input-wrapper .bx--form__helper-text--inline {
  margin-top: 0.125rem; }

.bx--text-input__field-outer-wrapper {
  display: flex;
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: flex-start; }

.bx--text-input__field-outer-wrapper--inline {
  flex: 8;
  flex-direction: column; }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--text-input--password__visibility,
  .bx--btn.bx--btn--icon-only.bx--text-input--password__visibility__toggle.bx--tooltip__trigger svg,
  .bx--btn.bx--btn--icon-only.bx--text-input--password__visibility__toggle.bx--tooltip__trigger:hover
svg {
    fill: ButtonText; } }

.bx--data-table-container + .bx--pagination {
  border-top: 0; }

.bx--pagination {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.29;
  letter-spacing: 0.16px;
  display: flex;
  width: calc(100% - 1px);
  min-height: 3rem;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e0e0e0;
  background-color: #f4f4f4;
  overflow-x: auto; }
  .bx--pagination *,
  .bx--pagination *::before,
  .bx--pagination *::after {
    box-sizing: inherit; }
  @media (min-width: 42rem) {
    .bx--pagination {
      overflow: initial; }
      .bx--pagination .bx--pagination__control-buttons {
        display: flex; } }
  @media (max-width: 41.98rem) {
    .bx--pagination .bx--pagination__left > *,
    .bx--pagination .bx--pagination__right > * {
      display: none; }
    .bx--pagination .bx--pagination__items-count {
      display: initial; }
    .bx--pagination .bx--pagination__control-buttons {
      display: flex; } }

.bx--pagination .bx--select {
  height: 100%;
  align-items: center; }

.bx--pagination .bx--select-input--inline__wrapper {
  display: flex;
  height: 100%; }

.bx--pagination .bx--select-input {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.29;
  letter-spacing: 0.16px;
  width: auto;
  min-width: auto;
  height: 3rem;
  padding: 0 2.25rem 0 1rem; }

.bx--pagination .bx--select-input:hover {
  background: #e5e5e5; }

.bx--pagination .bx--select__arrow {
  top: 50%;
  transform: translate(-0.5rem, -50%); }

.bx--pagination
.bx--select__item-count
.bx--select-input {
  border-right: 0.0625rem solid #e0e0e0; }

.bx--pagination
.bx--select__page-number
.bx--select-input {
  border-left: 1px solid #e0e0e0; }

.bx--pagination__left,
.bx--pagination__right {
  display: flex;
  height: 3rem;
  align-items: center; }

.bx--pagination__left > .bx--form-item,
.bx--pagination__right > .bx--form-item {
  height: 100%; }

.bx--pagination__left .bx--pagination__text,
.bx--pagination__right .bx--pagination__text {
  white-space: nowrap; }

.bx--pagination__left .bx--pagination__text {
  margin-right: 0.0625rem; }

.bx--pagination__right .bx--pagination__text {
  margin-right: 1rem;
  margin-left: 0.0625rem; }

.bx--pagination__left {
  padding: 0 1rem 0 0; }
  @media (min-width: 42rem) {
    .bx--pagination__left {
      padding: 0 1rem; } }

@media (min-width: 42rem) {
  .bx--pagination__text {
    display: inline-block; } }

span.bx--pagination__text {
  margin-left: 1rem;
  color: #525252; }

.bx--pagination__button,
.bx--btn--ghost.bx--pagination__button {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: flex;
  width: 3rem;
  height: 100%;
  align-items: center;
  justify-content: center;
  border: none;
  border-left: 1px solid #e0e0e0;
  margin: 0;
  background: none;
  cursor: pointer;
  fill: #161616;
  transition: outline 110ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--pagination__button *,
  .bx--pagination__button *::before,
  .bx--pagination__button *::after,
  .bx--btn--ghost.bx--pagination__button *,
  .bx--btn--ghost.bx--pagination__button *::before,
  .bx--btn--ghost.bx--pagination__button *::after {
    box-sizing: inherit; }

.bx--pagination__button:focus,
.bx--btn--ghost:focus.bx--pagination__button {
  outline: 2px solid #0f62fe;
  outline-offset: -2px;
  border-left: 0; }
  @media screen and (prefers-contrast) {
    .bx--pagination__button:focus,
    .bx--btn--ghost:focus.bx--pagination__button {
      outline-style: dotted; } }

.bx--pagination__button:hover,
.bx--btn--ghost:hover.bx--pagination__button {
  background: #e5e5e5; }

.bx--pagination__button--no-index,
.bx--btn--ghost.bx--pagination__button--no-index {
  cursor: not-allowed;
  fill: #c6c6c6; }

.bx--pagination__button:disabled:hover,
.bx--pagination__button--no-index:hover,
.bx--btn--ghost:disabled:hover.bx--pagination__button,
.bx--btn--ghost:hover.bx--pagination__button--no-index {
  border-color: #e0e0e0;
  background: #f4f4f4;
  cursor: not-allowed;
  fill: #c6c6c6; }

.bx--pagination.bx--skeleton .bx--skeleton__text {
  margin-right: 1rem;
  margin-bottom: 0; }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--pagination__button,
  .bx--btn--ghost.bx--pagination__button {
    outline: 1px solid transparent; } }

.bx--tabs {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.29;
  letter-spacing: 0.16px;
  position: relative;
  width: 100%;
  height: auto;
  color: #161616; }
  .bx--tabs *,
  .bx--tabs *::before,
  .bx--tabs *::after {
    box-sizing: inherit; }
  @media (min-width: 42rem) {
    .bx--tabs {
      min-height: 2.5rem;
      background: none; } }

@media (min-width: 42rem) {
  .bx--tabs--container {
    min-height: 3rem; } }

.bx--tabs-trigger {
  display: flex;
  height: 2.5rem;
  align-items: center;
  justify-content: space-between;
  padding: 0 3rem 0 1rem;
  border-bottom: 1px solid #8d8d8d;
  background-color: #f4f4f4;
  color: #161616;
  cursor: pointer;
  outline: 2px solid transparent; }
  @media (min-width: 42rem) {
    .bx--tabs-trigger {
      display: none; } }

.bx--tabs-trigger:focus,
.bx--tabs-trigger:active {
  outline: 2px solid #0f62fe;
  outline-offset: -2px; }
  @media screen and (prefers-contrast) {
    .bx--tabs-trigger:focus,
    .bx--tabs-trigger:active {
      outline-style: dotted; } }

.bx--tabs-trigger svg {
  position: absolute;
  right: 1rem;
  fill: #161616;
  transition: transform 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--tabs-trigger--open:focus,
.bx--tabs-trigger--open:active {
  outline: 2px solid transparent;
  outline-offset: -2px;
  transition: outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--tabs-trigger--open {
  background: #e0e0e0; }

.bx--tabs-trigger--open svg {
  transform: rotate(-180deg);
  transform-origin: 50% 45%;
  transition: transform 70ms; }

.bx--tabs--light.bx--tabs-trigger {
  background-color: #ffffff; }

.bx--tabs-trigger-text {
  overflow: hidden;
  padding-top: 2px;
  color: #161616;
  font-weight: 400;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap; }

.bx--tabs-trigger-text:hover {
  color: #161616; }

.bx--tabs-trigger-text:focus {
  outline: none; }

.bx--tabs__nav {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 9100;
  display: flex;
  width: 100%;
  max-height: 600px;
  flex-direction: column;
  padding: 0;
  margin: 0;
  background: #f4f4f4;
  list-style: none;
  transition: max-height 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  @media (min-width: 42rem) {
    .bx--tabs__nav {
      z-index: auto;
      width: auto;
      flex-direction: row;
      background: none;
      box-shadow: none;
      transition: inherit; } }

.bx--tabs__nav--hidden {
  overflow: hidden;
  max-height: 0;
  transition: max-height 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  @media (min-width: 42rem) {
    .bx--tabs__nav--hidden {
      display: flex;
      max-width: 100%;
      max-height: none;
      overflow-x: auto;
      transition: inherit; } }

.bx--tabs__nav-item {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: flex;
  width: 100%;
  height: 2.5rem;
  padding: 0;
  background-color: #f4f4f4;
  cursor: pointer;
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tabs__nav-item *,
  .bx--tabs__nav-item *::before,
  .bx--tabs__nav-item *::after {
    box-sizing: inherit; }
  @media (min-width: 42rem) {
    .bx--tabs__nav-item {
      height: auto;
      background: transparent; }
      .bx--tabs__nav-item + .bx--tabs__nav-item {
        margin-left: 0.0625rem; } }

@media (min-width: 42rem) {
  .bx--tabs--container .bx--tabs__nav-item {
    background-color: #e0e0e0; }
    .bx--tabs--container .bx--tabs__nav-item + .bx--tabs__nav-item {
      margin-left: 0;
      box-shadow: -1px 0 0 0 #8d8d8d; }
    .bx--tabs--container .bx--tabs__nav-item + .bx--tabs__nav-item.bx--tabs__nav-item--selected,
    .bx--tabs--container .bx--tabs__nav-item.bx--tabs__nav-item--selected + .bx--tabs__nav-item {
      box-shadow: none; } }

.bx--tabs__nav-item .bx--tabs__nav-link {
  transition: color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), border-bottom-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }

@media (min-width: 42rem) {
  .bx--tabs__nav-item:hover:not(.bx--tabs__nav-item--selected) {
    background: transparent; } }

.bx--tabs__nav-item:hover:not(.bx--tabs__nav-item--disabled) {
  background-color: #e5e5e5;
  box-shadow: 0 -1px 0 #e5e5e5; }
  @media (min-width: 42rem) {
    .bx--tabs__nav-item:hover:not(.bx--tabs__nav-item--disabled) {
      background-color: transparent; }
      .bx--tabs__nav-item:hover:not(.bx--tabs__nav-item--disabled) + .bx--tabs__nav-item {
        box-shadow: none; } }

@media (min-width: 42rem) {
  .bx--tabs--container
.bx--tabs__nav-item:hover:not(.bx--tabs__nav-item--disabled) {
    background-color: #cacaca; } }

.bx--tabs__nav-item--disabled,
.bx--tabs__nav-item--disabled:hover {
  cursor: not-allowed;
  outline: none; }

@media (min-width: 42rem) {
  .bx--tabs--container
.bx--tabs__nav-item.bx--tabs__nav-item--disabled,
  .bx--tabs--container
.bx--tabs__nav-item.bx--tabs__nav-item--disabled:hover {
    background-color: #c6c6c6; } }

@media (min-width: 42rem) {
  .bx--tabs--container
.bx--tabs__nav-item--disabled
.bx--tabs__nav-link {
    border-bottom: none;
    color: #8d8d8d; } }

.bx--tabs__nav-item--selected:not(.bx--tabs__nav-item--disabled) {
  display: none;
  border: none;
  transition: color 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  @media (min-width: 42rem) {
    .bx--tabs__nav-item--selected:not(.bx--tabs__nav-item--disabled) {
      display: flex; }
      .bx--tabs__nav-item--selected:not(.bx--tabs__nav-item--disabled) .bx--tabs__nav-link,
      .bx--tabs__nav-item--selected:not(.bx--tabs__nav-item--disabled) .bx--tabs__nav-link:focus,
      .bx--tabs__nav-item--selected:not(.bx--tabs__nav-item--disabled) .bx--tabs__nav-link:active {
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1.29;
        letter-spacing: 0.16px;
        border-bottom: 2px solid #0f62fe;
        color: #161616; } }

@media (min-width: 42rem) {
  .bx--tabs--container
.bx--tabs__nav-item--selected:not(.bx--tabs__nav-item--disabled),
  .bx--tabs--container
.bx--tabs__nav-item--selected:hover:not(.bx--tabs__nav-item--disabled) {
    background-color: #f4f4f4; }
    .bx--tabs--container
.bx--tabs__nav-item--selected:not(.bx--tabs__nav-item--disabled) .bx--tabs__nav-link,
    .bx--tabs--container
.bx--tabs__nav-item--selected:hover:not(.bx--tabs__nav-item--disabled) .bx--tabs__nav-link {
      padding: 0.5rem 1rem;
      border-bottom: none;
      box-shadow: inset 0 2px 0 0 #0f62fe;
      line-height: calc(3rem - (0.5rem * 2)); }
    .bx--tabs--container
.bx--tabs__nav-item--selected:not(.bx--tabs__nav-item--disabled) .bx--tabs__nav-link:focus,
    .bx--tabs--container
.bx--tabs__nav-item--selected:not(.bx--tabs__nav-item--disabled) .bx--tabs__nav-link:active,
    .bx--tabs--container
.bx--tabs__nav-item--selected:hover:not(.bx--tabs__nav-item--disabled) .bx--tabs__nav-link:focus,
    .bx--tabs--container
.bx--tabs__nav-item--selected:hover:not(.bx--tabs__nav-item--disabled) .bx--tabs__nav-link:active {
      box-shadow: none; } }

a.bx--tabs__nav-link {
  outline: 2px solid transparent;
  outline-offset: -2px;
  display: inline-block;
  overflow: hidden;
  width: calc(100% - 32px);
  height: 2.5rem;
  padding: 0.75rem 0;
  border-bottom: 1px solid #e0e0e0;
  margin: 0 1rem;
  color: #525252;
  font-weight: 400;
  line-height: 1rem;
  text-decoration: none;
  text-overflow: ellipsis;
  transition: border 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  white-space: nowrap; }
  a.bx--tabs__nav-link:focus, a.bx--tabs__nav-link:active {
    outline: 2px solid #0f62fe;
    outline-offset: -2px;
    width: 100%;
    padding-left: 16px;
    margin: 0; }
    @media screen and (prefers-contrast) {
      a.bx--tabs__nav-link:focus, a.bx--tabs__nav-link:active {
        outline-style: dotted; } }
  @media (min-width: 42rem) {
    a.bx--tabs__nav-link {
      width: 10rem;
      padding: 0.75rem 1rem 0.5rem;
      border-bottom: 2px solid #e0e0e0;
      margin: 0;
      line-height: inherit; }
      a.bx--tabs__nav-link:focus, a.bx--tabs__nav-link:active {
        width: 10rem;
        border-bottom: 2px; } }

@media (min-width: 42rem) {
  .bx--tabs--container a.bx--tabs__nav-link {
    height: 3rem;
    padding: 0.5rem 1rem;
    border-bottom: none;
    line-height: calc(3rem - (0.5rem * 2)); } }

.bx--tabs__nav-item:hover:not(.bx--tabs__nav-item--selected):not(.bx--tabs__nav-item--disabled)
.bx--tabs__nav-link {
  color: #161616; }
  @media (min-width: 42rem) {
    .bx--tabs__nav-item:hover:not(.bx--tabs__nav-item--selected):not(.bx--tabs__nav-item--disabled)
.bx--tabs__nav-link {
      border-bottom: 2px solid #8d8d8d;
      color: #161616; } }

@media (min-width: 42rem) {
  .bx--tabs--container
.bx--tabs__nav-item:hover:not(.bx--tabs__nav-item--selected):not(.bx--tabs__nav-item--disabled)
.bx--tabs__nav-link {
    border-bottom: none; } }

.bx--tabs__nav-item--disabled .bx--tabs__nav-link {
  border-bottom: 2px solid #f4f4f4;
  color: #c6c6c6;
  pointer-events: none; }

.bx--tabs__nav-item--disabled:hover .bx--tabs__nav-link {
  border-bottom: 2px solid #f4f4f4;
  cursor: no-drop; }

.bx--tabs__nav-item--disabled .bx--tabs__nav-link:focus,
.bx--tabs__nav-item--disabled a.bx--tabs__nav-link:active {
  border-bottom: 2px solid #f4f4f4;
  outline: none; }

.bx--tabs__nav-item:not(.bx--tabs__nav-item--selected):not(.bx--tabs__nav-item--disabled):not(.bx--tabs__nav-item--selected)
.bx--tabs__nav-link:focus,
.bx--tabs__nav-item:not(.bx--tabs__nav-item--selected):not(.bx--tabs__nav-item--disabled):not(.bx--tabs__nav-item--selected)
a.bx--tabs__nav-link:active {
  color: #525252; }

.bx--tab-content {
  padding: 1rem; }
  .bx--tab-content:focus {
    outline: 2px solid #0f62fe;
    outline-offset: -2px; }
    @media screen and (prefers-contrast) {
      .bx--tab-content:focus {
        outline-style: dotted; } }

.bx--tabs.bx--skeleton {
  cursor: default;
  pointer-events: none; }

.bx--skeleton.bx--tabs--scrollable:not(.bx--tabs--scrollable--container)
.bx--tabs--scrollable__nav-item {
  border-bottom: 2px solid #c6c6c6; }

.bx--tabs.bx--skeleton .bx--tabs__nav-link {
  display: flex;
  width: 10rem;
  height: 100%;
  align-items: center;
  padding: 0 1rem; }

.bx--tabs.bx--skeleton .bx--tabs__nav-link span {
  position: relative;
  padding: 0;
  border: none;
  background: #e5e5e5;
  box-shadow: none;
  pointer-events: none;
  display: block;
  width: 100%;
  height: 0.875rem; }
  .bx--tabs.bx--skeleton .bx--tabs__nav-link span:hover, .bx--tabs.bx--skeleton .bx--tabs__nav-link span:focus, .bx--tabs.bx--skeleton .bx--tabs__nav-link span:active {
    border: none;
    cursor: default;
    outline: none; }
  .bx--tabs.bx--skeleton .bx--tabs__nav-link span::before {
    position: absolute;
    width: 100%;
    height: 100%;
    animation: 3000ms ease-in-out skeleton infinite;
    background: #c6c6c6;
    content: '';
    will-change: transform-origin, transform, opacity; }
    @media (prefers-reduced-motion: reduce) {
      .bx--tabs.bx--skeleton .bx--tabs__nav-link span::before {
        animation: none; } }

.bx--tabs.bx--skeleton .bx--tabs-trigger {
  position: relative;
  padding: 0;
  border: none;
  background: #e5e5e5;
  box-shadow: none;
  pointer-events: none;
  width: 6.25rem; }
  .bx--tabs.bx--skeleton .bx--tabs-trigger:hover, .bx--tabs.bx--skeleton .bx--tabs-trigger:focus, .bx--tabs.bx--skeleton .bx--tabs-trigger:active {
    border: none;
    cursor: default;
    outline: none; }
  .bx--tabs.bx--skeleton .bx--tabs-trigger::before {
    position: absolute;
    width: 100%;
    height: 100%;
    animation: 3000ms ease-in-out skeleton infinite;
    background: #c6c6c6;
    content: '';
    will-change: transform-origin, transform, opacity; }
    @media (prefers-reduced-motion: reduce) {
      .bx--tabs.bx--skeleton .bx--tabs-trigger::before {
        animation: none; } }

.bx--tabs.bx--skeleton .bx--tabs-trigger svg {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  visibility: inherit;
  white-space: nowrap; }

.bx--tabs--scrollable {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.29;
  letter-spacing: 0.16px;
  display: flex;
  width: 100%;
  height: auto;
  min-height: 2.5rem;
  color: #161616; }
  .bx--tabs--scrollable *,
  .bx--tabs--scrollable *::before,
  .bx--tabs--scrollable *::after {
    box-sizing: inherit; }
  .bx--tabs--scrollable.bx--tabs--scrollable--container {
    min-height: 3rem; }
  .bx--tabs--scrollable .bx--tabs--scrollable__nav {
    display: flex;
    overflow: auto hidden;
    width: auto;
    max-width: 100%;
    flex-direction: row;
    padding: 0;
    margin: 0;
    list-style: none;
    outline: 0;
    scrollbar-width: none;
    transition: max-height 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
    .bx--tabs--scrollable .bx--tabs--scrollable__nav::-webkit-scrollbar {
      display: none; }
  .bx--tabs--scrollable .bx--tabs__overflow-indicator--left,
  .bx--tabs--scrollable .bx--tabs__overflow-indicator--right {
    z-index: 1;
    width: 0.5rem;
    flex: 1 0 auto; }
  .bx--tabs--scrollable .bx--tabs__overflow-indicator--left {
    margin-right: -0.5rem;
    background-image: linear-gradient(to left, transparent, #ffffff); }
  .bx--tabs--scrollable .bx--tabs__overflow-indicator--right {
    margin-left: -0.5rem;
    background-image: linear-gradient(to right, transparent, #ffffff); }
  .bx--tabs--scrollable .bx--tabs--scrollable--light
.bx--tabs__overflow-indicator--left {
    background-image: linear-gradient(to left, transparent, #f4f4f4); }
  .bx--tabs--scrollable .bx--tabs--scrollable--light
.bx--tabs__overflow-indicator--right {
    background-image: linear-gradient(to right, transparent, #f4f4f4); }
  .bx--tabs--scrollable.bx--tabs--scrollable--container .bx--tabs__overflow-indicator--left {
    background-image: linear-gradient(to left, transparent, #e0e0e0); }
  .bx--tabs--scrollable.bx--tabs--scrollable--container .bx--tabs__overflow-indicator--right {
    background-image: linear-gradient(to right, transparent, #e0e0e0); }
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      .bx--tabs--scrollable .bx--tabs__overflow-indicator--left {
        background-image: linear-gradient(to left, rgba(255, 255, 255, 0), #ffffff); }
      .bx--tabs--scrollable .bx--tabs__overflow-indicator--right {
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff); }
      .bx--tabs--scrollable.bx--tabs--scrollable--container .bx--tabs__overflow-indicator--left {
        background-image: linear-gradient(to left, rgba(224, 224, 224, 0), #e0e0e0); }
      .bx--tabs--scrollable.bx--tabs--scrollable--container .bx--tabs__overflow-indicator--right {
        background-image: linear-gradient(to right, rgba(224, 224, 224, 0), #e0e0e0); } } }
  .bx--tabs--scrollable .bx--tab--overflow-nav-button {
    box-sizing: border-box;
    padding: 0;
    border: 0;
    margin: 0;
    font-family: inherit;
    font-size: 100%;
    vertical-align: baseline;
    display: inline-block;
    padding: 0;
    border: 0;
    appearance: none;
    background: none;
    cursor: pointer;
    width: 100%;
    display: flex;
    width: 2.5rem;
    flex-shrink: 0;
    align-items: center;
    justify-content: center; }
    .bx--tabs--scrollable .bx--tab--overflow-nav-button *,
    .bx--tabs--scrollable .bx--tab--overflow-nav-button *::before,
    .bx--tabs--scrollable .bx--tab--overflow-nav-button *::after {
      box-sizing: inherit; }
    .bx--tabs--scrollable .bx--tab--overflow-nav-button::-moz-focus-inner {
      border: 0; }
    .bx--tabs--scrollable .bx--tab--overflow-nav-button:focus {
      outline: 2px solid #0f62fe;
      outline-offset: -2px; }
      @media screen and (prefers-contrast) {
        .bx--tabs--scrollable .bx--tab--overflow-nav-button:focus {
          outline-style: dotted; } }
  .bx--tabs--scrollable .bx--tab--overflow-nav-button--hidden {
    display: none; }
  .bx--tabs--scrollable.bx--tabs--scrollable--container .bx--tab--overflow-nav-button {
    width: 3rem;
    margin: 0;
    background-color: #e0e0e0; }
  .bx--tabs--scrollable .bx--tab--overflow-nav-button svg {
    fill: #161616; }
  .bx--tabs--scrollable .bx--tabs--scrollable__nav-item {
    box-sizing: border-box;
    padding: 0;
    border: 0;
    margin: 0;
    font-family: inherit;
    font-size: 100%;
    vertical-align: baseline;
    display: flex;
    padding: 0;
    cursor: pointer;
    transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
    .bx--tabs--scrollable .bx--tabs--scrollable__nav-item *,
    .bx--tabs--scrollable .bx--tabs--scrollable__nav-item *::before,
    .bx--tabs--scrollable .bx--tabs--scrollable__nav-item *::after {
      box-sizing: inherit; }
  .bx--tabs--scrollable .bx--tabs--scrollable__nav-item
+ .bx--tabs--scrollable__nav-item {
    margin-left: 0.0625rem; }
  .bx--tabs--scrollable.bx--tabs--scrollable--container .bx--tabs--scrollable__nav-item {
    background-color: #e0e0e0; }
  .bx--tabs--scrollable.bx--tabs--scrollable--container .bx--tabs--scrollable__nav-item
+ .bx--tabs--scrollable__nav-item {
    margin-left: 0;
    box-shadow: -0.0625rem 0 0 0 #8d8d8d; }
  .bx--tabs--scrollable.bx--tabs--scrollable--container .bx--tabs--scrollable__nav-item
+ .bx--tabs--scrollable__nav-item.bx--tabs--scrollable__nav-item--selected,
  .bx--tabs--scrollable.bx--tabs--scrollable--container .bx--tabs--scrollable__nav-item.bx--tabs--scrollable__nav-item--selected
+ .bx--tabs--scrollable__nav-item {
    box-shadow: none; }
  .bx--tabs--scrollable .bx--tabs--scrollable__nav-item
.bx--tabs--scrollable__nav-link {
    transition: color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), border-bottom-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tabs--scrollable.bx--tabs--scrollable--container .bx--tabs--scrollable__nav-item:hover {
    background-color: #cacaca; }
  .bx--tabs--scrollable .bx--tabs--scrollable__nav-item--disabled,
  .bx--tabs--scrollable .bx--tabs--scrollable__nav-item--disabled:hover {
    background-color: transparent;
    cursor: not-allowed;
    outline: none; }
  .bx--tabs--scrollable.bx--tabs--scrollable--container .bx--tabs--scrollable__nav-item.bx--tabs--scrollable__nav-item--disabled,
  .bx--tabs--scrollable.bx--tabs--scrollable--container .bx--tabs--scrollable__nav-item.bx--tabs--scrollable__nav-item--disabled:hover {
    background-color: #c6c6c6; }
  .bx--tabs--scrollable .bx--tabs--scrollable__nav-item--selected {
    transition: color 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tabs--scrollable .bx--tabs--scrollable__nav-item--selected
.bx--tabs--scrollable__nav-link,
  .bx--tabs--scrollable .bx--tabs--scrollable__nav-item--selected
.bx--tabs--scrollable__nav-link:focus,
  .bx--tabs--scrollable .bx--tabs--scrollable__nav-item--selected
.bx--tabs--scrollable__nav-link:active {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.29;
    letter-spacing: 0.16px;
    border-bottom: 2px solid #0f62fe;
    color: #161616; }
  .bx--tabs--scrollable.bx--tabs--scrollable--container .bx--tabs--scrollable__nav-item--selected,
  .bx--tabs--scrollable.bx--tabs--scrollable--container .bx--tabs--scrollable__nav-item--selected:hover {
    background-color: #f4f4f4; }
    .bx--tabs--scrollable.bx--tabs--scrollable--container .bx--tabs--scrollable__nav-item--selected .bx--tabs--scrollable__nav-link:focus,
    .bx--tabs--scrollable.bx--tabs--scrollable--container .bx--tabs--scrollable__nav-item--selected .bx--tabs--scrollable__nav-link:active,
    .bx--tabs--scrollable.bx--tabs--scrollable--container .bx--tabs--scrollable__nav-item--selected:hover .bx--tabs--scrollable__nav-link:focus,
    .bx--tabs--scrollable.bx--tabs--scrollable--container .bx--tabs--scrollable__nav-item--selected:hover .bx--tabs--scrollable__nav-link:active {
      box-shadow: none; }
  .bx--tabs--scrollable.bx--tabs--scrollable--container .bx--tabs--scrollable__nav-item--selected
.bx--tabs--scrollable__nav-link {
    box-shadow: inset 0 2px 0 0 #0f62fe;
    line-height: calc(3rem - (0.5rem * 2)); }
  .bx--tabs--scrollable.bx--tabs--scrollable--light.bx--tabs--scrollable--container .bx--tabs--scrollable__nav-item--selected,
  .bx--tabs--scrollable.bx--tabs--scrollable--light.bx--tabs--scrollable--container .bx--tabs--scrollable__nav-item--selected:hover {
    background-color: #ffffff; }
  .bx--tabs--scrollable .bx--tabs--scrollable__nav-link {
    box-sizing: border-box;
    padding: 0;
    border: 0;
    margin: 0;
    font-family: inherit;
    font-size: 100%;
    vertical-align: baseline;
    display: inline-block;
    padding: 0;
    border: 0;
    appearance: none;
    background: none;
    cursor: pointer;
    outline: 2px solid transparent;
    outline-offset: -2px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.29;
    letter-spacing: 0.16px;
    overflow: hidden;
    width: 10rem;
    padding: 0.75rem 1rem 0.5rem;
    border-bottom: 2px solid #e0e0e0;
    color: #525252;
    text-align: left;
    text-decoration: none;
    text-overflow: ellipsis;
    transition: border 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
    white-space: nowrap; }
    .bx--tabs--scrollable .bx--tabs--scrollable__nav-link *,
    .bx--tabs--scrollable .bx--tabs--scrollable__nav-link *::before,
    .bx--tabs--scrollable .bx--tabs--scrollable__nav-link *::after {
      box-sizing: inherit; }
    .bx--tabs--scrollable .bx--tabs--scrollable__nav-link::-moz-focus-inner {
      border: 0; }
    .bx--tabs--scrollable .bx--tabs--scrollable__nav-link:focus, .bx--tabs--scrollable .bx--tabs--scrollable__nav-link:active {
      outline: 2px solid #0f62fe;
      outline-offset: -2px; }
      @media screen and (prefers-contrast) {
        .bx--tabs--scrollable .bx--tabs--scrollable__nav-link:focus, .bx--tabs--scrollable .bx--tabs--scrollable__nav-link:active {
          outline-style: dotted; } }
  .bx--tabs--scrollable.bx--tabs--scrollable--container .bx--tabs--scrollable__nav-link {
    height: 3rem;
    padding: 0.5rem 1rem;
    border-bottom: 0;
    line-height: calc(3rem - (0.5rem * 2)); }
  .bx--tabs--scrollable .bx--tabs--scrollable__nav-item:hover
.bx--tabs--scrollable__nav-link {
    border-bottom: 2px solid #8d8d8d;
    color: #161616; }
  .bx--tabs--scrollable.bx--tabs--scrollable--container .bx--tabs--scrollable__nav-item
.bx--tabs--scrollable__nav-link {
    border-bottom: none; }
  .bx--tabs--scrollable .bx--tabs--scrollable__nav-item--disabled
.bx--tabs--scrollable__nav-link {
    border-bottom: 2px solid #f4f4f4;
    color: #c6c6c6; }
  .bx--tabs--scrollable .bx--tabs--scrollable__nav-item--disabled:hover
.bx--tabs--scrollable__nav-link {
    border-bottom: 2px solid #f4f4f4;
    color: #c6c6c6;
    cursor: not-allowed;
    pointer-events: none; }
  .bx--tabs--scrollable .bx--tabs--scrollable__nav-item--disabled
.bx--tabs--scrollable__nav-link:focus,
  .bx--tabs--scrollable .bx--tabs--scrollable__nav-item--disabled
.bx--tabs--scrollable__nav-link:active {
    border-bottom: 2px solid #f4f4f4;
    outline: none; }
  .bx--tabs--scrollable .bx--tabs--scrollable--light
.bx--tabs--scrollable__nav-item--disabled
.bx--tabs--scrollable__nav-link {
    border-bottom-color: #e0e0e0; }
  .bx--tabs--scrollable .bx--tabs--scrollable--light
.bx--tabs--scrollable__nav-item--disabled:hover
.bx--tabs--scrollable__nav-link {
    border-bottom-color: #e0e0e0; }
  .bx--tabs--scrollable .bx--tabs--scrollable--light
.bx--tabs--scrollable__nav-item--disabled
.bx--tabs--scrollable__nav-link:focus,
  .bx--tabs--scrollable .bx--tabs--scrollable--light
.bx--tabs--scrollable__nav-item--disabled
.bx--tabs--scrollable__nav-link:active {
    border-bottom-color: #e0e0e0; }
  .bx--tabs--scrollable.bx--tabs--scrollable--container .bx--tabs--scrollable__nav-item--disabled
.bx--tabs--scrollable__nav-link {
    border-bottom: none;
    color: #8d8d8d; }
  .bx--tabs--scrollable .bx--tab-content {
    padding: 1rem; }
  .bx--tabs--scrollable .bx--tabs.bx--skeleton {
    cursor: default;
    pointer-events: none; }
  .bx--tabs--scrollable .bx--tabs.bx--skeleton
.bx--tabs--scrollable__nav-link {
    position: relative;
    padding: 0;
    border: none;
    background: #e5e5e5;
    box-shadow: none;
    pointer-events: none;
    width: 4.6875rem; }
    .bx--tabs--scrollable .bx--tabs.bx--skeleton
.bx--tabs--scrollable__nav-link:hover, .bx--tabs--scrollable .bx--tabs.bx--skeleton
.bx--tabs--scrollable__nav-link:focus, .bx--tabs--scrollable .bx--tabs.bx--skeleton
.bx--tabs--scrollable__nav-link:active {
      border: none;
      cursor: default;
      outline: none; }
    .bx--tabs--scrollable .bx--tabs.bx--skeleton
.bx--tabs--scrollable__nav-link::before {
      position: absolute;
      width: 100%;
      height: 100%;
      animation: 3000ms ease-in-out skeleton infinite;
      background: #c6c6c6;
      content: '';
      will-change: transform-origin, transform, opacity; }
      @media (prefers-reduced-motion: reduce) {
        .bx--tabs--scrollable .bx--tabs.bx--skeleton
.bx--tabs--scrollable__nav-link::before {
          animation: none; } }
  .bx--tabs--scrollable .bx--tabs.bx--skeleton .bx--tabs-trigger {
    position: relative;
    padding: 0;
    border: none;
    background: #e5e5e5;
    box-shadow: none;
    pointer-events: none;
    width: 4.6875rem;
    margin-right: 0.0625rem; }
    .bx--tabs--scrollable .bx--tabs.bx--skeleton .bx--tabs-trigger:hover, .bx--tabs--scrollable .bx--tabs.bx--skeleton .bx--tabs-trigger:focus, .bx--tabs--scrollable .bx--tabs.bx--skeleton .bx--tabs-trigger:active {
      border: none;
      cursor: default;
      outline: none; }
    .bx--tabs--scrollable .bx--tabs.bx--skeleton .bx--tabs-trigger::before {
      position: absolute;
      width: 100%;
      height: 100%;
      animation: 3000ms ease-in-out skeleton infinite;
      background: #c6c6c6;
      content: '';
      will-change: transform-origin, transform, opacity; }
      @media (prefers-reduced-motion: reduce) {
        .bx--tabs--scrollable .bx--tabs.bx--skeleton .bx--tabs-trigger::before {
          animation: none; } }
  .bx--tabs--scrollable .bx--tabs.bx--skeleton .bx--tabs-trigger svg {
    position: absolute;
    overflow: hidden;
    width: 1px;
    height: 1px;
    padding: 0;
    border: 0;
    margin: -1px;
    clip: rect(0, 0, 0, 0);
    visibility: inherit;
    white-space: nowrap; }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--tabs--scrollable__nav-item
.bx--tabs__nav-item--selected
.bx--tabs--scrollable__nav-item--selected {
    color: Highlight;
    outline: 1px solid Highlight; } }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--tabs--scrollable
.bx--tabs--scrollable__nav-item--disabled
.bx--tabs--scrollable__nav-link {
    color: GrayText;
    fill: GrayText; } }

.bx--header {
  position: fixed;
  z-index: 8000;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  height: 3rem;
  align-items: center;
  border-bottom: 1px solid #393939;
  background-color: #161616; }

.bx--header__action {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  appearance: none;
  background: none;
  cursor: pointer;
  width: 100%;
  width: 3rem;
  height: 3rem;
  border: 0.125rem solid transparent;
  transition: background-color 110ms, border-color 110ms; }
  .bx--header__action *,
  .bx--header__action *::before,
  .bx--header__action *::after {
    box-sizing: inherit; }
  .bx--header__action::-moz-focus-inner {
    border: 0; }

.bx--header__action
> svg.bx--navigation-menu-panel-collapse-icon,
.bx--header__action--active
> svg.bx--navigation-menu-panel-expand-icon {
  display: none; }

.bx--header__action--active
> svg.bx--navigation-menu-panel-collapse-icon {
  display: inline; }

.bx--header__action:hover {
  background-color: #353535; }

.bx--header__action--active {
  border-right: 1px solid #393939;
  border-bottom: 1px solid #161616;
  border-left: 1px solid #393939; }

.bx--header__action:focus {
  border-color: #ffffff;
  outline: none; }

.bx--header__action:active {
  background-color: #393939; }

.bx--header__action.bx--btn--icon-only.bx--tooltip__trigger {
  justify-content: center; }

.bx--header__action > svg {
  fill: #ffffff; }

.bx--header__menu-trigger > svg {
  fill: #f4f4f4; }

.bx--header__menu-trigger:hover {
  fill: #2c2c2c; }

.bx--header__menu-toggle {
  display: flex;
  align-items: center;
  justify-content: center; }

@media (min-width: 66rem) {
  .bx--header__menu-toggle__hidden {
    display: none; } }

a.bx--header__name {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.29;
  letter-spacing: 0.16px;
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 2rem 0 1rem;
  border: 0.125rem solid transparent;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 1.25rem;
  outline: none;
  text-decoration: none;
  transition: border-color 110ms;
  user-select: none; }

a.bx--header__name:focus {
  border-color: #ffffff; }

.bx--header__name--prefix {
  font-weight: 400; }

a.bx--header__name,
a.bx--header__name:hover {
  color: #f4f4f4; }

.bx--header__menu-toggle:not(.bx--header__menu-toggle__hidden)
~ .bx--header__name {
  padding-left: 0.5rem; }

.bx--header__nav {
  position: relative;
  display: none;
  height: 100%;
  padding-left: 1rem; }
  @media (min-width: 66rem) {
    .bx--header__nav {
      display: block; } }
  .bx--header__nav::before {
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 0.0625rem;
    height: 1.5rem;
    background-color: #393939;
    content: '';
    transform: translateY(-50%); }

.bx--header__menu-bar {
  display: flex;
  height: 100%;
  padding: 0;
  margin: 0;
  list-style: none; }

a.bx--header__menu-item {
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 1rem;
  border: 2px solid transparent;
  color: #c6c6c6;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.125rem;
  text-decoration: none;
  transition: background-color 110ms, border-color 110ms, color 110ms;
  user-select: none; }

a.bx--header__menu-item:hover {
  background-color: #2c2c2c;
  color: #f4f4f4; }

.bx--header__action:active,
a.bx--header__menu-item:active {
  background-color: #393939;
  color: #f4f4f4; }

a.bx--header__menu-item:focus {
  border-color: #ffffff;
  color: #f4f4f4;
  outline: none; }

a.bx--header__menu-item:hover > svg,
a.bx--header__menu-item:active > svg,
a.bx--header__menu-item:focus > svg {
  fill: #f4f4f4; }

a.bx--header__menu-item[aria-current='page']::after,
.bx--header__menu-item--current::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -2px;
  left: 0;
  width: 100%;
  border-bottom: 3px solid #4589ff;
  content: ''; }

a.bx--header__menu-item[aria-current='page']:focus::after,
.bx--header__menu-item--current:focus::after {
  border: 0; }

a.bx--header__menu-item[aria-current='page']:focus,
a.bx--header__menu-item.bx--header__menu-item--current:focus {
  border: 2px solid #ffffff; }

.bx--header__submenu {
  position: relative; }

.bx--header__menu-title[aria-haspopup='true'] {
  position: relative; }

.bx--header__menu-title[aria-expanded='true'] {
  z-index: 8001;
  background-color: #262626;
  color: #ffffff; }

.bx--header__menu-title[aria-expanded='true']
> .bx--header__menu-arrow {
  transform: rotate(180deg); }

.bx--header__menu {
  display: none;
  padding: 0;
  margin: 0;
  list-style: none; }

.bx--header__menu-title[aria-expanded='true']
+ .bx--header__menu {
  position: absolute;
  z-index: 8000;
  bottom: 0;
  left: 0;
  display: flex;
  width: 12.5rem;
  flex-direction: column;
  background-color: #262626;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  transform: translateY(100%); }

.bx--header__menu-title[aria-expanded='true']
+ .bx--header__menu
.bx--header__menu-item:hover {
  background-color: #353535; }

.bx--header__menu-title[aria-expanded='true']
+ .bx--header__menu
.bx--header__menu-item:active {
  background-color: #393939; }

.bx--header__menu .bx--header__menu-item {
  height: 3rem; }

.bx--header__menu .bx--header__menu-item:hover {
  background-color: #262626;
  color: #f4f4f4; }

.bx--header__menu-arrow {
  margin-left: 0.5rem;
  fill: #c6c6c6;
  transition: transform 110ms, fill 110ms; }

.bx--header__global {
  display: flex;
  height: 100%;
  flex: 1 1 0%;
  justify-content: flex-end; }

.bx--skip-to-content {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  visibility: inherit;
  white-space: nowrap; }

.bx--skip-to-content:focus {
  z-index: 9999;
  top: 0;
  left: 0;
  display: flex;
  width: auto;
  height: 3rem;
  align-items: center;
  padding: 0 1rem;
  border: 4px solid #0f62fe;
  background-color: #161616;
  clip: auto;
  color: #f4f4f4;
  outline: none; }

.bx--header-panel {
  transition-timing-function: cubic-bezier(0.2, 0, 1, 0.9);
  position: fixed;
  z-index: 8000;
  top: 3rem;
  right: 0;
  bottom: 0;
  overflow: hidden;
  width: 0;
  border: none;
  background-color: #161616;
  color: #c6c6c6;
  transition: width 0.11s;
  will-change: width; }

.bx--header-panel--expanded {
  width: 16rem;
  border-right: 1px solid #393939;
  border-left: 1px solid #393939; }

.bx--panel--overlay {
  position: fixed;
  z-index: 1000;
  top: 3rem;
  right: 0;
  bottom: 0;
  width: 16rem;
  height: 100%;
  padding: 1rem 0;
  background-color: #161616;
  overflow-x: hidden;
  overflow-y: auto;
  transform: translate3d(100%, 0, 0);
  transition: transform 0.11s cubic-bezier(0.2, 0, 0.38, 0.9);
  will-change: transform; }

.bx--panel--expanded {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.25);
  transform: translate3d(0, 0, 0); }

.bx--product-switcher__search {
  padding: 0 1rem;
  margin-bottom: 1.5rem; }

.bx--search--shell input {
  background-color: #e0e0e0; }

.bx--product-switcher__subheader,
.bx--product-switcher__all-btn {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.29;
  letter-spacing: 0.16px;
  padding: 0.5rem;
  color: #c6c6c6; }

.bx--product-switcher__subheader {
  padding-left: 3.5rem; }

.bx--product-switcher__all-btn {
  padding-left: 3.5rem; }

.bx--product-switcher__all-btn,
.bx--product-switcher__back-btn {
  display: inline-block;
  width: 100%;
  border: none;
  background: transparent;
  color: #0f62fe;
  cursor: pointer;
  text-align: left; }

.bx--product-switcher__all-btn:hover,
.bx--product-switcher__back-btn:hover {
  text-decoration: underline; }

.bx--product-switcher__all-btn:focus,
.bx--product-switcher__back-btn:focus {
  box-shadow: inset 0 0 0 3px #0f62fe;
  outline: none; }

.bx--product-switcher__back-btn {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.29;
  letter-spacing: 0.16px;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem; }

.bx--product-switcher__back-arrow {
  margin-right: 1rem;
  fill: #0f62fe; }

.bx--product-list__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer; }

.bx--product-list__item:hover {
  background: #e0e0e0; }

.bx--product-link {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 1rem;
  text-decoration: none; }

.bx--product-link:focus {
  box-shadow: inset 0 0 0 3px #0f62fe;
  outline: none; }

.bx--product-switcher__icon {
  margin-right: 1rem; }

.bx--product-link__name {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.29;
  letter-spacing: 0.16px;
  margin-left: 0.25rem;
  color: #c6c6c6;
  font-weight: 400; }

.bx--product-switcher__product-list .bx--overflow-menu {
  display: none;
  width: 2.5rem;
  align-items: center;
  justify-content: center; }
  .bx--product-switcher__product-list .bx--overflow-menu.bx--overflow-menu--open {
    display: flex; }

.bx--product-switcher__product-list .bx--overflow-menu > svg {
  fill: #c6c6c6; }

.bx--product-switcher__product-list .bx--overflow-menu:hover {
  background: #c6c6c6; }

.bx--product-switcher__product-list
.bx--overflow-menu:hover
> svg {
  fill: #c6c6c6; }

.bx--product-switcher__product-list .bx--overflow-menu:focus {
  display: flex;
  box-shadow: inset 0 0 0 3px #0f62fe;
  outline: none; }

.bx--product-switcher__product-list
.bx--overflow-menu-options__option:hover {
  background: #ffffff; }

.bx--product-list__item:hover .bx--overflow-menu {
  display: flex; }

.bx--switcher {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #c6c6c6; }

.bx--switcher__item {
  width: 100%;
  height: 2rem; }

.bx--switcher__item:nth-child(1) {
  margin-top: 1rem; }

.bx--switcher__item--divider {
  display: block;
  width: 14rem;
  height: 1px;
  border: none;
  margin: 0.5rem 1rem;
  background: #393939; }

.bx--switcher__item-link {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.29;
  letter-spacing: 0.16px;
  display: block;
  height: 2rem;
  padding: 0.375rem 1rem;
  color: #c6c6c6;
  text-decoration: none; }
  .bx--switcher__item-link:hover:not(.bx--switcher__item-link--selected) {
    background: #2c2c2c;
    color: #f4f4f4;
    cursor: pointer; }
  .bx--switcher__item-link:focus {
    outline: 2px solid #ffffff;
    outline-offset: -2px; }
  .bx--switcher__item-link:active {
    background: #393939;
    color: #f4f4f4; }

.bx--switcher__item-link--selected {
  background: #262626;
  color: #f4f4f4; }

.bx--side-nav {
  position: fixed;
  z-index: 8000;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 3rem;
  max-width: 16rem;
  background-color: #ffffff;
  color: #525252;
  transition: width 0.11s cubic-bezier(0.2, 0, 1, 0.9);
  will-change: width; }

.bx--side-nav--ux {
  top: 3rem;
  width: 16rem; }
  @media (max-width: 65.98rem) {
    .bx--side-nav--ux {
      width: 0; } }

.bx--side-nav--rail {
  width: 3rem; }

.bx--side-nav--hidden {
  width: 0; }

.bx--side-nav.bx--side-nav--rail:not(.bx--side-nav--fixed):hover,
.bx--side-nav--expanded {
  width: 16rem; }

.bx--side-nav__overlay {
  position: fixed;
  top: 3rem;
  left: 0;
  width: 0;
  height: 0;
  background-color: transparent;
  opacity: 0;
  transition: opacity 240ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 240ms cubic-bezier(0.2, 0, 0.38, 0.9); }

@media (max-width: 65.98rem) {
  .bx--side-nav__overlay-active {
    width: 100vw;
    height: 100vh;
    background-color: rgba(22, 22, 22, 0.5);
    opacity: 1;
    transition: opacity 240ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 240ms cubic-bezier(0.2, 0, 0.38, 0.9); } }

.bx--header ~ .bx--side-nav {
  top: 3rem;
  height: calc(100% - 48px); }

.bx--side-nav--fixed {
  width: 16rem; }

.bx--side-nav--collapsed {
  width: 16rem;
  transform: translateX(-16rem); }

.bx--side-nav__navigation {
  display: flex;
  height: 100%;
  flex-direction: column; }

.bx--side-nav__header {
  display: flex;
  width: 100%;
  max-width: 100%;
  height: 3rem;
  border-bottom: 1px solid #393939; }
  .bx--side-nav:hover .bx--side-nav__header,
  .bx--side-nav--fixed .bx--side-nav__header,
  .bx--side-nav--expanded .bx--side-nav__header {
    height: auto; }

.bx--side-nav--ux .bx--side-nav__header {
  height: auto; }

.bx--side-nav__details {
  display: flex;
  min-width: 0;
  flex: 1;
  flex-direction: column;
  padding-right: 1rem;
  opacity: 0;
  visibility: hidden; }
  .bx--side-nav:hover .bx--side-nav__details,
  .bx--side-nav--fixed .bx--side-nav__details,
  .bx--side-nav--expanded .bx--side-nav__details {
    visibility: inherit;
    opacity: 1; }

.bx--side-nav--ux .bx--side-nav__details {
  opacity: 1;
  visibility: inherit; }

.bx--side-nav__title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 1rem;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.1px;
  user-select: none; }

.bx--side-nav__title,
.bx--side-nav__select {
  padding-left: 0.5rem; }

.bx--side-nav__switcher {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.bx--side-nav__switcher-chevron {
  position: absolute;
  top: 0;
  right: 0.5rem;
  bottom: 0;
  display: flex;
  align-items: center;
  fill: #525252; }

.bx--side-nav__select {
  outline: 2px solid transparent;
  outline-offset: -2px;
  min-width: 0;
  height: 2rem;
  flex: 1 1 0%;
  padding-right: 2rem;
  border: none;
  appearance: none;
  background-color: #161616;
  border-radius: 0;
  color: #f4f4f4;
  cursor: pointer;
  font-size: 0.75rem;
  transition: outline 110ms; }

.bx--side-nav__select:focus {
  outline: 2px solid #0f62fe;
  outline-offset: -2px; }
  @media screen and (prefers-contrast) {
    .bx--side-nav__select:focus {
      outline-style: dotted; } }

.bx--side-nav__footer {
  width: 100%;
  flex: 0 0 3rem;
  background-color: #ffffff; }

.bx--side-nav__toggle {
  outline: 2px solid transparent;
  outline-offset: -2px;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  appearance: none;
  background: none;
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding-left: 1rem;
  text-align: left;
  transition: outline 110ms; }
  .bx--side-nav__toggle *,
  .bx--side-nav__toggle *::before,
  .bx--side-nav__toggle *::after {
    box-sizing: inherit; }
  .bx--side-nav__toggle::-moz-focus-inner {
    border: 0; }

.bx--side-nav__toggle:focus {
  outline: 2px solid #0f62fe;
  outline-offset: -2px; }
  @media screen and (prefers-contrast) {
    .bx--side-nav__toggle:focus {
      outline-style: dotted; } }

.bx--side-nav__items {
  overflow: hidden;
  flex: 1 1 0%;
  padding: 1rem 0 0; }
  .bx--side-nav:hover .bx--side-nav__items,
  .bx--side-nav--fixed .bx--side-nav__items,
  .bx--side-nav--expanded .bx--side-nav__items {
    overflow-y: auto; }

.bx--side-nav--ux .bx--side-nav__items {
  overflow-y: auto; }

.bx--side-nav__item {
  overflow: hidden;
  width: auto;
  height: auto; }

.bx--side-nav--ux .bx--side-nav__item {
  width: auto;
  height: auto; }

.bx--side-nav__item:not(.bx--side-nav__item--active):hover
.bx--side-nav__item:not(.bx--side-nav__item--active)
> .bx--side-nav__submenu:hover,
.bx--side-nav__item:not(.bx--side-nav__item--active)
> .bx--side-nav__link:hover,
.bx--side-nav__menu
a.bx--side-nav__link:not(.bx--side-nav__link--current):not([aria-current='page']):hover,
.bx--side-nav a.bx--header__menu-item:hover,
.bx--side-nav
.bx--header__menu-title[aria-expanded='true']:hover {
  background-color: #e5e5e5;
  color: #161616; }

.bx--side-nav__item:not(.bx--side-nav__item--active)
> .bx--side-nav__link:hover
> span,
.bx--side-nav__item:not(.bx--side-nav__item--active)
.bx--side-nav__menu-item
> .bx--side-nav__link:hover
> span {
  color: #161616; }

.bx--side-nav__item--large {
  height: 3rem; }

.bx--side-nav__divider {
  height: 1px;
  margin: 0.5rem 1rem;
  background-color: #e0e0e0; }

.bx--side-nav__submenu {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  appearance: none;
  background: none;
  cursor: pointer;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.29;
  letter-spacing: 0.16px;
  outline: 2px solid transparent;
  outline-offset: -2px;
  display: flex;
  height: 2rem;
  align-items: center;
  padding: 0 1rem;
  color: #525252;
  transition: color 110ms, background-color 110ms, outline 110ms;
  user-select: none; }
  .bx--side-nav__submenu *,
  .bx--side-nav__submenu *::before,
  .bx--side-nav__submenu *::after {
    box-sizing: inherit; }
  .bx--side-nav__submenu::-moz-focus-inner {
    border: 0; }

.bx--side-nav__submenu:hover {
  background-color: #e5e5e5;
  color: #161616; }

.bx--side-nav__submenu:focus {
  outline: 2px solid #0f62fe;
  outline-offset: -2px; }
  @media screen and (prefers-contrast) {
    .bx--side-nav__submenu:focus {
      outline-style: dotted; } }

.bx--side-nav__submenu-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left; }

.bx--side-nav__icon.bx--side-nav__submenu-chevron {
  display: flex;
  flex: 1;
  justify-content: flex-end; }

.bx--side-nav__submenu-chevron > svg {
  width: 1rem;
  height: 1rem;
  transition: transform 110ms; }

.bx--side-nav__submenu[aria-expanded='true']
.bx--side-nav__submenu-chevron
> svg {
  transform: rotate(180deg); }

.bx--side-nav__item--large .bx--side-nav__submenu {
  height: 3rem; }

.bx--side-nav__item--active .bx--side-nav__submenu:hover {
  background-color: #e5e5e5;
  color: #161616; }

.bx--side-nav__item--active
.bx--side-nav__submenu[aria-expanded='false'] {
  position: relative;
  background-color: #e5e5e5;
  color: #161616; }
  .bx--side-nav__item--active
.bx--side-nav__submenu[aria-expanded='false']::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 4px;
    background-color: #0f62fe;
    content: ''; }

.bx--side-nav__item--active .bx--side-nav__submenu-title {
  color: #161616;
  font-weight: 600; }

.bx--side-nav__menu {
  display: block;
  max-height: 0;
  visibility: hidden; }

.bx--side-nav__submenu[aria-expanded='true']
+ .bx--side-nav__menu {
  max-height: 93.75rem;
  visibility: inherit; }

.bx--side-nav__menu a.bx--side-nav__link {
  height: 2rem;
  min-height: 2rem;
  padding-left: 2rem;
  font-weight: 400; }

.bx--side-nav__item.bx--side-nav__item--icon
a.bx--side-nav__link {
  padding-left: 4.5rem; }

.bx--side-nav__menu a.bx--side-nav__link--current,
.bx--side-nav__menu a.bx--side-nav__link[aria-current='page'],
a.bx--side-nav__link--current {
  background-color: #e0e0e0; }
  .bx--side-nav__menu a.bx--side-nav__link--current > span,
  .bx--side-nav__menu a.bx--side-nav__link[aria-current='page'] > span,
  a.bx--side-nav__link--current > span {
    color: #161616;
    font-weight: 600; }

a.bx--side-nav__link,
.bx--side-nav a.bx--header__menu-item,
.bx--side-nav
.bx--header__menu-title[aria-expanded='true']
+ .bx--header__menu {
  outline: 2px solid transparent;
  outline-offset: -2px;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.29;
  letter-spacing: 0.16px;
  position: relative;
  display: flex;
  min-height: 2rem;
  align-items: center;
  padding: 0 1rem;
  text-decoration: none;
  transition: color 110ms, background-color 110ms, outline 110ms; }

.bx--side-nav__item--large a.bx--side-nav__link {
  height: 3rem; }

a.bx--side-nav__link > .bx--side-nav__link-text,
.bx--side-nav
a.bx--header__menu-item
.bx--text-truncate-end {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #525252;
  font-size: 0.875rem;
  letter-spacing: 0.1px;
  line-height: 1.25rem;
  user-select: none; }

a.bx--side-nav__link:focus,
.bx--side-nav a.bx--header__menu-item:focus {
  outline: 2px solid #0f62fe;
  outline-offset: -2px; }
  @media screen and (prefers-contrast) {
    a.bx--side-nav__link:focus,
    .bx--side-nav a.bx--header__menu-item:focus {
      outline-style: dotted; } }

a.bx--side-nav__link[aria-current='page'],
a.bx--side-nav__link--current {
  background-color: #e5e5e5;
  font-weight: 600; }

a.bx--side-nav__link[aria-current='page']
.bx--side-nav__link-text,
a.bx--side-nav__link--current .bx--side-nav__link-text {
  color: #161616; }

a.bx--side-nav__link[aria-current='page']::before,
a.bx--side-nav__link--current::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 4px;
  background-color: #0f62fe;
  content: ''; }

.bx--side-nav__icon {
  display: flex;
  flex: 0 0 1rem;
  align-items: center;
  justify-content: center; }

.bx--side-nav__icon:not(.bx--side-nav__submenu-chevron) {
  margin-right: 1.5rem; }

.bx--side-nav__icon > svg {
  width: 1rem;
  height: 1rem;
  fill: #525252; }

.bx--side-nav__icon > svg.bx--side-nav-collapse-icon {
  display: none; }

.bx--side-nav--expanded
.bx--side-nav__icon
> svg.bx--side-nav-expand-icon {
  display: none; }

.bx--side-nav--expanded
.bx--side-nav__icon
> svg.bx--side-nav-collapse-icon {
  display: block; }

.bx--side-nav--fixed a.bx--side-nav__link,
.bx--side-nav--fixed .bx--side-nav__submenu {
  padding-left: 1rem; }

.bx--side-nav--fixed
.bx--side-nav__item:not(.bx--side-nav__item--icon)
.bx--side-nav__menu
a.bx--side-nav__link {
  padding-left: 2rem; }

@media (max-width: 65.98rem) {
  .bx--side-nav .bx--header__nav {
    display: block; } }

.bx--side-nav__header-navigation {
  display: none; }
  @media (max-width: 65.98rem) {
    .bx--side-nav__header-navigation {
      position: relative;
      display: block;
      margin-bottom: 2rem; } }

.bx--side-nav__header-divider::after {
  position: absolute;
  bottom: -1rem;
  left: 1rem;
  width: calc(100% - 32px);
  height: 0.0625rem;
  background: #e0e0e0;
  content: ''; }

.bx--side-nav a.bx--header__menu-item {
  justify-content: space-between;
  color: #525252;
  white-space: nowrap; }
  .bx--side-nav a.bx--header__menu-item[aria-expanded='true'] {
    background-color: transparent; }

.bx--side-nav
.bx--header__menu-title[aria-expanded='true']
+ .bx--header__menu {
  bottom: inherit;
  width: 100%;
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  transform: none; }
  .bx--side-nav
.bx--header__menu-title[aria-expanded='true']
+ .bx--header__menu li {
    width: 100%; }
  .bx--side-nav
.bx--header__menu-title[aria-expanded='true']
+ .bx--header__menu a.bx--header__menu-item {
    padding-left: 4.25rem;
    font-weight: 400; }
  .bx--side-nav
.bx--header__menu-title[aria-expanded='true']
+ .bx--header__menu a.bx--header__menu-item:hover {
    background-color: #e5e5e5;
    color: #161616; }

.bx--side-nav
.bx--header__menu
a.bx--header__menu-item {
  height: inherit; }

.bx--side-nav
a.bx--header__menu-item:hover
.bx--header__menu-arrow,
.bx--side-nav
a.bx--header__menu-item:focus
.bx--header__menu-arrow,
.bx--side-nav .bx--header__menu-arrow {
  fill: #525252; }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--side-nav__icon > svg,
  .bx--side-nav
a.bx--header__menu-item:hover
.bx--header__menu-arrow,
  .bx--side-nav
a.bx--header__menu-item:focus
.bx--header__menu-arrow,
  .bx--side-nav .bx--header__menu-arrow {
    fill: ButtonText; } }

.bx--navigation {
  position: fixed;
  z-index: 9100;
  top: 3rem;
  bottom: 0;
  left: 0;
  width: 16rem;
  background-color: #262626;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.25);
  color: #f4f4f4; }

.bx--navigation--right {
  right: 0;
  left: auto; }

.bx--navigation svg {
  fill: #f4f4f4; }

.bx--navigation-section:not(:last-child)::after {
  display: block;
  height: 1px;
  margin: 0 1rem;
  background-color: #393939;
  content: ''; }

.bx--navigation-item {
  position: relative;
  display: flex;
  align-items: center; }

.bx--navigation-item--active > a.bx--navigation-link {
  color: #ffffff;
  font-weight: 600; }

.bx--navigation-item--active::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 4px;
  background-color: #0f62fe;
  content: ''; }

a.bx--navigation-link {
  display: flex;
  width: 100%;
  min-height: 2.5rem;
  align-items: center;
  padding-left: 1rem;
  color: #f4f4f4;
  font-size: 0.875rem;
  font-weight: 400;
  text-decoration: none; }

a.bx--navigation-link:hover {
  background-color: #333333;
  color: #ffffff; }

a.bx--navigation-link:focus {
  outline: 0.1875rem solid #0f62fe;
  outline-offset: -0.1875rem; }

.bx--navigation-item--icon > a.bx--navigation-link {
  padding-left: 0; }

.bx--navigation__category {
  width: 100%; }

.bx--navigation__category-toggle {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  appearance: none;
  background: none;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center; }
  .bx--navigation__category-toggle *,
  .bx--navigation__category-toggle *::before,
  .bx--navigation__category-toggle *::after {
    box-sizing: inherit; }
  .bx--navigation__category-toggle::-moz-focus-inner {
    border: 0; }

.bx--navigation__category-toggle:hover {
  background-color: #333333; }

.bx--navigation__category-toggle:focus {
  outline: 0.1875rem solid #0f62fe;
  outline-offset: -0.1875rem; }

.bx--navigation__category-title {
  display: flex;
  width: 100%;
  min-height: 2.5rem;
  align-items: center;
  justify-content: space-between;
  padding-right: 1rem;
  padding-left: 1rem;
  color: #f4f4f4;
  font-size: 0.875rem;
  font-weight: 400; }

.bx--navigation-item--icon .bx--navigation__category-title {
  padding-left: 0; }

.bx--navigation__category-items {
  display: none;
  visibility: hidden; }

.bx--navigation__category-item > a.bx--navigation-link {
  display: flex;
  min-height: 2rem;
  align-items: center;
  padding-left: 2rem; }

.bx--navigation__category-item {
  position: relative; }

.bx--navigation-item--icon
.bx--navigation__category-item
> a.bx--navigation-link {
  padding-left: 3.5rem; }

.bx--navigation__category-item--active::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 4px;
  background-color: #0f62fe;
  content: ''; }

.bx--navigation__category-item--active
> a.bx--navigation-link {
  color: #ffffff;
  font-weight: 600; }

.bx--navigation__category--expanded
.bx--navigation__category-title {
  font-weight: 600; }

.bx--navigation__category--expanded
.bx--navigation__category-title
> svg {
  transform: rotate(180deg); }

.bx--navigation__category--expanded
.bx--navigation__category-items {
  display: block;
  visibility: inherit; }

.bx--navigation-icon {
  display: flex;
  width: 3rem;
  min-width: 3rem;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem; }

.bx--content {
  padding: 2rem;
  background: #f4f4f4;
  will-change: margin-left; }

.bx--header ~ .bx--content {
  margin-top: 3rem; }

.bx--side-nav ~ .bx--content {
  margin-left: 3rem; }

.bx--side-nav.bx--side-nav--expanded ~ .bx--content {
  margin-left: 16rem; }

main {
  display: block; }

@media (min-width: 42rem) {
  .bx--grid--no-gutter {
    padding-left: 1rem;
    padding-right: 1rem; } }

.bx--content {
  padding: 0; }
